<template>
  <b-container id="reporting">
    <div class="card mt-3">
      <div class="card-body" style="padding-top: 4.25rem;">
        <b-row >
          <b-col cols="12">
            <div class="content-block">
              <div class='header-form'>
                <p><span>Reporting</span></p>
              </div>
              <div class="content-form">
                <b-container>
                <b-row>
                  <b-col sm="12" md="5">
                    <b-form inline>
                      <label for="example-datepicker" style='margin-right:10px;'>Début: </label>
                      <b-form-datepicker id="example-datepicker" v-bind="labels['fr-FR'] || {}" v-model="start" locale="fr-FR" reset-button></b-form-datepicker>
                    </b-form>
                  </b-col>
                  <b-col sm="12" md="5">
                    <b-form inline>
                      <label style='margin-right:10px;' for="example2-datepicker">Fin: </label>
                      <b-form-datepicker id="example2-datepicker" v-bind="labels['fr-FR'] || {}" v-model="end" locale="fr-FR" reset-button></b-form-datepicker>
                    </b-form>
                  </b-col>
                  <b-col sm="12" md="2">
                    <b-button variant="info" @click="exportCSV($event)">Export CSV</b-button>
                  </b-col>
                </b-row>
              </b-container>

              <b-pagination
                v-model="currentPage"
                :total-rows="total_devis"
                :per-page="perPage"
                aria-controls="my-table"
                align="center"
              ></b-pagination>
                <b-table responsive striped hover
                :items="items"
                :fields="fields"
                id="my-table"
                :per-page="perPage"
                :current-page="currentPage">
                  <template #cell(actions)="row">
                    <b-button size="sm" target="_blank" :href="downloadPDF(row.item.id)" class="mr-1">
                      PDF
                    </b-button>
                  </template>
                  <template #row-details="row">
                    <b-row class="mb-2">
                      <b-col style="text-align:left;padding-left:2em;"><b>Ref: {{ row.item.ref_chantier }}</b></b-col>
                    </b-row>
                </template>
                </b-table>
                <b-container>
                <b-row>
                  <b-col>
                    Nb Devis généré: {{total_devis}}
                  </b-col>
                  <b-col>
                    Prix Total Devis Généré: {{total_prix}}
                  </b-col>
                </b-row>
              </b-container>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
        <div class="card-footer">
          <b-row>
            <b-col cols="8" class="logo">
              <b-img src="../assets/logo_blanc.png"/>
            </b-col>
          </b-row>
        </div>
      </div>
  </b-container>
</template>

<script>

export default {
  name: 'Reporting',
  data() {
      return {
        labels: {
          'fr-FR': {
           labelPrevDecade: 'Décennie précédente',
           labelPrevYear: 'Année précédente',
           labelPrevMonth: 'Mois précédent',
           labelCurrentMonth: 'Mois actuel',
           labelNextMonth: 'Mois suivant',
           labelNextYear: 'Année suivante',
           labelNextDecade: 'Décennie suivante',
           labelToday: "Aujourd'hui",
           labelSelected: 'Date sélectionnée',
           labelNoDateSelected: 'Aucune date choisie',
           labelCalendar: 'Calendrier',
           labelNav: 'Navigation du calendrier',
           labelHelp: 'Naviguer dans le calendrier avec les touches fléchées'
         }
        },
        devis: [],
        fields: [
          {
            key: 'created_at',
            label: 'Date',
            sortable: true,
            formatter: value => {
              return value.split("T")[0]
            }
          },
          {
            key: 'type',
            label: 'Type',
            sortable: true
          },
          {
            key: 'departement',
            label: 'Départe.',
            sortable: true
          },
          {
            key: 'ville',
            label: 'Ville',
            sortable: true,
          },
          {
            key: 'devis_fait_par',
            label: 'Généré Par',
            sortable: false
          },
          {
            key: 'mail',
            label: 'Mail',
            sortable: false
          },
          {
            key: 'telephone',
            label: 'Tel',
            sortable: false
          },
          {
            key: 'demandeur',
            label: 'Deman.',
            sortable: false
          },
          {
            key: 'mail_demandeur',
            label: 'Mail deman.',
            sortable: false
          },
          {
            key: 'telephone_demandeur',
            label: 'Tel. deman.',
            sortable: false
          }/*,
          {
            key: 'prix',
            label:'Prix',
            sortable: false
          }*/,
          { key: 'actions', label: 'Actions' }
        ],
        start:null,
        end:null,
        perPage: 15,
        currentPage: 1,
      }
    },
    methods: {
      downloadPDF(id){
        return this.$api_url+id+".pdf";
      },
      exportCSV(event){
        event.preventDefault;
        const baseURI = this.$api_url+'export_devis';
        let request = {}
        if(this.start!=null && this.start!=""){
          request['start'] = this.start;
        }
        if(this.end!=null && this.end!=""){
          request['end'] = this.end;
        }
        this.$http.post(baseURI,{"request":request})
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'export_devis.csv');
          document.body.appendChild(link);
          link.click();
        });
      },
    },
    computed: {
      total_devis(){
        return this.items.length;
      },
      total_prix(){
        let res = 0;
        this.items.forEach((item) => {
          res += parseFloat(item.prix);
        });
        res = res.toFixed(2);
        return res;
      },
      items(){
        if(this.devis.length>0){
          console.log(this.devis[1].created_at);
          console.log(this.end);
        }
        let res;
        if((this.start!=null && this.start!="") && (this.end!=null && this.end!="")){
          res = this.devis.filter((elem) => {
            var tmp = elem.created_at.split("T");
            return new Date(tmp[0]).getTime() >= new Date(this.start).getTime() && new Date(tmp[0]).getTime() <= new Date(this.end).getTime()
          });
        }else if((this.start!=null && this.start!="")){
          res = this.devis.filter((elem) => {
            var tmp = elem.created_at.split("T");
            return new Date(tmp[0]).getTime() >= new Date(this.start).getTime()
          });
        }else if((this.end!=null && this.end!="")){
          res = this.devis.filter((elem) => {
            var tmp = elem.created_at.split("T");
            return new Date(tmp[0]).getTime()<= new Date(this.end).getTime()
          });
        }else{
          res = this.devis;
        }
        return res;
      },
    },mounted(){
      const baseURI = this.$api_url+'devis';
      this.$http.get(baseURI)
      .then((result) => {
        console.log(result);
        this.devis = result.data;
        this.devis.forEach((item) => {
          item['_showDetails'] = true;
          if(item['ref_chantier']==""){
            let tmp = JSON.parse(item['content']);
            item['ref_chantier'] = tmp.informations.ref_chantier;
          }
        });
      });
    }
}
</script>

<style lang="scss">
#reporting{
  table.table {
    tr.b-table-details td{
      padding-top:0;
    }
    th, td{
      padding-top: 1.1rem;
      padding-bottom: 1rem;
      font-size: 9pt;
      padding-left: 0;
    }
  }


}
</style>
