<template>
  <div id="img_admin">
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <div class="content-block shadow-box">
          <div class='header-form'>
            <p><span>Images</span><br></p>
          </div>
          <div class="content-form roundButton">
            <b-alert
              :show="dismissCountDown"
              dismissible
              variant="warning"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
            >
              <p>Une image est déja associé à ce LIDIC. Supprimer cette image si vous voulez la remplacer.</p>
            </b-alert>
            <b-row><b-col>Upload Nouveau Fichier</b-col></b-row>
            <b-row>
              <b-col>
                <form @submit.prevent="handleSubmit">
                <div class="form-group">
                    <input type="file" @change="uploadFile" name="new_images" multiple>
                </div>

                <div class="form-group">
                    <button class="btn btn-success btn-block btn-lg">Upload</button>
                </div>
            </form>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="2">
                <b-form-group label="Filtrer" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter_img" type="search" placeholder="Rechercher par LIDIC, Nom"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-pagination
              v-model="currentPage"
              :total-rows="total_rows"
              :per-page="perPage"
              aria-controls="my-tableImg"
            ></b-pagination>
            <b-table responsive striped hover :items="img" :fields="fields_img" :filter="filter_img" id="my-tableImg" :per-page="perPage" :current-page="currentPage" @filtered="onFiltered">
              <template #cell(vignette)="row">
                <b-img :src="`/img/${row.item.name}`"/>
              </template>
              <template #cell(actions)="row">
                <b-row>
                  <b-col cols="2">
                    <label :for="'img_lidic_'+row.index">Associé LIDIC: </label>
                  </b-col>
                  <b-col cols="3">
                    <b-form-input :id="'img_lidic_'+row.index" list="my-list-pt-image" v-model="row.item.lidic" @input="updateLidicImg(row.item)" @blur="putLidicImg(row.item)"></b-form-input>
                  </b-col>
                  <b-col></b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button size="sm" target="_blank" @click="deleteItem(row.item,'img')" class="mr-1">Supprimer</b-button>
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </div>
        </div>
      </b-col>
    </b-row>
    <datalist id="my-list-pt-image">
        <option :value="prod.value" v-for="(prod,index3) in lidic_params" :key="index3">{{ prod.text }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'ImageAdmin',
  props: {
    img: Array,
    product: Array,
    lidic_img: Array,
    total_rows: Number
  },
  data() {
    return {
      fields_img: [
        { key: 'vignette', label: '',tdClass: 'img-vignette' },
        {
          key: 'name',
          label: 'Nom du fichier',
          sortable: true
        },
        {
          key: 'product_name',
          label: 'Nom Produit',
          sortable: true
        },
        { key: 'actions', label: 'Actions' }
      ],
      lidic_params:[],
      dismissCountDown: 0,
      perPage: 20,
      currentPage:1,
      files: null,
      filter_img: null,
    }
  },
  methods: {
    onFiltered(filteredItems){
      this.total_rows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteItem(item,type){
      switch(type){
        case 'img':
          console.log(this.img[item.id]);
          this.$http.delete(`${this.$api_url}img/${this.img[item.id].name}`).then((result) => {
            if(result){
              let lidic_tmp = this.img[item.id].lidic;
              let indexLidic = this.lidic_img.findIndex((element) => element == lidic_tmp);
              if(indexLidic!=-1) this.lidic_img.splice(indexLidic,1);

              this.img.splice(item.id,1);
            }else{
              console.log(result);
            }
          });
        break;
      }
    },
    countDownChanged(dismisscd){
      this.dismissCountDown = dismisscd;
    },
    uploadFile(event){
      this.files = event.target.files;
    },
    handleSubmit() {
      const formData = new FormData();
      for (const i of Object.keys(this.files)) {
        formData.append('new_images', this.files[i]);
      }

      this.$http.post(this.$api_url+'img', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }}).then((res) => {
        res.data.filenames.forEach((item) => {
          let lidic_tmp = item.split(".");
          let index_lidic = this.product.findIndex((element) => element.lidic == lidic_tmp[0]);
          if(index_lidic==-1){
            this.img.push({id:this.img.length,name:item,product_name:'',lidic:''});
          }else{
            let index_img_lidic = this.img.findIndex((element) => element.lidic == lidic_tmp[0]);
            if(index_img_lidic==-1){
              this.img.push({id:this.img.length,name:item,product_name:this.product[index_lidic].name,lidic:this.product[index_lidic].lidic});
              this.lidic_img.push(this.product[index_lidic].lidic);
            }else{
              this.img[index_img_lidic].name=item;
            }
          }
        });
        console.log(this.img);
      })
    },
    updateLidicImg(lidic_item){
      let res = [];
      this.product.forEach((item) => {
        if(lidic_item.lidic.toString().length>3 && (item.lidic.toString().includes(lidic_item.lidic) || item.nom.toString().includes(lidic_item.lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    putLidicImg(lidic_item){
      var baseURI = this.$api_url+'img';
      let indexLidic = this.lidic_img.findIndex((element) => element == lidic_item.lidic);
      console.log(indexLidic);
      console.log(lidic_item.id);
      if(indexLidic==lidic_item.id || indexLidic==-1){
        this.$http.put(baseURI,{name: lidic_item.name,lidic: lidic_item.lidic})
        .then((result) => {
          console.log(result);
          if(result){
            this.img[lidic_item.id].name = result.data.name;
            let indexProduct = this.product.findIndex((element) => element.lidic == lidic_item.lidic);
            this.img[lidic_item.id].product_name = this.product[indexProduct].nom;
            if(indexLidic==-1){
              this.lidic_img.push(lidic_item.lidic);
            }
          }else{
            console.log(result);
          }
        });
      }else{
        this.dismissCountDown = 5;
        this.img[lidic_item.id].lidic = this.lidic_img[lidic_item.id];
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
