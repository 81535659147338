import Vue from 'vue'
import Vuex from 'vuex'
import { DevisModule } from './Devis'
import { ProductsModule } from './Products'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    devis: DevisModule,
    products: ProductsModule
  }
})
