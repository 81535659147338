<template>
  <div id="collectif_admin">
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <div class="content-block shadow-box">
          <div class='header-form'>
            <p><span>Habitat Collectif</span><br></p>
          </div>
          <div class="content-form roundButton">
          <b-tabs>
            <b-tab title="Calcul" active>
              <b-row v-if="editCalcul">
                <b-col cols="12" md="12" lg="12">
                  <div class="content-block shadow-box">
                    <div class='header-form'>
                      <p><span>{{getcurrentCaculType}}</span><br></p>
                    </div>
                    <div class="content-form roundButton">
                      <b-row>
                        <b-col cols="2">
                          <label for="statut_calcul">Status: </label>
                        </b-col>
                        <b-col>
                          <b-form-select v-model="currentCalcul.status" :options="status" id="statut_calcul"></b-form-select>
                        </b-col>
                      </b-row>
                      <div class="content-block shadow-box" v-if="editCalculParams">
                          <div class='header-form'>
                            <p><span>Paramètre</span><br></p>
                          </div>
                          <div class="content-form roundButton">
                            <b-row>
                            <b-col>
                              <b-row>
                                <b-col cols="2">
                                  <label for="param_type">Type: </label>
                                </b-col>
                                <b-col>
                                  <b-form-select v-model="currentCalculParams.type" :options="param_type" id="param_type" @change="updateParams('calcul')"></b-form-select>
                                </b-col>
                              </b-row>
                            <b-row v-if="is_paramType('piece_technique',true)">
                              <b-col>
                                <b-tabs>
                                  <b-tab v-for="(piece_technique,index) in piece_technique_range_calcul" :key="index" :title="piece_technique.text">
                                  <b-col>
                                    <b-row v-if="currentCalculParams[piece_technique.value]['220V']">
                                      <b-col>
                                        <b-tabs>
                                          <b-tab v-for="(commande,indexC) in commande" :key="indexC" :title="commande.text">
                                            <b-row v-if="currentCalculParams[piece_technique.value][commande.value]['main']">
                                              <b-col>
                                                <b-tabs>
                                                  <b-tab title="Principal">
                                                    <b-row v-for="(typo,index) in typologie_calcul" :key="index">
                                                      <b-col>
                                                        <b-row>
                                                          <b-col cols="1">
                                                            <h5>T{{typo}}</h5>
                                                          </b-col>
                                                        </b-row>
                                                        <b-row v-for="(prod_typo,index2) in currentCalculParams[piece_technique.value][commande.value]['main'][typo]" :key="index2">
                                                          <b-col cols="1">
                                                            <label :for="'pt_value_'+piece_technique.value+'_main'+commande.value+'_'+index+'_'+index2+'_'+commande.value">Produit: </label>
                                                          </b-col>
                                                          <b-col cols="2">
                                                            <b-form-input :id="'pt_value_'+piece_technique.value+'_main'+commande.value+'_'+index+'_'+index2+'_'+commande.value" list="my-list-pt-collectif" v-model="currentCalculParams[piece_technique.value][commande.value]['main'][typo][index2].lidic" @input="updateLidicPTCommande(piece_technique.value,commande.value,typo,index2,true,false,'calcul')" @blur="clearLidicBouche"></b-form-input>
                                                          </b-col>
                                                          <b-col cols="3">
                                                            <b-button size="sm" target="_blank" @click="remove_item_pt(piece_technique.value,commande.value,true,true,typo,index2)" class="mr-1 squarebutton">-</b-button>
                                                          </b-col>
                                                        </b-row>
                                                        <b-row>
                                                          <b-col offset="9" cols="3">
                                                            <b-button size="sm" target="_blank" @click="add_item_pt(piece_technique.value,commande.value,true,true,typo)" class="mr-1 squarebutton">+</b-button>
                                                          </b-col>
                                                        </b-row>
                                                      </b-col>
                                                    </b-row>
                                                  </b-tab>
                                                  <b-tab title="Supplementaire">
                                                    <b-row v-for="(typo,index) in typologie_calcul" :key="index">
                                                      <b-col>
                                                        <b-row>
                                                          <b-col cols="1">
                                                            <h5>T{{typo}}</h5>
                                                          </b-col>
                                                        </b-row>
                                                        <b-row v-for="(prod_typo,index2) in currentCalculParams[piece_technique.value][commande.value]['supp'][typo]" :key="index2">
                                                          <b-col cols="1">
                                                            <label :for="'pt_value_'+piece_technique.value+'_'+commande.value+'_supp'+index+'_'+index2+'_'+commande.value">Produit: </label>
                                                          </b-col>
                                                          <b-col cols="2">
                                                            <b-form-input :id="'pt_value_'+piece_technique.value+'_'+commande.value+'_supp'+index+'_'+index2+'_'+commande.value" list="my-list-pt-collectif" v-model="currentCalculParams[piece_technique.value][commande.value]['supp'][typo][index2].lidic" @input="updateLidicPTCommande(piece_technique.value,commande.value,typo,index2,true,true,'calcul')" @blur="clearLidicBouche"></b-form-input>
                                                          </b-col>
                                                          <b-col cols="3">
                                                            <b-button size="sm" target="_blank" @click="remove_item_pt(piece_technique.value,commande.value,true,false,typo,index2)" class="mr-1 squarebutton">-</b-button>
                                                          </b-col>
                                                        </b-row>
                                                        <b-row>
                                                          <b-col offset="9" cols="3">
                                                            <b-button size="sm" target="_blank" @click="add_item_pt(piece_technique.value,commande.value,true,false,typo)" class="mr-1 squarebutton">+</b-button>
                                                          </b-col>
                                                        </b-row>
                                                      </b-col>
                                                    </b-row>
                                                  </b-tab>
                                                </b-tabs>
                                              </b-col>
                                            </b-row>
                                            <b-row v-if="!currentCalculParams[piece_technique.value][commande.value]['main']">
                                              <b-col>
                                                <b-row v-for="(typo,index) in typologie_calcul" :key="index">
                                                  <b-col>
                                                    <b-row>
                                                      <b-col cols="1">
                                                        <h5>T{{typo}}</h5>
                                                      </b-col>
                                                    </b-row>
                                                    <b-row v-for="(prod_typo,index2) in currentCalculParams[piece_technique.value][commande.value][typo]" :key="index2">
                                                      <b-col cols="1">
                                                        <label :for="'pt_value_'+piece_technique.value+'_'+index+'_'+index2+'_'+commande.value">Produit: </label>
                                                      </b-col>
                                                      <b-col cols="2">
                                                        <b-form-input :id="'pt_value_'+piece_technique.value+'_'+index+'_'+index2+'_'+commande.value" list="my-list-pt-collectif" v-model="currentCalculParams[piece_technique.value][commande.value][typo][index2].lidic" @input="updateLidicPTCommande(piece_technique.value,commande.value,typo,index2,false,false,'calcul')" @blur="clearLidicBouche"></b-form-input>
                                                      </b-col>
                                                      <b-col cols="3">
                                                        <b-button size="sm" target="_blank" @click="remove_item_pt(piece_technique.value,commande.value,false,false,typo,index2)" class="mr-1 squarebutton">-</b-button>
                                                      </b-col>
                                                    </b-row>
                                                    <b-row>
                                                      <b-col offset="9" cols="3">
                                                        <b-button size="sm" target="_blank" @click="add_item_pt(piece_technique.value,commande.value,false,false,typo)" class="mr-1 squarebutton">+</b-button>
                                                      </b-col>
                                                    </b-row>
                                                  </b-col>
                                                </b-row>
                                              </b-col>
                                            </b-row>
                                          </b-tab>
                                        </b-tabs>
                                      </b-col>
                                    </b-row>
                                    <b-row v-if="currentCalculParams[piece_technique.value]['main']">
                                      <b-col>
                                        <b-tabs>
                                          <b-tab title="Principal">
                                            <b-row v-for="(typo,index) in typologie_calcul" :key="index">
                                              <b-col>
                                                <b-row>
                                                  <b-col cols="1">
                                                    <h5>T{{typo}}</h5>
                                                  </b-col>
                                                </b-row>
                                                <b-row v-for="(prod_typo,index2) in currentCalculParams[piece_technique.value]['main'][typo]" :key="index2">
                                                  <b-col cols="1">
                                                    <label :for="'pt_value_'+piece_technique.value+'_main'+index+'_'+index2">Produit: </label>
                                                  </b-col>
                                                  <b-col cols="2">
                                                    <b-form-input :id="'pt_value_'+piece_technique.value+'_main'+index+'_'+index2" list="my-list-pt-collectif" v-model="currentCalculParams[piece_technique.value]['main'][typo][index2].lidic" @input="updateLidicPT(piece_technique.value,typo,index2,true,false,'calcul')" @blur="clearLidicBouche"></b-form-input>
                                                  </b-col>
                                                  <b-col cols="3">
                                                    <b-button size="sm" target="_blank" @click="remove_item_pt(piece_technique.value,'',true,true,typo,index2)" class="mr-1 squarebutton">-</b-button>
                                                  </b-col>
                                                </b-row>
                                                <b-row>
                                                  <b-col offset="9" cols="3">
                                                    <b-button size="sm" target="_blank" @click="add_item_pt(piece_technique.value,'',true,true,typo)" class="mr-1 squarebutton">+</b-button>
                                                  </b-col>
                                                </b-row>
                                              </b-col>
                                            </b-row>
                                          </b-tab>
                                          <b-tab title="Supplementaire">
                                            <b-row v-for="(typo,index) in typologie_calcul" :key="index">
                                              <b-col>
                                                <b-row>
                                                  <b-col cols="1">
                                                    <h5>T{{typo}}</h5>
                                                  </b-col>
                                                </b-row>
                                                <b-row v-for="(prod_typo,index2) in currentCalculParams[piece_technique.value]['supp'][typo]" :key="index2">
                                                  <b-col cols="1">
                                                    <label :for="'pt_value_'+piece_technique.value+'_supp'+index+'_'+index2">Produit: </label>
                                                  </b-col>
                                                  <b-col cols="2">
                                                    <b-form-input :id="'pt_value_'+piece_technique.value+'_supp'+index+'_'+index2" list="my-list-pt-collectif" v-model="currentCalculParams[piece_technique.value]['supp'][typo][index2].lidic" @input="updateLidicPT(piece_technique.value,typo,index2,true,true,'calcul')" @blur="clearLidicBouche"></b-form-input>
                                                  </b-col>
                                                  <b-col cols="3">
                                                    <b-button size="sm" target="_blank" @click="remove_item_pt(piece_technique.value,'',true,false,typo,index2)" class="mr-1 squarebutton">-</b-button>
                                                  </b-col>
                                                </b-row>
                                                <b-row>
                                                  <b-col offset="9" cols="3">
                                                    <b-button size="sm" target="_blank" @click="add_item_pt(piece_technique.value,'',true,false,typo)" class="mr-1 squarebutton">+</b-button>
                                                  </b-col>
                                                </b-row>
                                              </b-col>
                                            </b-row>
                                          </b-tab>
                                        </b-tabs>
                                      </b-col>
                                    </b-row>
                                    <b-row v-if="!currentCalculParams[piece_technique.value]['220V'] && !currentCalculParams[piece_technique.value]['main']">
                                      <b-col>
                                        <b-row v-for="(typo,index) in typologie_calcul" :key="index">
                                          <b-col>
                                            <b-row>
                                              <b-col cols="1">
                                                <h5>T{{typo}}</h5>
                                              </b-col>
                                            </b-row>
                                            <b-row v-for="(prod_typo,index2) in currentCalculParams[piece_technique.value][typo]" :key="index2">
                                              <b-col cols="1">
                                                <label :for="'pt_value_'+piece_technique.value+'_'+index+'_'+index2">Produit: </label>
                                              </b-col>
                                              <b-col cols="2">
                                                <b-form-input :id="'pt_value_'+piece_technique.value+'_'+index+'_'+index2" list="my-list-pt-collectif" v-model="currentCalculParams[piece_technique.value][typo][index2].lidic" @input="updateLidicPT(piece_technique.value,typo,index2,false,false,'calcul')" @blur="clearLidicBouche"></b-form-input>
                                              </b-col>
                                              <b-col cols="3">
                                                <b-button size="sm" target="_blank" @click="remove_item_pt(piece_technique.value,'',false,false,typo,index2)" class="mr-1 squarebutton">-</b-button>
                                              </b-col>
                                            </b-row>
                                            <b-row>
                                              <b-col offset="9" cols="3">
                                                <b-button size="sm" target="_blank" @click="add_item_pt(piece_technique.value,'',false,false,typo)" class="mr-1 squarebutton">+</b-button>
                                              </b-col>
                                            </b-row>
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-tab>
                              </b-tabs>
                              </b-col>
                            </b-row>
                            <b-row v-if="is_paramType('bouche',true)">
                              <b-col>
                                <b-tabs>
                                <b-tab title="220V" v-if="currentCalculParams['220V']">
                                  <b-row v-for="(bouche,index) in typologie_calcul" :key="index">
                                    <b-col>
                                      <b-row><b-col><h6>T{{bouche}}</h6></b-col></b-row>
                                      <b-row v-for="(bouche_type,index2) in currentCalculParams['220V'][bouche]" :key="index2" >
                                        <b-col cols="2">
                                          <label :for="'bouche_value_220V'+index+'_'+index2">Produit: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'bouche_value_220V'+index+'_'+index2" list="my-list-pt-collectif" v-model="currentCalculParams['220V'][bouche][index2].lidic" @input="updateLidicCommande('220V',bouche,index2)" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                            <label :for="'bouche_label_220V'+index+'_'+index2">Nombre: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-spinbutton inline :id="'bouche_label_220V'+index+'_'+index2" v-model="currentCalculParams['220V'][bouche][index2].number" min="0" size="sm"></b-form-spinbutton>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_commande(bouche,index2,'220V')" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_commande(bouche,'220V')" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-tab>
                                <b-tab title="Manuel" v-if="currentCalculParams['M']">
                                  <b-row v-for="(bouche,index) in typologie_calcul" :key="index">
                                    <b-col>
                                      <b-row><b-col><h6>T{{bouche}}</h6></b-col></b-row>
                                      <b-row v-for="(bouche_type,index2) in currentCalculParams['M'][bouche]" :key="index2" >
                                        <b-col cols="2">
                                          <label :for="'bouche_value_M'+index+'_'+index2">Produit: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'bouche_value_M'+index+'_'+index2" list="my-list-pt-collectif" v-model="currentCalculParams['M'][bouche][index2].lidic" @input="updateLidicCommande('M',bouche,index2)" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                            <label :for="'bouche_label_M'+index+'_'+index2">Nombre: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-spinbutton inline :id="'bouche_label_M'+index+'_'+index2" v-model="currentCalculParams['M'][bouche][index2].number" min="0" size="sm"></b-form-spinbutton>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_commande(bouche,index2,'M')" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_commande(bouche,'M')" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-tab>
                                <b-tab title="A Pile" v-if="currentCalculParams['P']">
                                  <b-row v-for="(bouche,index) in typologie_calcul" :key="index">
                                    <b-col>
                                      <b-row><b-col><h6>T{{bouche}}</h6></b-col></b-row>
                                      <b-row v-for="(bouche_type,index2) in currentCalculParams['P'][bouche]" :key="index2" >
                                        <b-col cols="2">
                                          <label :for="'bouche_value_P'+index+'_'+index2">Produit: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'bouche_value_P'+index+'_'+index2" list="my-list-pt-collectif" v-model="currentCalculParams['P'][bouche][index2].lidic" @input="updateLidicCommande('P',bouche,index2)" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                            <label :for="'bouche_label_P'+index+'_'+index2">Nombre: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-spinbutton inline :id="'bouche_label_P'+index+'_'+index2" v-model="currentCalculParams['P'][bouche][index2].number" min="0" size="sm"></b-form-spinbutton>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_commande(bouche,index2,'P')" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_commande(bouche,'P')" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-tab>
                                <b-tab title="12V" v-if="currentCalculParams['12V']">
                                  <b-row v-for="(bouche,index) in typologie_calcul" :key="index">
                                    <b-col>
                                      <b-row><b-col><h6>T{{bouche}}</h6></b-col></b-row>
                                      <b-row v-for="(bouche_type,index2) in currentCalculParams['12V'][bouche]" :key="index2" >
                                        <b-col cols="2">
                                          <label :for="'bouche_value_12V'+index+'_'+index2">Produit: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'bouche_value_12V'+index+'_'+index2" list="my-list-pt-collectif" v-model="currentCalculParams['12V'][bouche][index2].lidic" @input="updateLidicCommande('12V',bouche,index2)" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                            <label :for="'bouche_label_12V'+index+'_'+index2">Nombre: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-spinbutton inline :id="'bouche_label_12V'+index+'_'+index2" v-model="currentCalculParams['12V'][bouche][index2].number" min="0" size="sm"></b-form-spinbutton>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_commande(bouche,index2,'12V')" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_commande(bouche,'12V')" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-tab>
                              </b-tabs>
                              </b-col>
                            </b-row>
                            <b-row v-if="is_paramType('entree',true)">
                              <b-col v-if="currentCalculParams['entree']">
                                <b-tabs>
                                <b-tab title="Entrée d'air">
                                  <b-row v-for="(bouche,index) in typologie_calcul" :key="index">
                                    <b-col>
                                      <b-row><b-col><h6>T{{bouche}}</h6></b-col></b-row>
                                      <b-row v-for="(bouche_type,index2) in currentCalculParams['entree'][bouche]" :key="index2" >
                                        <b-col cols="2">
                                          <label :for="'insufflation_value_entree'+index+'_'+index2">Produit: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'insufflation_value_entree'+index+'_'+index2" list="my-list-pt-collectif" v-model="currentCalculParams['entree'][bouche][index2].lidic" @input="updateLidicEntree(false,bouche,index2,'calcul')" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                            <label :for="'options_label_entree'+index+'_'+index2">Nombre: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-spinbutton inline :id="'options_label_entree'+index+'_'+index2" v-model="currentCalculParams['entree'][bouche][index2].number" min="0" size="sm"></b-form-spinbutton>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_entree(false,bouche,index2,'calcul')" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_entree(false,bouche)" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-tab>
                                <b-tab title="Entrée d'air avec Rallonge">
                                  <b-row v-for="(bouche,index) in typologie_calcul" :key="index">
                                    <b-col>
                                      <b-row><b-col><h6>T{{bouche}}</h6></b-col></b-row>
                                      <b-row v-for="(bouche_type,index2) in currentCalculParams['rallonge'][bouche]" :key="index2" >
                                        <b-col cols="2">
                                          <label :for="'insufflation_value_rallonge'+index+'_'+index2">Produit: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'insufflation_value_rallonge'+index+'_'+index2" list="my-list-pt-collectif" v-model="currentCalculParams['rallonge'][bouche][index2].lidic" @input="updateLidicEntree(true,bouche,index2,'calcul')" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                            <label :for="'options_label_rallonge'+index+'_'+index2">Nombre: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-spinbutton inline :id="'options_label_rallonge'+index+'_'+index2" v-model="currentCalculParams['rallonge'][bouche][index2].number" min="0" size="sm"></b-form-spinbutton>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_entree(true,bouche,index2,'calcul')" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_entree(true,bouche)" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-tab>
                              </b-tabs>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-button size="sm" target="_blank" @click="close('calcul_params')" class="mr-1">Fermer</b-button>
                              </b-col>
                            </b-row>
                          </b-col>
                          </b-row>
                        </div>
                      </div>
                      <b-row align-h="end">
                        <b-col cols="2">
                          <b-button size="sm" target="_blank" @click="editItem(-1,'calcul_params')" class="mr-1">Ajouter Paramètre</b-button>
                        </b-col>
                      </b-row>
                      <b-table responsive striped hover :items="currentCalcul.params.items" :fields="fields_params">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'calcul_params')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'calcul_params')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                      <b-row>
                        <b-col cols="2">
                          <b-button size="sm" target="_blank" @click="cancel('calcul_params')" class="mr-1">Annuler</b-button>
                        </b-col>
                        <b-col cols="8"></b-col>
                        <b-col cols="2">
                          <b-button size="sm" target="_blank" @click="saveItem('calcul_params')" class="mr-1">Enregistrer</b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!editCalcul">
                <b-col>
                  <b-table responsive striped hover :items="collectif.calcul" :fields="fields_calcul" id="collectif_calcul" :tbody-tr-class="rowClass">
                    <template #cell(actions)="row">
                      <b-button size="sm" target="_blank" @click="editItem(row.item,'calcul')" class="mr-1">Editer</b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="VMC">
              <b-row  v-if="editVMC">
                <b-col cols="12" md="12" lg="12">
                  <div class="content-block shadow-box">
                    <div class='header-form'>
                      <p><span>{{currentVMC.nom}}</span><br></p>
                    </div>
                    <div class="content-form roundButton">
                      <b-row>
                        <b-col cols="2">
                          <label for="vmc_input">VMC: </label>
                        </b-col>
                        <b-col>
                        <b-form-input id="vmc_input" list="my-list-id" v-model="currentVMC.lidic" @blur="updateVMC" @input="updateLidicProduct()"></b-form-input>
                        <datalist id="my-list-id">
                            <option :value="prod.value" v-for="(prod,index) in lidic_product" :key="index">{{ prod.text }}</option>
                        </datalist>
                      </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="categorie_input">Catégorie: </label>
                        </b-col>
                        <b-col>
                          <b-form-select v-model="currentVMC.categorie" :options="categorie" id="categorie_input"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="type_input">Type: </label>
                        </b-col>
                        <b-col>
                          <b-form-select id="type_input" v-model="currentVMC.type" :options="type"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="tension_input">Tension: </label>
                        </b-col>
                        <b-col>
                          <b-form-select id="tension_input" v-model="currentVMC.tension" :options="tension"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="aspiration_input">Type d'aspiration: </label>
                        </b-col>
                        <b-col>
                          <b-form-select id="aspiration_input" v-model="currentVMC.type_aspiration" :options="aspiration" multiple="multiple"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="debit_input">Débit: </label>
                        </b-col>
                        <b-col>
                          <b-form-select id="debit_input" v-model="currentVMC.debit_max" :options="debit" multiple></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="diam_aspi_input">Diamètre Aspiration: </label>
                        </b-col>
                        <b-col>
                          <b-form-input v-model.number="currentVMC.diametre_aspi"  id="diam_aspi_input"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="diam_rejet_input">Diamètre Rejet: </label>
                        </b-col>
                        <b-col>
                          <b-form-input v-model.number="currentVMC.diametre_rejet"  id="diam_rejet_input"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="statut_input">Status: </label>
                        </b-col>
                        <b-col>
                          <b-form-select v-model="currentVMC.status" :options="status" id="statut_input"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-tabs pills>
                            <b-tab title="Options" active>
                              <div class="content-block shadow-box" v-if="editOptions">
                                <div class='header-form'>
                                  <p><span>Options</span><br></p>
                                </div>
                                <div class="content-form roundButton">
                                  <b-row>
                                  <b-col>
                                  <b-row>
                                    <b-col cols="2">
                                        <label for="options_libelle">Libelle: </label>
                                      </b-col>
                                      <b-col>
                                      <b-form-input id="options_libelle" v-model="currentOptions.libelle"></b-form-input>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="2">
                                      <label for="options_type">Type d'options: </label>
                                    </b-col>
                                    <b-col>
                                      <b-form-select v-model="currentOptions.content.type" :options="options_type" id="options_type" @change="updateConfig()"></b-form-select>
                                    </b-col>
                                  </b-row>
                                  <h4>Options</h4>
                                  <b-row>
                                    <b-col cols="3">
                                      <b-form-checkbox
                                          id="options-require"
                                          v-model="currentOptions.content.optionnal"
                                          name="options-require"
                                        >
                                          En Option
                                        </b-form-checkbox>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="3">
                                      <b-form-checkbox
                                          id="options-multiple"
                                          v-model="currentOptions.content.multiple"
                                          name="options-multiple"
                                        >
                                          Multiple
                                        </b-form-checkbox>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="3">
                                      <b-form-checkbox
                                          id="options-visibility"
                                          v-model="currentOptions.content.visibility"
                                          name="options-visibility"
                                        >
                                          Visibilité
                                        </b-form-checkbox>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="is_option_checkbox()">
                                    <b-col>
                                      <b-row v-for="(opt,index) in currentOptions.content.items" :key="index">
                                        <b-col cols="2">
                                            <label :for="'options_value_'+index">Produit: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'options_value_'+index" :list="'my-list-pt-collectif'" v-model="currentOptions.content.items[index].lidic" @input="updateLidicOptions(index,true)" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                            <label :for="'options_label_'+index">Nombre: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'options_label_'+index" v-model="currentOptions.content.items[index].number"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_options(index)" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_options()" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="!is_option_checkbox()">
                                    <b-col>
                                      <b-row v-for="(opt,index) in currentOptions.content.options" :key="index">
                                        <b-col cols="2">
                                            <label :for="'options_label_'+index">Label: </label>
                                          </b-col>
                                          <b-col cols="3">
                                          <b-form-input :id="'options_label_'+index" v-model="opt.text"></b-form-input>
                                        </b-col>
                                        <b-col v-if="is_option_checkbox_visible()" cols="2">
                                          <label>Visible: </label>
                                        </b-col>
                                        <b-col v-if="is_option_checkbox_visible()" cols="2">
                                          <b-form-checkbox
                                              id="options-terasse"
                                              v-model="currentOptions.content.options[index].terasse"
                                              name="options-terasse"
                                            >
                                              En Terasse
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col v-if="is_option_checkbox_visible()" cols="2">
                                          <b-form-checkbox
                                              id="options-comble"
                                              v-model="currentOptions.content.options[index].comble"
                                              name="options-comble"
                                            >
                                              En Comble
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col v-if="is_option_checkbox_visible()" cols="1"></b-col>
                                        <b-col v-if="!is_option_checkbox_visible()" cols="7"></b-col>
                                        <b-col cols="2">
                                            <label :for="'options_value_'+index">Produit: </label>
                                          </b-col>
                                          <b-col cols="3">
                                          <b-form-input :id="'options_value_'+index" :list="'my-list-pt-collectif'" v-model="currentOptions.content.options[index].value" @input="updateLidicOptions(index,false)" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <label :for="'options_value_qte'+index">Quantité: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'options_value_qte'+index" v-model="currentOptions.content.options[index].qte"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_options(index)" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_options()" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>

                                  <b-row>
                                    <b-col>
                                      <b-button size="sm" target="_blank" @click="close('options')" class="mr-1">Fermer</b-button>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                </b-row>
                              </div>
                            </div>
                            <b-row align-h="end">
                              <b-col cols="2">
                                <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_option')" class="mr-1">Ajouter Option</b-button>
                              </b-col>
                            </b-row>
                              <b-table responsive striped hover :items="currentVMC.options" :fields="fields_options">
                                <template #cell(actions)="row">
                                  <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_option')" class="mr-1">Editer</b-button>
                                  <b-button size="sm" target="_blank" @click="deleteItem(row.index,'individuel_option')" class="mr-1">Supprimer</b-button>
                                </template>
                              </b-table>
                            </b-tab>
                            <b-tab title="Paramètre">
                              <div class="content-block shadow-box" v-if="editParams">
                                <div class='header-form'>
                                  <p><span>Paramètre</span><br></p>
                                </div>
                                <div class="content-form roundButton">
                                  <b-row>
                                  <b-col>
                                    <b-row>
                                      <b-col cols="2">
                                        <label for="param_vmc_type">Type: </label>
                                      </b-col>
                                      <b-col>
                                        <b-form-select v-model="currentParams.type" :options="param_vmc_type" id="param_vmc_type" @change="updateParams('vmc')"></b-form-select>
                                      </b-col>
                                    </b-row>
                                  <b-row v-if="is_paramType('piquetage',false)">
                                    <b-col cols="2">
                                      <label :for="'piquetage_prod'">Produit: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'piquetage_prod'" list="my-list-pt-collectif" v-model="currentParams.lidic" @input="updateLidicPiquetage()" @blur="clearLidicBouche"></b-form-input>
                                    </b-col>
                                    <b-col cols="2">
                                        <label :for="'piquetage_nb'">Nombre: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-spinbutton inline :id="'piquetage_nb'" v-model="currentParams.number" min="0" size="sm"></b-form-spinbutton>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="is_paramType('barre_horizontal',false)">
                                    <b-col cols="2">
                                      <label :for="'barre_horizontal_prod'">Produit: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'barre_horizontal_prod'" list="my-list-pt-collectif" v-model="currentParams.lidic" @input="updateLidicPiquetage()" @blur="clearLidicBouche"></b-form-input>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="is_paramType('barre_vertical',false)">
                                    <b-col cols="2">
                                      <label :for="'barre_vertical_prod'">Produit: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'barre_vertical_prod'" list="my-list-pt-collectif" v-model="currentParams.lidic" @input="updateLidicPiquetage()" @blur="clearLidicBouche"></b-form-input>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-button size="sm" target="_blank" @click="close('params')" class="mr-1">Fermer</b-button>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                </b-row>
                              </div>
                            </div>
                            <b-row align-h="end">
                              <b-col cols="2">
                                <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_params')" class="mr-1">Ajouter Paramètre</b-button>
                              </b-col>
                            </b-row>
                              <b-table responsive striped hover :items="currentVMC.params.items" :fields="fields_params">
                                <template #cell(actions)="row">
                                  <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_params')" class="mr-1">Editer</b-button>
                                  <b-button size="sm" target="_blank" @click="deleteItem(row.index,'individuel_params')" class="mr-1">Supprimer</b-button>
                                </template>
                              </b-table>
                            </b-tab>
                            <b-tab title="Config">
                              <div class="content-block shadow-box" v-if="editConfig">
                                <div class='header-form'>
                                  <p><span>Configuration: {{currentConfigLabel}}</span><br></p>
                                </div>
                                <div class="content-form roundButton">
                                  <b-row>
                                    <b-col>
                                      <b-row>
                                        <b-col cols="2">
                                          <label :for="'config_label'">Label: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'config_label'" v-model="tmpConfigLabel"></b-form-input>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="!isBarreVertical()">
                                    <b-col>
                                      <b-row>
                                        <b-col cols="2">
                                            <label>Diamètre moyen</label>
                                        </b-col>
                                        <b-col cols="3">
                                          <label>Prix unitaire</label>
                                        </b-col>
                                      </b-row>
                                      <b-row v-for="(value, propertyName, index) in currentConfig" :key="propertyName" >
                                        <b-col cols="2">
                                            <label :for="'config_value_'+index">{{propertyName}}: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'config_value_'+index" v-model.number="currentConfig[propertyName]" type="number"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_config(propertyName)" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="isBarreVertical()">
                                    <b-col>
                                      <b-row>
                                        <b-col cols="2">
                                            <label>Débit</label>
                                        </b-col>
                                        <b-col cols="3">
                                          <label>Prix 1er Niveau</label>
                                        </b-col>
                                        <b-col cols="3">
                                          <label>Prix Niveau Supplémentaire</label>
                                        </b-col>
                                      </b-row>
                                      <b-row v-for="(value, propertyName, index) in currentConfig" :key="propertyName" >
                                        <b-col cols="2">
                                            <label :for="'config_value_'+index">{{propertyName}}: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'config_value_'+index+'nv1'" v-model.number="currentConfig[propertyName][0]" type="number"></b-form-input>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'config_value_'+index+'nv2'" v-model.number="currentConfig[propertyName][1]" type="number"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_config(propertyName)" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="2">
                                        <label :for="'config_value_-1'">Nouvel Config: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'config_value_-1'" v-model="newConfigParam"></b-form-input>
                                    </b-col>
                                    <b-col cols="2">
                                      <b-button size="sm" target="_blank" @click="add_item_config()" class="mr-1 squarebutton">+</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-button size="sm" target="_blank" @click="close('config')" class="mr-1">Fermer</b-button>
                                    </b-col>
                                  </b-row>
                              </div>
                            </div>
                            <b-row align-h="end">
                              <b-col cols="2">
                                <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_config')" class="mr-1">Ajouter Config</b-button>
                              </b-col>
                            </b-row>
                              <b-table responsive striped hover :items="getCurrentConfig" :fields="fields_config">
                                <template #cell(actions)="row">
                                  <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_config')" class="mr-1">Editer</b-button>
                                  <b-button size="sm" target="_blank" @click="deleteItem(row.item,'individuel_config')" class="mr-1">Supprimer</b-button>
                                </template>
                              </b-table>
                            </b-tab>
                          </b-tabs>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <b-button size="sm" target="_blank" @click="cancel('vmc')" class="mr-1">Annuler</b-button>
                        </b-col>
                        <b-col cols="8"></b-col>
                        <b-col cols="2">
                          <b-button size="sm" target="_blank" @click="saveItem('vmc')" class="mr-1">Enregistrer</b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
            </b-row>
                <b-row  v-if="!editVMC">
                  <b-col cols="12" md="12" lg="12">
                    <div class="content-block shadow-box">
                      <div class='header-form'>
                        <p><span>VMC</span><br></p>
                      </div>
                      <div class="content-form roundButton">
                        <b-alert
                          :show="dismissCountDown"
                          dismissible
                          variant="success"
                          @dismissed="dismissCountDown=0"
                          @dismiss-count-down="countDownChanged"
                        >
                          <p>VMC enregistré avec succès.</p>
                        </b-alert>
                <b-row align-h="end">
                  <b-col cols="2">
                    <b-button size="sm" target="_blank" @click="editItem(-1,'individuel')" class="mr-1">Ajouter VMC</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6" lg="2">
                    <b-form-group label="Filtrer" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
                      <b-input-group size="sm">
                        <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Rechercher par LIDIC, Nom, Type,..."></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="total_rows_vmc"
                  :per-page="perPage"
                  aria-controls="my-table-tertiare-vmc"
                ></b-pagination>
                <b-table responsive striped hover :items="collectif.vmc" :fields="fields" :filter="filter" id="my-table" :per-page="perPage" :current-page="currentPage" :tbody-tr-class="rowClass" @filtered="onFiltered">
                <template #cell(vignette)="row">
                  <b-img :src="`/img/${row.item.lidic}.jpg`"/>
                </template>
                  <template #cell(actions)="row">
                    <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel')" class="mr-1">Editer</b-button>
                    <b-button size="sm" target="_blank" @click="copyItem(row.item,'individuel')" class="mr-1">Copier</b-button>
                    <b-button size="sm" target="_blank" @click="deleteItem(row.item,'individuel')" class="mr-1">Supprimer</b-button>
                  </template>
                </b-table>
                </div></div>
              </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      </b-col>
    </b-row>
    <datalist id="my-list-pt-collectif">
        <option :value="prod.value" v-for="(prod,index3) in lidic_params" :key="index3">{{ prod.text }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'CollectifAdmin',
  props: {
    collectif: Object,
    product: Array,
    total_rows_vmc: Number,
  },
  data() {
      return {
        dismissCountDown: 0,
        currentVMC:{},
        currentVMCLidic: -1,
        currentOptions:{},
        currentParams:{},
        currentConfig:{},
        currentCalcul:{},
        currentCalculParams: {},
        typologie_calcul: [1,2,3,4,5,6,7],
        newConfig: 'Nouveau Label',
        currentGaine:{},
        currentGaineLidic: null,
        tmpGaineLidic: null,
        currentConfigLabel: null,
        tmpConfigLabel: null,
        lidic_options:[],
        lidic_params:[],
        lidic_product:[],
        newConfigParam: '',
        editVMC:false,
        editCalcul: false,
        editCalculParams: false,
        editOptions:false,
        editParams:false,
        editConfig:false,
        editGaines:false,
        filter: null,
        perPage: 20,
        currentPage: 1,
        fields_calcul:[
          {
            key: 'id',
            label: 'Type',
            sortable: true,
            formatter: (value) => {
              let res = '';
              switch(value){
                case 'A':
                  res = 'Auto';
                break;
                case 'HA':
                  res = 'Hydro A';
                break;
                case 'HB':
                  res = 'Hydro B';
                break;
              }
              return res;
            }
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_config: [
          {
            key: 'label',
            label: 'label',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_params: [
          {
            key: 'type',
            label: 'Type',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_gaines: [
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_options: [
          {
            key: 'libelle',
            label: 'Libelle',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields: [
          { key: 'vignette', label: '',tdClass: 'product-vignette' },
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          {
            key: 'nom',
            label: 'Nom',
            sortable: true
          },
          {
            key: 'categorie',
            label: 'Type',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        typologie:[{
          value:1,
          text:'T1'
        },{
          value:2,
          text:'T2'
        },{
          value:3,
          text:'T3'
        },{
          value:4,
          text:'T4'
        },{
          value:5,
          text:'T5'
        },{
          value:6,
          text:'T6'
        },{
          value:7,
          text:'T7'
        }],
        reglementation:[{
          value:'E',
          text:'Existant'
        },
        {
          value:'N',
          text:'Construction Neuve'
        },{
          value:'E/N',
          text:'Existant ou Construction Neuve'
        }],
        options_type:[{value:'',text:'Classique'},{value:'checkbox',text:'Oui/Non'}],
        param_type:[{value:'bouche',text:'Bouche Commande'},
                    {value:'piece_technique','text':'Pièces Techniques'},
                   {value:'entree','text':"Bouche d'entrée"}],
        param_vmc_type:[{value:'piquetage',text:'Piquetage'},{value:'barre_horizontal',text:'Barre Horizontal'},{value:'barre_vertical',text:'Barre Vertical'}],
        flux:[{value:'Simple Flux',text:'Simple Flux'},{value:'Double Flux',text:'Double Flux'}],
        status:[{value:'valid',text:'Validé'},{value:'error',text:'Erreur Paramétrage'},{value:'waiting',text:'En attente de validation'}],
        categorie:[{value:'Caisson Très Basse Conso. Régulé',text:'Caisson Très Basse Consommation Régulé'},{value:'Caisson Basse Conso.',text:'Caisson Basse Consommation'},{value:'Caisson Basse Conso. Régulé',text:'Caisson Basse Consommation Régulé'},{value:'Caisson Standard',text:'Caisson Standard'}],
        type:[{value:'CACB ECM ECO',text:'CACB ECM ECO'},{value:'CACB ECM REJET VERTICAL',text:'CACB ECM REJET VERTICAL'},{value:'CACB MV REJET HORIZ/VERTICAL',text:'CACB MV REJET HORIZ/VERTICAL'},
      {value:'CRCB ECOWATT REJET HORIZONTAL',text:'CRCB ECOWATT REJET HORIZONTAL'},{value:'CRCB ECOWATT ISOLE  REJET HORIZONTAL',text:'CRCB ECOWATT ISOLE  REJET HORIZONTAL'},{value:'CRCB ECOWATT ISOLE  REJET VERTICAL',text:'CRCB ECOWATT ISOLE  REJET VERTICAL'},
    {value:'CRCB ECOWATT REJET VERTICAL',text:'CRCB ECOWATT REJET VERTICAL'},{value:'CRCB ECOWATT PM  REJET HORIZONTAL',text:'CRCB ECOWATT PM  REJET HORIZONTAL'},{value:'CRCB ECOWATT PM  REJET VERTICAL',text:'CRCB ECOWATT PM  REJET VERTICAL'},
  {value:'CRCB ECOWATT PM ISOLE  REJET HORIZONTAL',text:'CRCB ECOWATT PM ISOLE  REJET HORIZONTAL'},{value:'CRCB ECOWATT PM ISOLE  REJET VERTICAL',text:'CRCB ECOWATT PM ISOLE  REJET VERTICAL'},{value:'CRCB ECOWATT TRI  ISOLE  REJET VERTICAL',text:'CRCB ECOWATT TRI  ISOLE  REJET VERTICAL'},
{value:'CRCB ECOWATT TRI ISOLE  REJET VERTICAL',text:'CRCB ECOWATT TRI ISOLE  REJET VERTICAL'},{value:'CRCB ECOWATT TRI REJET VERTICAL',text:'CRCB ECOWATT TRI REJET VERTICAL'},{value:'CACT N ECO TRI',text:'CACT N ECO TRI'},{value:'CACB ECM ECO',text:'CACB ECM ECO'},
{value:'CACB MV 005 DI',text:'CACB MV 005 DI'},
{value:'CACB MV 008 DI',text:'CACB MV 008 DI'},
{value:'CACB ECM 007-1DI',text:'CACB ECM 007-1DI'},
{value:'CACB ECM 012-1DI',text:'CACB ECM 012-1DI'},
{value:'CACB ECM 015-1DI',text:'CACB ECM 015-1DI'},
{value:'CACB ECM 021-1DI',text:'CACB ECM 021-1DI'},
{value:'CACB ECM 027-1DI',text:'CACB ECM 027-1DI'},
{value:'CRCB ECOWATT 04 HM',text:'CRCB ECOWATT 04 HM'},
{value:'CRCB ECOWATT 06 HM',text:'CRCB ECOWATT 06 HM'},
{value:'CRCB ECOWATT 10 HM',text:'CRCB ECOWATT 10 HM'},
{value:'CRCB ECOWATT 22 HM',text:'CRCB ECOWATT 22 HM'},
{value:'CRCB ECOWATT 30 HM',text:'CRCB ECOWATT 30 HM'},
{value:'CRCB ECOWATT 38 HM',text:'CRCB ECOWATT 38 HM'},
{value:'CRCB ECOWATT 48 HM',text:'CRCB ECOWATT 48 HM'},
{value:'CRCB ECOWATT 70 HM',text:'CRCB ECOWATT 70 HM'},
{value:'CRCB ECOWATT 82 HM',text:'CRCB ECOWATT 82 HM'},
{value:'CRCB ECOWATT 04 HM ISO',text:'CRCB ECOWATT 04 HM ISO'},
{value:'CRCB ECOWATT 06 HM ISO',text:'CRCB ECOWATT 06 HM ISO'},
{value:'CRCB ECOWATT 10 HM ISO',text:'CRCB ECOWATT 10 HM ISO'},
{value:'CRCB ECOWATT 22 HM ISO',text:'CRCB ECOWATT 22 HM ISO'},
{value:'CRCB ECOWATT 30 HM ISO',text:'CRCB ECOWATT 30 HM ISO'},
{value:'CRCB ECOWATT 38 HM ISO',text:'CRCB ECOWATT 38 HM ISO'},
{value:'CRCB ECOWATT 48 HM ISO',text:'CRCB ECOWATT 48 HM ISO'},
{value:'CRCB ECOWATT 70 HM ISO',text:'CRCB ECOWATT 70 HM ISO'},
{value:'CRCB ECOWATT 82 HM ISO',text:'CRCB ECOWATT 82 HM ISO'},
{value:'CRCB ECOWATT 04 VM',text:'CRCB ECOWATT 04 VM'},
{value:'CRCB ECOWATT 06 VM',text:'CRCB ECOWATT 06 VM'},
{value:'CRCB ECOWATT 10 VM',text:'CRCB ECOWATT 10 VM'},
{value:'CRCB ECOWATT 22 VM',text:'CRCB ECOWATT 22 VM'},
{value:'CRCB ECOWATT 30 VM',text:'CRCB ECOWATT 30 VM'},
{value:'CRCB ECOWATT 38 VM',text:'CRCB ECOWATT 38 VM'},
{value:'CRCB ECOWATT 48 VM',text:'CRCB ECOWATT 48 VM'},
{value:'CRCB ECOWATT 70 VM',text:'CRCB ECOWATT 70 VM'},
{value:'CRCB ECOWATT 82 VM',text:'CRCB ECOWATT 82 VM'},
{value:'CRCB ECOWATT 04 VM ISO',text:'CRCB ECOWATT 04 VM ISO'},
{value:'CRCB ECOWATT 06 VM ISO',text:'CRCB ECOWATT 06 VM ISO'},
{value:'CRCB ECOWATT 10 VM ISO',text:'CRCB ECOWATT 10 VM ISO'},
{value:'CRCB ECOWATT 22 VM ISO',text:'CRCB ECOWATT 22 VM ISO'},
{value:'CRCB ECOWATT 30 VM ISO',text:'CRCB ECOWATT 30 VM ISO'},
{value:'CRCB ECOWATT 38 VM ISO',text:'CRCB ECOWATT 38 VM ISO'},
{value:'CRCB ECOWATT 48 VM ISO',text:'CRCB ECOWATT 48 VM ISO'},
{value:'CRCB ECOWATT 70 VM ISO',text:'CRCB ECOWATT 70 VM ISO'},
{value:'CRCB ECOWATT 82 VM ISO',text:'CRCB ECOWATT 82 VM ISO'},
{value:'CACB ECM ECO 07 -1DI',text:'CACB ECM ECO 07 -1DI'},
{value:'CACB ECM ECO 12 -1DI',text:'CACB ECM ECO 12 -1DI'},
{value:'CACB ECM ECO 15 -1DI',text:'CACB ECM ECO 15 -1DI'},
{value:'CACB ECM ECO 21 -1DI',text:'CACB ECM ECO 21 -1DI'},
{value:'CACB ECM ECO 27 -1DI',text:'CACB ECM ECO 27 -1DI'},
{value:'CRCB ECOWATT PM 04 HM',text:'CRCB ECOWATT PM 04 HM'},
{value:'CRCB ECOWATT PM 06 HM',text:'CRCB ECOWATT PM 06 HM'},
{value:'CRCB ECOWATT PM 10 HM',text:'CRCB ECOWATT PM 10 HM'},
{value:'CRCB ECOWATT PM 22 HM',text:'CRCB ECOWATT PM 22 HM'},
{value:'CRCB ECOWATT PM 30 HM',text:'CRCB ECOWATT PM 30 HM'},
{value:'CRCB ECOWATT PM 38 HM',text:'CRCB ECOWATT PM 38 HM'},
{value:'CRCB ECOWATT PM 48 HM',text:'CRCB ECOWATT PM 48 HM'},
{value:'CRCB ECOWATT PM 70 HM',text:'CRCB ECOWATT PM 70 HM'},
{value:'CRCB ECOWATT PM 82 HM',text:'CRCB ECOWATT PM 82 HM'},
{value:'CRCB ECOWATT PM 04 HM ISO',text:'CRCB ECOWATT PM 04 HM ISO'},
{value:'CRCB ECOWATT PM 06 HM ISO',text:'CRCB ECOWATT PM 06 HM ISO'},
{value:'CRCB ECOWATT PM 10 HM ISO',text:'CRCB ECOWATT PM 10 HM ISO'},
{value:'CRCB ECOWATT PM 22 HM ISO',text:'CRCB ECOWATT PM 22 HM ISO'},
{value:'CRCB ECOWATT PM 30 HM ISO',text:'CRCB ECOWATT PM 30 HM ISO'},
{value:'CRCB ECOWATT PM 38 HM ISO',text:'CRCB ECOWATT PM 38 HM ISO'},
{value:'CRCB ECOWATT PM 48 HM ISO',text:'CRCB ECOWATT PM 48 HM ISO'},
{value:'CRCB ECOWATT PM 70 HM ISO',text:'CRCB ECOWATT PM 70 HM ISO'},
{value:'CRCB ECOWATT PM 82 HM ISO',text:'CRCB ECOWATT PM 82 HM ISO'},
{value:'CRCB ECOWATT PM 04 VM',text:'CRCB ECOWATT PM 04 VM'},
{value:'CRCB ECOWATT PM 06 VM',text:'CRCB ECOWATT PM 06 VM'},
{value:'CRCB ECOWATT PM 10 VM',text:'CRCB ECOWATT PM 10 VM'},
{value:'CRCB ECOWATT PM 22 VM',text:'CRCB ECOWATT PM 22 VM'},
{value:'CRCB ECOWATT PM 30 VM',text:'CRCB ECOWATT PM 30 VM'},
{value:'CRCB ECOWATT PM 38 VM',text:'CRCB ECOWATT PM 38 VM'},
{value:'CRCB ECOWATT PM 48 VM',text:'CRCB ECOWATT PM 48 VM'},
{value:'CRCB ECOWATT PM 70 VM',text:'CRCB ECOWATT PM 70 VM'},
{value:'CRCB ECOWATT PM 82 VM',text:'CRCB ECOWATT PM 82 VM'},
{value:'CRCB ECOWATT PM 04 VM ISO',text:'CRCB ECOWATT PM 04 VM ISO'},
{value:'CRCB ECOWATT PM 06 VM ISO',text:'CRCB ECOWATT PM 06 VM ISO'},
{value:'CRCB ECOWATT PM 10 VM ISO',text:'CRCB ECOWATT PM 10 VM ISO'},
{value:'CRCB ECOWATT PM 22 VM ISO',text:'CRCB ECOWATT PM 22 VM ISO'},
{value:'CRCB ECOWATT PM 30 VM ISO',text:'CRCB ECOWATT PM 30 VM ISO'},
{value:'CRCB ECOWATT PM 38 VM ISO',text:'CRCB ECOWATT PM 38 VM ISO'},
{value:'CRCB ECOWATT PM 48 VM ISO',text:'CRCB ECOWATT PM 48 VM ISO'},
{value:'CRCB ECOWATT PM 70 VM ISO',text:'CRCB ECOWATT PM 70 VM ISO'},
{value:'CRCB ECOWATT PM 82 VM ISO',text:'CRCB ECOWATT PM 82 VM ISO'},
{value:'CRCB ECOWATT 04 HL',text:'CRCB ECOWATT 04 HL'},
{value:'CRCB ECOWATT 06 HL',text:'CRCB ECOWATT 06 HL'},
{value:'CRCB ECOWATT 10 HL',text:'CRCB ECOWATT 10 HL'},
{value:'CRCB ECOWATT 22 HL',text:'CRCB ECOWATT 22 HL'},
{value:'CRCB ECOWATT 30 HL',text:'CRCB ECOWATT 30 HL'},
{value:'CRCB ECOWATT 38 HL',text:'CRCB ECOWATT 38 HL'},
{value:'CRCB ECOWATT 48 HL',text:'CRCB ECOWATT 48 HL'},
{value:'CRCB ECOWATT 70 HL',text:'CRCB ECOWATT 70 HL'},
{value:'CRCB ECOWATT 82 HL',text:'CRCB ECOWATT 82 HL'},
{value:'CRCB ECOWATT 04 HL ISO',text:'CRCB ECOWATT 04 HL ISO'},
{value:'CRCB ECOWATT 06 HL ISO',text:'CRCB ECOWATT 06 HL ISO'},
{value:'CRCB ECOWATT 10 HL ISO',text:'CRCB ECOWATT 10 HL ISO'},
{value:'CRCB ECOWATT 22 HL ISO',text:'CRCB ECOWATT 22 HL ISO'},
{value:'CRCB ECOWATT 30 HL ISO',text:'CRCB ECOWATT 30 HL ISO'},
{value:'CRCB ECOWATT 38 HL ISO',text:'CRCB ECOWATT 38 HL ISO'},
{value:'CRCB ECOWATT 48 HL ISO',text:'CRCB ECOWATT 48 HL ISO'},
{value:'CRCB ECOWATT 70 HL ISO',text:'CRCB ECOWATT 70 HL ISO'},
{value:'CRCB ECOWATT 82 HL ISO',text:'CRCB ECOWATT 82 HL ISO'},
{value:'CRCB ECOWATT 04 VL',text:'CRCB ECOWATT 04 VL'},
{value:'CRCB ECOWATT 06 VL',text:'CRCB ECOWATT 06 VL'},
{value:'CRCB ECOWATT 10 VL',text:'CRCB ECOWATT 10 VL'},
{value:'CRCB ECOWATT 22 VL',text:'CRCB ECOWATT 22 VL'},
{value:'CRCB ECOWATT 30 VL',text:'CRCB ECOWATT 30 VL'},
{value:'CRCB ECOWATT 38 VL',text:'CRCB ECOWATT 38 VL'},
{value:'CRCB ECOWATT 48 VL',text:'CRCB ECOWATT 48 VL'},
{value:'CRCB ECOWATT 70 VL',text:'CRCB ECOWATT 70 VL'},
{value:'CRCB ECOWATT 82 VL',text:'CRCB ECOWATT 82 VL'},
{value:'CRCB ECOWATT 04 VL ISO',text:'CRCB ECOWATT 04 VL ISO'},
{value:'CRCB ECOWATT 06 VL ISO',text:'CRCB ECOWATT 06 VL ISO'},
{value:'CRCB ECOWATT 10 VL ISO',text:'CRCB ECOWATT 10 VL ISO'},
{value:'CRCB ECOWATT 22 VL ISO',text:'CRCB ECOWATT 22 VL ISO'},
{value:'CRCB ECOWATT 30 VL ISO',text:'CRCB ECOWATT 30 VL ISO'},
{value:'CRCB ECOWATT 38 VL ISO',text:'CRCB ECOWATT 38 VL ISO'},
{value:'CRCB ECOWATT 48 VL ISO',text:'CRCB ECOWATT 48 VL ISO'},
{value:'CRCB ECOWATT 70 VL ISO',text:'CRCB ECOWATT 70 VL ISO'},
{value:'CRCB ECOWATT 82 VL ISO',text:'CRCB ECOWATT 82 VL ISO'},
{value:'CRCB ECOWATT PM 04 HL',text:'CRCB ECOWATT PM 04 HL'},
{value:'CRCB ECOWATT PM 06 HL',text:'CRCB ECOWATT PM 06 HL'},
{value:'CRCB ECOWATT PM 10 HL',text:'CRCB ECOWATT PM 10 HL'},
{value:'CRCB ECOWATT PM 22 HL',text:'CRCB ECOWATT PM 22 HL'},
{value:'CRCB ECOWATT PM 30 HL',text:'CRCB ECOWATT PM 30 HL'},
{value:'CRCB ECOWATT PM 38 HL',text:'CRCB ECOWATT PM 38 HL'},
{value:'CRCB ECOWATT PM 48 HL',text:'CRCB ECOWATT PM 48 HL'},
{value:'CRCB ECOWATT PM 70 HL',text:'CRCB ECOWATT PM 70 HL'},
{value:'CRCB ECOWATT PM 82 HL',text:'CRCB ECOWATT PM 82 HL'},
{value:'CRCB ECOWATT PM 04 HL ISO',text:'CRCB ECOWATT PM 04 HL ISO'},
{value:'CRCB ECOWATT PM 06 HL ISO',text:'CRCB ECOWATT PM 06 HL ISO'},
{value:'CRCB ECOWATT PM 10 HL ISO',text:'CRCB ECOWATT PM 10 HL ISO'},
{value:'CRCB ECOWATT PM 22 HL ISO',text:'CRCB ECOWATT PM 22 HL ISO'},
{value:'CRCB ECOWATT PM 30 HL ISO',text:'CRCB ECOWATT PM 30 HL ISO'},
{value:'CRCB ECOWATT PM 38 HL  ISO',text:'CRCB ECOWATT PM 38 HL  ISO'},
{value:'CRCB ECOWATT PM 48 HL  ISO',text:'CRCB ECOWATT PM 48 HL  ISO'},
{value:'CRCB ECOWATT PM 70 HL  ISO',text:'CRCB ECOWATT PM 70 HL  ISO'},
{value:'CRCB ECOWATT PM 82 HL  ISO',text:'CRCB ECOWATT PM 82 HL  ISO'},
{value:'CRCB ECOWATT PM 04 VL',text:'CRCB ECOWATT PM 04 VL'},
{value:'CRCB ECOWATT PM 06 VL',text:'CRCB ECOWATT PM 06 VL'},
{value:'CRCB ECOWATT PM 10 VL',text:'CRCB ECOWATT PM 10 VL'},
{value:'CRCB ECOWATT PM 22 VL',text:'CRCB ECOWATT PM 22 VL'},
{value:'CRCB ECOWATT PM 30 VL',text:'CRCB ECOWATT PM 30 VL'},
{value:'CRCB ECOWATT PM 38 VL',text:'CRCB ECOWATT PM 38 VL'},
{value:'CRCB ECOWATT PM 48 VL',text:'CRCB ECOWATT PM 48 VL'},
{value:'CRCB ECOWATT PM 70 VL',text:'CRCB ECOWATT PM 70 VL'},
{value:'CRCB ECOWATT PM 82 VL',text:'CRCB ECOWATT PM 82 VL'},
{value:'CRCB ECOWATT PM 04 VL ISO',text:'CRCB ECOWATT PM 04 VL ISO'},
{value:'CRCB ECOWATT PM 06 VL ISO',text:'CRCB ECOWATT PM 06 VL ISO'},
{value:'CRCB ECOWATT PM 10 VL ISO',text:'CRCB ECOWATT PM 10 VL ISO'},
{value:'CRCB ECOWATT PM 22 VL ISO',text:'CRCB ECOWATT PM 22 VL ISO'},
{value:'CRCB ECOWATT PM 30 VL ISO',text:'CRCB ECOWATT PM 30 VL ISO'},
{value:'CRCB ECOWATT PM 38 VL  ISO',text:'CRCB ECOWATT PM 38 VL  ISO'},
{value:'CRCB ECOWATT PM 48 VL  ISO',text:'CRCB ECOWATT PM 48 VL  ISO'},
{value:'CRCB ECOWATT PM 70 VL  ISO',text:'CRCB ECOWATT PM 70 VL  ISO'},
{value:'CRCB ECOWATT PM 82 VL  ISO',text:'CRCB ECOWATT PM 82 VL  ISO'},
{value:'CACB ECM 007-2 DI',text:'CACB ECM 007-2 DI'},
{value:'CACB ECM 012-2 DI',text:'CACB ECM 012-2 DI'},
{value:'CACB ECM 021-2 DI',text:'CACB ECM 021-2 DI'},
{value:'CACB ECM 027-2 DI',text:'CACB ECM 027-2 DI'},
{value:'CRCB ECOWATT 10 HD',text:'CRCB ECOWATT 10 HD'},
{value:'CRCB ECOWATT  22 HD',text:'CRCB ECOWATT  22 HD'},
{value:'CRCB ECOWATT  38 HD',text:'CRCB ECOWATT  38 HD'},
{value:'CRCB ECOWATT  48 HD',text:'CRCB ECOWATT  48 HD'},
{value:'CRCB ECOWATT  82 HD',text:'CRCB ECOWATT  82 HD'},
{value:'CRCB ECOWATT 10 HD ISO',text:'CRCB ECOWATT 10 HD ISO'},
{value:'CRCB ECOWATT  22 HD ISO',text:'CRCB ECOWATT  22 HD ISO'},
{value:'CRCB ECOWATT  38 HD ISO',text:'CRCB ECOWATT  38 HD ISO'},
{value:'CRCB ECOWATT  48 HD ISO',text:'CRCB ECOWATT  48 HD ISO'},
{value:'CRCB ECOWATT  82 HD ISO',text:'CRCB ECOWATT  82 HD ISO'},
{value:'CRCB ECOWATT 10 VD',text:'CRCB ECOWATT 10 VD'},
{value:'CRCB ECOWATT  22 VD',text:'CRCB ECOWATT  22 VD'},
{value:'CRCB ECOWATT  38 VD',text:'CRCB ECOWATT  38 VD'},
{value:'CRCB ECOWATT  48 VD',text:'CRCB ECOWATT  48 VD'},
{value:'CRCB ECOWATT  82 VD',text:'CRCB ECOWATT  82 VD'},
{value:'CRCB ECOWATT 10 VD ISO',text:'CRCB ECOWATT 10 VD ISO'},
{value:'CRCB ECOWATT  22 VD ISO',text:'CRCB ECOWATT  22 VD ISO'},
{value:'CRCB ECOWATT  38 VD ISO',text:'CRCB ECOWATT  38 VD ISO'},
{value:'CRCB ECOWATT  48 VD ISO',text:'CRCB ECOWATT  48 VD ISO'},
{value:'CRCB ECOWATT  82 VD ISO',text:'CRCB ECOWATT  82 VD ISO'},
{value:'CACB ECM ECO 07 -2DI',text:'CACB ECM ECO 07 -2DI'},
{value:'CACB ECM ECO 12 -2DI',text:'CACB ECM ECO 12 -2DI'},
{value:'CACB ECM ECO 21 -2DI',text:'CACB ECM ECO 21 -2DI'},
{value:'CACB ECM ECO 27 -2DI',text:'CACB ECM ECO 27 -2DI'},
{value:'CRCB ECOWATT PM 10 HD',text:'CRCB ECOWATT PM 10 HD'},
{value:'CRCB ECOWATT PM 22 HD',text:'CRCB ECOWATT PM 22 HD'},
{value:'CRCB ECOWATT PM 38 HD',text:'CRCB ECOWATT PM 38 HD'},
{value:'CRCB ECOWATT PM 48 HD',text:'CRCB ECOWATT PM 48 HD'},
{value:'CRCB ECOWATT PM 82 HD',text:'CRCB ECOWATT PM 82 HD'},
{value:'CRCB ECOWATT PM 10 HD ISO',text:'CRCB ECOWATT PM 10 HD ISO'},
{value:'CRCB ECOWATT PM 22 HD ISO',text:'CRCB ECOWATT PM 22 HD ISO'},
{value:'CRCB ECOWATT PM 38 HD ISO',text:'CRCB ECOWATT PM 38 HD ISO'},
{value:'CRCB ECOWATT PM 48 HD ISO',text:'CRCB ECOWATT PM 48 HD ISO'},
{value:'CRCB ECOWATT PM 82 HD ISO',text:'CRCB ECOWATT PM 82 HD ISO'},
{value:'CRCB ECOWATT PM 10 VD',text:'CRCB ECOWATT PM 10 VD'},
{value:'CRCB ECOWATT PM 22 VD',text:'CRCB ECOWATT PM 22 VD'},
{value:'CRCB ECOWATT PM 38 VD',text:'CRCB ECOWATT PM 38 VD'},
{value:'CRCB ECOWATT PM 48 VD',text:'CRCB ECOWATT PM 48 VD'},
{value:'CRCB ECOWATT PM 82 VD',text:'CRCB ECOWATT PM 82 VD'},
{value:'CRCB ECOWATT PM 10 VD ISO',text:'CRCB ECOWATT PM 10 VD ISO'},
{value:'CRCB ECOWATT PM 22 VD ISO',text:'CRCB ECOWATT PM 22 VD ISO'},
{value:'CRCB ECOWATT PM 38 VD ISO',text:'CRCB ECOWATT PM 38 VD ISO'},
{value:'CRCB ECOWATT PM 48 VD ISO',text:'CRCB ECOWATT PM 48 VD ISO'},
{value:'CRCB ECOWATT PM 82 VD ISO',text:'CRCB ECOWATT PM 82 VD ISO'},
{value:'CRCB ECOWATT 30 HM TRI',text:'CRCB ECOWATT 30 HM TRI'},
{value:'CRCB ECOWATT 38 HM TRI',text:'CRCB ECOWATT 38 HM TRI'},
{value:'CRCB ECOWATT 48 HM  TRI',text:'CRCB ECOWATT 48 HM  TRI'},
{value:'CRCB ECOWATT 70 HM TRI',text:'CRCB ECOWATT 70 HM TRI'},
{value:'CRCB ECOWATT 92 HM TRI',text:'CRCB ECOWATT 92 HM TRI'},
{value:'CRCB ECOWATT 30 HM ISO TRI',text:'CRCB ECOWATT 30 HM ISO TRI'},
{value:'CRCB ECOWATT 38 HM ISO TRI',text:'CRCB ECOWATT 38 HM ISO TRI'},
{value:'CRCB ECOWATT 48 HM ISO TRI',text:'CRCB ECOWATT 48 HM ISO TRI'},
{value:'CRCB ECOWATT 70 HM ISO TRI',text:'CRCB ECOWATT 70 HM ISO TRI'},
{value:'CRCB ECOWATT 92 HM ISO TRI',text:'CRCB ECOWATT 92 HM ISO TRI'},
{value:'CRCB ECOWATT 30 VM TRI',text:'CRCB ECOWATT 30 VM TRI'},
{value:'CRCB ECOWATT 38 VM TRI',text:'CRCB ECOWATT 38 VM TRI'},
{value:'CRCB ECOWATT 48 VM  TRI',text:'CRCB ECOWATT 48 VM  TRI'},
{value:'CRCB ECOWATT 70 VM TRI',text:'CRCB ECOWATT 70 VM TRI'},
{value:'CRCB ECOWATT 92 VM TRI',text:'CRCB ECOWATT 92 VM TRI'},
{value:'CRCB ECOWATT 30 VM ISO TRI',text:'CRCB ECOWATT 30 VM ISO TRI'},
{value:'CRCB ECOWATT 38 VM ISO TRI',text:'CRCB ECOWATT 38 VM ISO TRI'},
{value:'CRCB ECOWATT 48 VM ISO TRI',text:'CRCB ECOWATT 48 VM ISO TRI'},
{value:'CRCB ECOWATT 70 VM ISO TRI',text:'CRCB ECOWATT 70 VM ISO TRI'},
{value:'CRCB ECOWATT 92 VM ISO TRI',text:'CRCB ECOWATT 92 VM ISO TRI'},
{value:'CRCB ECOWATT PM 30 HM TRI',text:'CRCB ECOWATT PM 30 HM TRI'},
{value:'CRCB ECOWATT PM 38 HM TRI',text:'CRCB ECOWATT PM 38 HM TRI'},
{value:'CRCB ECOWATT PM 48 HM TRI',text:'CRCB ECOWATT PM 48 HM TRI'},
{value:'CRCB ECOWATT PM 70 HM TRI',text:'CRCB ECOWATT PM 70 HM TRI'},
{value:'CRCB ECOWATT PM 92 HM TRI',text:'CRCB ECOWATT PM 92 HM TRI'},
{value:'CRCB ECOWATT PM 30 HM TRI ISO',text:'CRCB ECOWATT PM 30 HM TRI ISO'},
{value:'CRCB ECOWATT PM 38 HM TRI ISO',text:'CRCB ECOWATT PM 38 HM TRI ISO'},
{value:'CRCB ECOWATT PM 48 HM TRI ISO',text:'CRCB ECOWATT PM 48 HM TRI ISO'},
{value:'CRCB ECOWATT PM 70 HM TRI ISO',text:'CRCB ECOWATT PM 70 HM TRI ISO'},
{value:'CRCB ECOWATT PM 92 HM TRI ISO',text:'CRCB ECOWATT PM 92 HM TRI ISO'},
{value:'CRCB ECOWATT PM 30 VM TRI',text:'CRCB ECOWATT PM 30 VM TRI'},
{value:'CRCB ECOWATT PM 38 VM TRI',text:'CRCB ECOWATT PM 38 VM TRI'},
{value:'CRCB ECOWATT PM 48 VM TRI',text:'CRCB ECOWATT PM 48 VM TRI'},
{value:'CRCB ECOWATT PM 70 VM TRI',text:'CRCB ECOWATT PM 70 VM TRI'},
{value:'CRCB ECOWATT PM 92 VM TRI',text:'CRCB ECOWATT PM 92 VM TRI'},
{value:'CRCB ECOWATT PM ISO 30 VM TRI',text:'CRCB ECOWATT PM ISO 30 VM TRI'},
{value:'CRCB ECOWATT PM ISO 38 VM TRI',text:'CRCB ECOWATT PM ISO 38 VM TRI'},
{value:'CRCB ECOWATT PM ISO 48 VM TRI',text:'CRCB ECOWATT PM ISO 48 VM TRI'},
{value:'CRCB ECOWATT PM ISO 70 VM TRI',text:'CRCB ECOWATT PM ISO 70 VM TRI'},
{value:'CRCB ECOWATT PM ISO 92 VM TRI',text:'CRCB ECOWATT PM ISO 92 VM TRI'},
{value:'CRCB ECOWATT 30 HL TRI',text:'CRCB ECOWATT 30 HL TRI'},
{value:'CRCB ECOWATT 38 HL TRI',text:'CRCB ECOWATT 38 HL TRI'},
{value:'CRCB ECOWATT 48 HL  TRI',text:'CRCB ECOWATT 48 HL  TRI'},
{value:'CRCB ECOWATT 70 HL TRI',text:'CRCB ECOWATT 70 HL TRI'},
{value:'CRCB ECOWATT 92 HL TRI',text:'CRCB ECOWATT 92 HL TRI'},
{value:'CRCB ECOWATT 30 HL ISO TRI',text:'CRCB ECOWATT 30 HL ISO TRI'},
{value:'CRCB ECOWATT 38 HL ISO TRI',text:'CRCB ECOWATT 38 HL ISO TRI'},
{value:'CRCB ECOWATT 48 HL ISO TRI',text:'CRCB ECOWATT 48 HL ISO TRI'},
{value:'CRCB ECOWATT 70 HL ISO TRI',text:'CRCB ECOWATT 70 HL ISO TRI'},
{value:'CRCB ECOWATT 92 HL ISO TRI',text:'CRCB ECOWATT 92 HL ISO TRI'},
{value:'CRCB ECOWATT 30 VL  TRI',text:'CRCB ECOWATT 30 VL  TRI'},
{value:'CRCB ECOWATT 38 VL  TRI',text:'CRCB ECOWATT 38 VL  TRI'},
{value:'CRCB ECOWATT 48 VL  TRI',text:'CRCB ECOWATT 48 VL  TRI'},
{value:'CRCB ECOWATT 70 VL  TRI',text:'CRCB ECOWATT 70 VL  TRI'},
{value:'CRCB ECOWATT 92 VL  TRI',text:'CRCB ECOWATT 92 VL  TRI'},
{value:'CRCB ECOWATT 30 VL ISO TRI',text:'CRCB ECOWATT 30 VL ISO TRI'},
{value:'CRCB ECOWATT 38 VL ISO TRI',text:'CRCB ECOWATT 38 VL ISO TRI'},
{value:'CRCB ECOWATT 48 VL ISO TRI',text:'CRCB ECOWATT 48 VL ISO TRI'},
{value:'CRCB ECOWATT 70 VL ISO TRI',text:'CRCB ECOWATT 70 VL ISO TRI'},
{value:'CRCB ECOWATT 92 VL ISO TRI',text:'CRCB ECOWATT 92 VL ISO TRI'},
{value:'CRCB ECOWATT PM 30 HL TRI',text:'CRCB ECOWATT PM 30 HL TRI'},
{value:'CRCB ECOWATT PM 38 HL TRI',text:'CRCB ECOWATT PM 38 HL TRI'},
{value:'CRCB ECOWATT PM 48 HL TRI',text:'CRCB ECOWATT PM 48 HL TRI'},
{value:'CRCB ECOWATT PM 70 HL TRI',text:'CRCB ECOWATT PM 70 HL TRI'},
{value:'CRCB ECOWATT PM 92 HL TRI',text:'CRCB ECOWATT PM 92 HL TRI'},
{value:'CACT N 045 ECO L',text:'CACT N 045 ECO L'},
{value:'CACT N 080 ECO L',text:'CACT N 080 ECO L'},
{value:'CACT N 110 ECO L',text:'CACT N 110 ECO L'},
{value:'CRCB ECOWATT 38 HD TRI',text:'CRCB ECOWATT 38 HD TRI'},
{value:'CRCB ECOWATT 48 HD  TRI',text:'CRCB ECOWATT 48 HD  TRI'},
{value:'CRCB ECOWATT 92 HD TRI',text:'CRCB ECOWATT 92 HD TRI'},
{value:'CRCB ECOWATT 38 HD TRI ISO',text:'CRCB ECOWATT 38 HD TRI ISO'},
{value:'CRCB ECOWATT 48 HD  TRI ISO',text:'CRCB ECOWATT 48 HD  TRI ISO'},
{value:'CRCB ECOWATT 92 HD TRI ISO',text:'CRCB ECOWATT 92 HD TRI ISO'},
{value:'CRCB ECOWATT 38 VD TRI',text:'CRCB ECOWATT 38 VD TRI'},
{value:'CRCB ECOWATT 48 VD  TRI',text:'CRCB ECOWATT 48 VD  TRI'},
{value:'CRCB ECOWATT 92 VD TRI',text:'CRCB ECOWATT 92 VD TRI'},
{value:'CRCB ECOWATT 38 VD TRI ISO',text:'CRCB ECOWATT 38 VD TRI ISO'},
{value:'CRCB ECOWATT 48 VD  TRI ISO',text:'CRCB ECOWATT 48 VD  TRI ISO'},
{value:'CRCB ECOWATT 92 VD TRI ISO',text:'CRCB ECOWATT 92 VD TRI ISO'},
{value:'CRCB ECOWATT PM 38 HD TRI',text:'CRCB ECOWATT PM 38 HD TRI'},
{value:'CRCB ECOWATT PM 48 HD  TRI',text:'CRCB ECOWATT PM 48 HD  TRI'},
{value:'CRCB ECOWATT PM 92 HD TRI',text:'CRCB ECOWATT PM 92 HD TRI'},
{value:'CRCB ECOWATT PM 38 HD TRI ISO',text:'CRCB ECOWATT PM 38 HD TRI ISO'},
{value:'CRCB ECOWATT PM 48 HD  TRI ISO',text:'CRCB ECOWATT PM 48 HD  TRI ISO'},
{value:'CRCB ECOWATT PM 92 HD TRI ISO',text:'CRCB ECOWATT PM 92 HD TRI ISO'},
{value:'CACT N 045 ECO-2',text:'CACT N 045 ECO-2'},
{value:'CACT N 080 ECO-2',text:'CACT N 080 ECO-2'},
{value:'CACT N 110 ECO -2',text:'CACT N 110 ECO -2'}],
      tension:[{value:'M',text:'MonoPhase'},{value:'T',text:'Triphase'}],
      aspiration:[{value:'9',text:'90°'},{value:'L',text:'Ligne'},{value:'D',text:'Double'}],
      debit:['350','500','800','1000','1400','2000','2700','2900','3700','5000','6900','8000','9000'],
      }
  },
  methods: {
    onFiltered(filteredItems){
      console.log("filter");
      console.log(this.collectif.vmc.length);
      console.log(filteredItems.length);
      this.total_rows_vmc = filteredItems.length;
      this.currentPage = 1;
    },
    countDownChanged(dismisscd){
      this.dismissCountDown = dismisscd;
    },
    updateConfig(){
      switch(this.currentOptions.content.type){
        case 'checkbox':
          this.$delete(this.currentOptions.content,'options');
        break;
        default:
          this.$delete(this.currentOptions.content,'items');
          this.$delete(this.currentOptions,'type');
        break;
      }
    },
    updateParams(type){
      switch(type){
        case 'vmc':
          switch(this.currentParams.type){
            case 'entree':
              this.$set(this.currentParams,'rallonge',{});
              this.$set(this.currentParams,'entree',{});
              this.$delete(this.currentParams,'sdb');
              this.$delete(this.currentParams,'sdb_wc');
              this.$delete(this.currentParams,'wc');
              this.$delete(this.currentParams,'eau');
              this.$delete(this.currentParams,'offered');
            break;
            case 'piece_technique':
              this.$delete(this.currentParams,'rallonge');
              this.$delete(this.currentParams,'entree');
              this.$set(this.currentParams,'sdb',{items:[],offered:0});
              this.$set(this.currentParams,'sdb_wc',{items:[],offered:0});
              this.$set(this.currentParams,'wc',{items:[],offered:0});
              this.$set(this.currentParams,'eau',{items:[],offered:0});
              this.$set(this.currentParams,'offered',0);
            break;
            default:
              this.$delete(this.currentParams,'sdb');
              this.$delete(this.currentParams,'sdb_wc');
              this.$delete(this.currentParams,'wc');
              this.$delete(this.currentParams,'eau');
              this.$delete(this.currentParams,'offered');
              this.$delete(this.currentParams,'rallonge');
              this.$delete(this.currentParams,'entree');
            break;
          }
        break;
        case 'calcul':
          switch(this.currentParams.type){
            case 'entree':
              this.$set(this.currentCalculParams,'rallonge',{});
              this.$set(this.currentCalculParams,'entree',{});
              this.$delete(this.currentCalculParams,'sdb');
              this.$delete(this.currentCalculParams,'sdb_wc');
              this.$delete(this.currentCalculParams,'wc');
              this.$delete(this.currentCalculParams,'eau');
              this.$delete(this.currentCalculParams,'offered');
            break;
            case 'piece_technique':
              this.$delete(this.currentCalculParams,'rallonge');
              this.$delete(this.currentCalculParams,'entree');
              this.$set(this.currentCalculParams,'sdb',{items:[],offered:0});
              this.$set(this.currentCalculParams,'sdb_wc',{items:[],offered:0});
              this.$set(this.currentCalculParams,'wc',{items:[],offered:0});
              this.$set(this.currentCalculParams,'eau',{items:[],offered:0});
              this.$set(this.currentCalculParams,'offered',0);
            break;
            default:
              this.$delete(this.currentCalculParams,'sdb');
              this.$delete(this.currentCalculParams,'sdb_wc');
              this.$delete(this.currentCalculParams,'wc');
              this.$delete(this.currentCalculParams,'eau');
              this.$delete(this.currentCalculParams,'offered');
              this.$delete(this.currentCalculParams,'rallonge');
              this.$delete(this.currentCalculParams,'entree');
            break;
          }
        break;
      }
    },
    add_item_options(){
      if(this.currentOptions.content.type == 'checkbox'){
        if(!this.currentOptions.content.items) this.$set(this.currentOptions.content,'items',[]);
        this.currentOptions.content.items.push({lidic:'',number:'1'});
      }else{
        if(!this.currentOptions.content.options) this.$set(this.currentOptions.content,'options',[]);
        this.currentOptions.content.options.push({text:'',value:''});
      }
    },
    remove_item_options(index){
      if(this.currentOptions.content.type == 'checkbox'){
        this.currentOptions.content.items.splice(index,1);
      }else{
        this.currentOptions.content.options.splice(index,1);
      }
    },
    remove_item_config(propertyName){
      delete this.currentConfig[propertyName];
      this.$forceUpdate();
    },
    add_item_config(){
      if(this.currentConfigLabel=='barre_vertical'){
        if(this.newConfigParam!=""){
          this.$set(this.currentConfig,this.newConfigParam,[0,0]);
          this.newConfigParam = '';
        }
      }else{
        if(this.newConfigParam!=""){
          this.$set(this.currentConfig,this.newConfigParam,0);
          this.newConfigParam = '';
        }
      }
    },
    add_item_commande(bouche,commande){
      this.currentCalculParams[commande][bouche].push({lidic:'',number:1});
    },
    add_item_entree(rallonge,bouche){
      if(rallonge){
        if(!this.currentCalculParams['rallonge'][bouche]) this.$set(this.currentCalculParams['rallonge'],bouche,[]);
        this.currentCalculParams['rallonge'][bouche].push({lidic:'',number:1});
      }else{
        if(!this.currentCalculParams['entree'][bouche]) this.$set(this.currentCalculParams['entree'],bouche,[]);
        this.currentCalculParams['entree'][bouche].push({lidic:'',number:1});
      }

    },
    remove_item_commande(bouche,index2,commande){
      this.currentCalculParams[commande][bouche].splice(index2,1);
    },
    remove_item_entree(rallonge,bouche,index2){
      if(rallonge){
        this.currentCalculParams['rallonge'][bouche].splice(index2,1);
      }else{
        this.currentCalculParams['entree'][bouche].splice(index2,1);
      }
    },
    remove_item_gaine(nb_etage,index){
      this.currentGaine.items[nb_etage].splice(index,1);
    },
    add_item_gaine(nb_etage){
      if(!this.currentGaine.items[nb_etage]) this.$set(this.currentGaine.items,nb_etage,[]);
      this.currentGaine.items[nb_etage].push({lidic:'',number:1});
    },
    add_item_bouche(bouche){
      if(!this.currentParams[bouche]) this.$set(this.currentParams,bouche,[]);
      this.currentParams[bouche].push({lidic:'',number:1});
    },
    remove_item_bouche(bouche,index2){
      this.currentParams[bouche].splice(index2,1);
    },
    add_item_pt(pt,commande,mainDefine,isMain,index){
      if(commande!=''){
        if(mainDefine){
          if(isMain){
            if(!this.currentCalculParams[pt][commande]['main'][index]) this.$set(this.currentCalculParams[pt][commande]['main'][index],[]);
            this.currentCalculParams[pt][commande]['main'][index].push({lidic:''});
          }else{
            if(!this.currentCalculParams[pt][commande]['supp'][index]) this.$set(this.currentCalculParams[pt][commande]['supp'][index],[]);
            this.currentCalculParams[pt][commande]['supp'][index].push({lidic:''});
          }
        }else{
          if(!this.currentCalculParams[pt][commande][index]) this.$set(this.currentCalculParams[pt][commande][index],[]);
          this.currentCalculParams[pt][commande][index].push({lidic:''});
        }
      }else{
        if(mainDefine){
          if(isMain){
            if(!this.currentCalculParams[pt]['main'][index]) this.$set(this.currentCalculParams[pt]['main'][index],[]);
            this.currentCalculParams[pt]['main'][index].push({lidic:''});
          }else{
            if(!this.currentCalculParams[pt]['supp'][index]) this.$set(this.currentCalculParams[pt]['supp'][index],[]);
            this.currentCalculParams[pt]['supp'][index].push({lidic:''});
          }
        }else{
          if(!this.currentCalculParams[pt][index]) this.$set(this.currentCalculParams[pt][index],[]);
          this.currentCalculParams[pt][index].push({lidic:''});
        }
      }

    },
    remove_item_pt(pt,commande,mainDefine,isMain,index,index2){
      if(commande!=''){
        if(mainDefine){
          if(isMain){
            this.currentCalculParams[pt][commande]['main'][index].splice(index2,1);
          }else{
            this.currentCalculParams[pt][commande]['supp'][index].splice(index2,1);
          }
        }else{
          this.currentCalculParams[pt][commande][index].splice(index2,1);
        }
      }else{
        if(mainDefine){
          if(isMain){
            this.currentCalculParams[pt]['main'][index].splice(index2,1);
          }else{
            this.currentCalculParams[pt]['supp'][index].splice(index2,1);
          }
        }else{
          this.currentCalculParams[pt][index].splice(index2,1);
        }
      }

    },
    is_option_checkbox(){
      return (this.currentOptions && this.currentOptions.content && this.currentOptions.content.type=="checkbox");
    },
    is_option_checkbox_visible(){
      console.log(this.currentOptions.content.visibility);
      return (this.currentOptions && this.currentOptions.content && this.currentOptions.content.visibility);
    },
    is_paramType(type,isCalcul){
      return isCalcul ? this.currentCalculParams.type==type : this.currentParams.type==type;
    },
    isBarreVertical(){
      return this.currentConfigLabel=="barre_vertical";
    },
    close(type){
      switch(type){
        case 'options':
          this.lidic_options = [];
          this.editOptions=false;
          console.log(this.currentOptions);
          console.log(this.currentVMC);
        break;
        case 'calcul_params':
          this.editCalculParams = false;
          console.log(this.currentCalculParams);
          console.log(this.currentCalcul);
        break;
        case 'params':
          this.lidic_params = [];
          this.editParams=false;
          console.log(this.currentParams);
          console.log(this.currentVMC);
        break;
        case 'gaines':
          this.lidic_params = [];
          this.editGaines = false;
          if(this.currentGaineLidic!=this.tmpGaineLidic && this.currentGaineLidic!=-1){
            this.$delete(this.currentParams, this.currentGaineLidic);
          }
          this.$set(this.currentParams,this.tmpGaineLidic,this.currentGaine);
          console.log(this.currentParams);
          console.log(this.currentVMC);
        break;
        case 'config':
          this.editConfig=false;
          if(this.currentConfigLabel!=this.tmpConfigLabel && this.currentConfigLabel!='Nouveau Label'){
            this.$delete(this.currentVMC.params.config, this.currentConfigLabel);
          }
          this.$set(this.currentVMC.params.config,this.tmpConfigLabel,this.currentConfig);
          console.log(this.currentConfig);
          console.log(this.currentVMC);
        break;
      }
    },
    copyItem(item,type){
      switch(type){
        case 'individuel':
          this.currentVMC = JSON.parse(JSON.stringify(item));
          this.currentVMC.lidic=null;
          this.currentVMC.status= "waiting",
          this.currentVMCLidic = -1;
          this.editVMC = true;
        break;
      }
    },
    editItem(item,type){
      switch(type){
        case 'calcul_params':
          if(item==-1){
            this.currentCalcul.params.items.push({type:''});
            this.currentCalculParams = this.currentCalcul.params.items[this.currentCalcul.params.items.length - 1];
          }else{
            this.currentCalculParams = item;
          }
          this.editCalculParams = true;
        break;
        case 'calcul':
          if(item==-1){
            console.log("new");
          }else{
            this.currentCalcul = item;
          }
          this.editCalcul = true;
        break;
        case 'individuel':
          if(item==-1){
            this.currentVMC = {
              categorie: "",
              lidic: null,
              options: [
                {
                  "content": {
                    "number": 1,
                    "type": "checkbox",
                    "items": [
                      {
                        "lidic": "990001",
                        "number": "1"
                      }
                    ]
                  },
                  "libelle": "Plots Antivibratoire"
                }
              ],
              params: {
                items:[
                {
                  "type": "barre_horizontal",
                  "lidic": 869997
                },
                {
                  "type": "barre_vertical",
                  "lidic": 869996
                }
                ],
                  config: {
                    barre_horizontal: {
                      "125": 32.67,
                      "160": 43.32,
                      "200": 57.94,
                      "250": 73.65,
                      "315": 93.39,
                      "355": 106.65,
                      "400": 148.46,
                      "450": 165.67,
                      "500": 185.24,
                      "560": 206.04,
                      "630": 233.02,
                      "710": 330.08,
                      "800": 500.64
                    },
                    barre_vertical: {
                      "180": [
                        177,
                        81
                      ],
                      "300": [
                        211,
                        92
                      ],
                      "450": [
                        266,
                        109
                      ],
                      "700": [
                        329,
                        126
                      ],
                      "1100": [
                        433,
                        169
                      ],
                      "1400": [
                        480,
                        189
                      ],
                      "1800": [
                        646,
                        246
                      ],
                      "2300": [
                        768,
                        269
                      ],
                      "2900": [
                        947,
                        269
                      ],
                      "3500": [
                        1057,
                        354
                      ],
                      "4500": [
                        1270,
                        415
                      ]
                    }
                  }
              },
              status: "waiting",
              debit_max: [],
              diametre_aspi: 0,
              diametre_rejet: 0,
              type_aspiration:'',
              tension:'',
              type:'',
            };
            this.currentVMCLidic = item;
          }else{
            this.currentVMC = item;
            this.currentVMCLidic = item.lidic;
          }
          this.editVMC = true;
        break;
        case 'individuel_option':
          if(item==-1){
            this.currentVMC.options.push({content:{number:1,options:[]},libelle:''});
            this.currentOptions = this.currentVMC.options[this.currentVMC.options.length - 1];
          }else{
            this.currentOptions = item;
          }
          this.editOptions = true;
        break;
        case 'individuel_params':
          if(item==-1){
            this.currentVMC.params.items.push({type:''});
            this.currentParams = this.currentVMC.params.items[this.currentVMC.params.items.length - 1];
          }else{
            this.currentParams = item;
          }
          this.editParams = true;
        break;
        case 'individuel_config':
          if(item==-1){
            this.currentConfig = {};
            this.currentConfigLabel = 'Nouveau label';
            this.tmpConfigLabel = 'Nouveau label';
          }else{
            this.currentConfig = this.currentVMC.params.config[item.label];
            this.currentConfigLabel = item.label;
            this.tmpConfigLabel = this.currentConfigLabel;
          }
          this.editConfig = true;
          console.log(this.currentConfig);
        break;
        case 'individuel_gaines':

          if(item==-1){
            this.currentGaine = {
              items:{
                0:[],
                1:[]
              },
              longueur:0
            };
            this.currentGaineLidic = -1;
            this.tmpGaineLidic = null;
          }else{
            this.currentGaine = this.currentParams[item.lidic];
            this.currentGaineLidic = item.lidic;
            this.tmpGaineLidic = this.currentGaineLidic;
          }
          this.editGaines = true;
          console.log(item);
        break;
      }
    },
    deleteItem(item,type){
      switch(type){
        case 'individuel':
          this.$http.delete(`${this.$api_url}vmc/collectif/${item.lidic}`).then((result) => {
            console.log(result);
            if(result){
              var index = this.collectif.vmc.findIndex((vmc) => { return item.lidic == vmc.lidic; });
              if(index>-1) this.collectif.vmc.splice(index,1);
            }else{
              console.log(result);
            }
          });
        break;
        case 'individuel_option':
          this.currentVMC.options.splice(item,1);
        break;
        case 'individuel_params':
          this.currentVMC.params.items.splice(item,1);
        break;
        case 'individuel_config':
          this.$delete(this.currentVMC.params.config, item.label);
        break;
        case 'individuel_gaines':
          this.$delete(this.currentParams, item.lidic);
        break;
      }
    },
    cancel(type){
      switch(type){
        case 'vmc':
          this.editVMC = false;
          this.currentVMC = {};
        break;
        case 'calcul_params':
          this.editCalcul = false;
          this.currentCalcul = {};
        break;
      }
    },
    saveItem(type){
      var baseURI = this.$api_url+'vmc';
      var type_content = '';
      switch(type){
        case 'calcul_params':
          console.log(this.currentCalcul);
          type_content = 'collectif_calcul';
          this.$http.put(baseURI,{vmc: this.currentCalcul,lidic: this.currentCalcul.id, type:type_content})
            .then((result) => {
              console.log(result);
              if(result){
                var index = this.collectif.calcul.findIndex((item) => { return item.id == this.currentCalcul.id; });
                this.$set(this.collectif.calcul,index,this.currentCalcul);
                this.currentCalcul = {};
                this.editCalcul = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
        break;
        case 'vmc':
          console.log(this.currentVMC);
          type_content = 'collectif';
          if(this.currentVMCLidic==-1){
            this.$http.post(baseURI,{vmc: this.currentVMC, type:type_content})
            .then((result) => {
              console.log(result);
              if(result){
                var indexVMC=0;
                if(this.collectif.vmc.lengh>0){
                  indexVMC=this.collectif.vmc.length-1;
                }

                this.$set(this.collectif.vmc,indexVMC,this.currentVMC);
                this.currentVMC = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }else{
            this.$http.put(baseURI,{vmc: this.currentVMC,lidic: this.currentVMCLidic, type:type_content})
            .then((result) => {
              console.log(result);
              if(result){
                var index = this.collectif.vmc.findIndex((item) => { return item.lidic == this.currentVMCLidic; });
                this.$set(this.collectif.vmc,index,this.currentVMC);
                this.currentVMC = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }
        break;
      }
    },
    rowClass(item,type){
      if (item && type === 'row') {
        if(item.status=='valid'){
          return 'validation_success';
        }else if(item.status=='error'){
          return 'validation_error';
        }else if(item.status=="waiting"){
          return 'waiting_validation';
        }
      }else{
        return null;
      }
    },
    updateVMC(){
      if(this.currentVMC.lidic!=""){
        let index = this.product.findIndex((item)=> {
          return item.lidic == this.currentVMC.lidic});
        if(index!=1){
          this.currentVMC.nom = this.product[index].nom;
          this.currentVMC.designation = this.product[index].designation;
          this.currentVMC.prix_unitaire = this.product[index].prix_unitaire;
          this.currentVMC.debit_max = this.product[index].debit_max;
        }
        console.log(this.currentVMC);
      }
    },
    updateLidicPiquetage(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.lidic) || item.nom.toString().includes(this.currentParams.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicGaineProd(nb_etage,index){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentGaine.items[nb_etage][index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentGaine.items[nb_etage][index].lidic) || item.nom.toString().includes(this.currentGaine.items[nb_etage][index].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicGaine(){
      let res = [];
      this.product.forEach((item) => {
        if(this.tmpGaineLidic.toString().length>3 && (item.lidic.toString().includes(this.tmpGaineLidic) || item.nom.toString().includes(this.tmpGaineLidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicOptions(index,isCheckbox){
      let res = [];
      if(isCheckbox){
        this.product.forEach((item) => {
          if(this.currentOptions.content.items[index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentOptions.content.items[index].lidic) || item.nom.toString().includes(this.currentOptions.content.items[index].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }else{
        this.product.forEach((item) => {
          if(this.currentOptions.content.options[index].value.toString().length>3 && (item.lidic.toString().includes(this.currentOptions.content.options[index].value) || item.nom.toString().includes(this.currentOptions.content.options[index].value))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }
      this.lidic_params = res;
      return res;
    },
    clearLidicBouche(){
      this.lidic_params = [];
    },
    updateLidicCommande(type_commande,index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentCalculParams[type_commande][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[type_commande][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[type_commande][index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicEntree(rallonge,index,index2,type){
      let res = [];
      switch(type){
        case 'calcul':
          if(rallonge){
            this.product.forEach((item) => {
              if(this.currentCalculParams['rallonge'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams['rallonge'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams['rallonge'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }else{
            this.product.forEach((item) => {
              if(this.currentCalculParams['entree'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams['entree'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams['entree'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }
        break;
        case 'vmc':
          if(rallonge){
            this.product.forEach((item) => {
              if(this.currentParams['rallonge'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['rallonge'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['rallonge'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }else{
            this.product.forEach((item) => {
              if(this.currentParams['entree'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['entree'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['entree'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }
        break;
      }
      this.lidic_params = res;
      return res;
    },
    updateLidicBouche(index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[index][index2].lidic) || item.nom.toString().includes(this.currentParams[index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicParams(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.lidic) || item.nom.toString().includes(this.currentParams.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicPTCommande(pt,cmd,index,index2,mainDefine,isSupp,type){
      console.log("called");
      let res = [];
      switch(type){
        case 'calcul':
          if(mainDefine){
            if(isSupp){
              this.product.forEach((item) => {
                if(this.currentCalculParams[pt][cmd]['supp'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt][cmd]['supp'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt][cmd]['supp'][index][index2].lidic))){
                  res.push({'value':item.lidic, 'text':item.nom});
                }
              });
            }else{

              this.product.forEach((item) => {
                if(this.currentCalculParams[pt][cmd]['main'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt][cmd]['main'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt][cmd]['main'][index][index2].lidic))){
                  res.push({'value':item.lidic, 'text':item.nom});
                }
              });
            }
          }else{
            this.product.forEach((item) => {
              if(this.currentCalculParams[pt][cmd][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt][cmd][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt][cmd][index][index2].lidic))){
                res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }
        break;
        case 'vmc':
        break;
      }
      this.lidic_params = res;
      return res;
    },
    updateLidicPT(pt,index,index2,mainDefine,isSupp,type){
      let res = [];
      switch(type){
        case 'calcul':
        if(mainDefine){
          if(isSupp){
            this.product.forEach((item) => {
              if(this.currentCalculParams[pt]['supp'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt]['supp'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt]['supp'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }else{
            this.product.forEach((item) => {
              if(this.currentCalculParams[pt]['main'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt]['main'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt]['main'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }
        }else{
          this.product.forEach((item) => {
            if(this.currentCalculParams[pt][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt][index][index2].lidic))){
            res.push({'value':item.lidic, 'text':item.nom});
            }
          });
        }
        break;
        case 'vmc':
          this.product.forEach((item) => {
            if(this.currentParams[pt].items[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[pt].items[index][index2].lidic) || item.nom.toString().includes(this.currentParams[pt].items[index][index2].lidic))){
            res.push({'value':item.lidic, 'text':item.nom});
            }
          });
        break;
      }

      this.lidic_params = res;
      return res;
    },
    updateLidicProduct(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentVMC.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentVMC.lidic) || item.nom.toString().includes(this.currentVMC.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_product = res;
      return res;
    }
  },
  computed: {
    getcurrentCaculType(){
      let res = "";
      if(this.currentCalcul!=null && this.currentCalcul.id!=-1){
        switch(this.currentCalcul.id){
          case 'A':
            res = 'Auto';
          break;
          case 'HA':
            res = "Hydro A";
          break;
          case 'HB':
            res = "Hydro B";
          break;
        }
      }else{
        res = 'Nouveau Calcul';
      }
      return res;
    },
    get_gaines_params(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="gaines"){
        console.log(this.currentParams);
        Object.keys(this.currentParams).filter(e => e !== 'type').forEach((item)=>{
          res.push({lidic: item});
        });
      }
      return res;
    },
    typologie_range(){
      let res = [];
      if(this.currentVMC!=null && this.currentVMC.typologie!=undefined && this.currentVMC.typologie_min!=undefined){
        let tmp = this.currentVMC.typologie_min;
        while(tmp<=this.currentVMC.typologie){
          res.push(tmp);
          tmp++;
        }
      }
      return res;
    },
    piece_technique_range(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="piece_technique" && this.currentParams['eau']!=null){
        res.push({value:'eau',text:"Salle d'eau"});
        res.push({value:'wc',text:"WC"});
        res.push({value:'sdb_wc',text:"Salle de bain avec WC"});
        res.push({value:'sdb',text:"Salle de bain"});
      }
      return res;
    },
    commande(){
      let res = [];
      if(this.currentCalculParams!=null && this.currentCalculParams.type=="piece_technique"){
        res.push({value:'M',text:"Commande Manuelle"});
        res.push({value:'P',text:"Commande à Pile"});
        res.push({value:'220V',text:"Commande 220V"});
        res.push({value:'12V',text:"Commande 12V"});
      }
      return res;
    },
    piece_technique_range_calcul(){
      let res = [];
      if(this.currentCalculParams!=null && this.currentCalculParams.type=="piece_technique" && this.currentCalculParams['eau']!=null){
        res.push({value:'eau',text:"Salle d'eau"});
        res.push({value:'wc',text:"WC"});
        res.push({value:'sdb_wc',text:"Salle de bain avec WC"});
        res.push({value:'sdb',text:"Salle de bain"});
      }
      return res;
    },
    total_devis(){
      return this.items.length;
    },
    getCurrentConfig(){
      let res = [];
      Object.keys(this.currentVMC.params.config).forEach((item)=>{
        console.log(item);
        res.push({label:item});
      });
      return res;
    },
    total_prix(){
      let res = 0;
      this.items.forEach((item) => {
        res += parseFloat(item.prix);
      });
      res = res.toFixed(2);
      return res;
    },
    items(){
      let res;
      if((this.start!=null && this.start!="") && (this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime() && new Date(elem.created_at).getTime() <= new Date(this.end).getTime());
      }else if((this.start!=null && this.start!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime());
      }else if((this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime()<= new Date(this.end).getTime());
      }else{
        res = this.devis;
      }
      return res;
    }
  },mounted(){
    console.log(this.collectif.vmc.length);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
