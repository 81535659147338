<template>
  <b-container id="options" :style="cssProps">
    <div class="header inner-shadow-box">
      <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Estimair" id="estimair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
    </div>
    <div class="arianne">
      <p><router-link to="/">Accueil</router-link> <span class='decoration'>></span> <router-link :to="prev">Config</router-link> <span class='decoration'>></span> <router-link to="/list">{{list_label}}</router-link> <span class='decoration'>></span> <span class='selected'>Accessoires</span> <span class='decoration'>></span> Données <span class='decoration'>></span> PDF</p>
    </div>
    <h3>Choisissez vos accessoires</h3>
    <div class="options-item" v-for="(option,index) in vmc.options" :key="index">
      <h5 v-if="!isCheckBox(option.content)" :class="showPremium[index]?'toShow':'toHide'"><span>{{first_word(option.libelle)}}</span> {{follow_word(option.libelle)}}</h5>
      <b-row v-if="!isCheckBox(option.content)" :class="showPremium[index]?'toShow':'toHide'">
        <b-col class="options-content" cols="12" md="6" lg="3" v-for="(opt,index2) in option.content.options" :key="index2" @click="selectOptions(opt,index,option.content.number,option.libelle,$event)" :class="opt.hasOwnProperty('terasse') && !opt.terasse && $store.state.devis.devis.request.terrasse ? ['_'+index,'c'+opt.value,'hide_options']:['_'+index,'c'+opt.value]">
          <h6>{{opt.text}}</h6>
          <b-img :src="`/img/${opt.value}.jpg`" fluid class='vignette'/>
        </b-col>
      </b-row>
      <b-row v-if="isCheckBox(option.content)" align-h="center">
        <b-col lg="8" md="12" cols="12" class="image_options" :class="checkbox[index]?'selected_options':''" @click="callCheckBox(index,option.content)">
          <b-row style="margin-bottom:2em;">
            <h5 class="online"><span>{{first_word(option.libelle)}}</span> {{follow_word(option.libelle)}} <b-form-checkbox class="checkbox-position" v-model="checkbox[index]" @change="selectCheckBox($event,index,option.content)" switch></b-form-checkbox></h5>
          </b-row>
          <b-row>
            <b-col cols="12" :sm="(12/option.content.images_line)" v-for="(image,index_image) in option.content.images" :key="index_image" align-self="center" style="margin-bottom:3em;">
              <b-row align-v="center" align-h="end">
                <b-col cols="5">
                  <img :src='"/img/"+$path+"/"+image.name'/>
                </b-col>
                <b-col cols="7">
                  <h6>{{image.title}}</h6>
                  <p>{{image.text}}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-row class="footer align-items-center no-gutters">
      <b-col md="2" cols="6" order="2" order-md="1" style="text-align:left;">
        <b-button @click="back($event)">
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </b-button>
      </b-col>
      <b-col md="8" cols="12" order="1" order-md="2"></b-col>
      <b-col md="2" cols="6" order="3" order-md="3" style="text-align:right;">
        <b-button type="submit" class="next" @click="next($event)" :disabled="!validate">
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'Options',
  data() {
      return {
        show: true,
        validate: false,
        list_label: 'Choix VMC',
        classic: true,
        checkbox:[],
        showPremium:[],
        click_switch: false,
      }
    },
    methods: {
      back(evt){
        evt.preventDefault();
        this.$store.dispatch('devis/setOptions',undefined);
        this.$router.go(-1);
      },
      isCheckBox(content){
        return (Object.prototype.hasOwnProperty.call(content,'type') && content.type=="checkbox");
      },
      isShowPremium(index){
        var res = true;
        if(this.vmc.options[index].showPremium!=undefined && this.vmc.options[index].showPremium){
          if(this.form[1][0]!=undefined){ res = this.form[1][0].lidic.toString().includes("810400"); }else{ res = false; }
        }
        this.showPremium.splice(index,1,res);
      },
      first_word(word){
        return word.split(" ")[0];
      },
      follow_word(word){
        var res = word.split(" ");
        res.shift();
        return res.join(" ");
      },
      next(evt) {
        evt.preventDefault()
        this.$store.dispatch('devis/setOptions',this.form);
        if(this.$path=='/sonepar/' || this.$dev || this.$path=="/rexel/" || this.$path=="/cged/" || this.$path=="/yes/"){
            this.sonepar_devis();
        }else{
          this.$router.push('informations');
        }
      },
      sonepar_devis(){
        this.$store.dispatch('devis/setInformations',{});
        console.log(this.$store.state.devis.devis);
        const baseURI = this.$api_url+'devis'
        this.$http.post(baseURI,{'devis':this.$store.state.devis.devis})
        .then((result) => {
          if(result){
            console.log(result.data);
            this.$store.dispatch('devis/setPDF',result.data);
            console.log(this.$store.state.devis.devis);
            this.$router.push('devis');
          }

        })
      },
      selectCheckBox(val,index,opt){
        console.log(val);
        this.callCheckBox(index,opt);
      },
      callCheckBox(index,opt){
        console.log("calledCheckbox");
        this.checkbox[index] = !this.checkbox[index];
        if(this.checkbox[index] && opt.items!=undefined){
          this.form[index][0] = opt.items[0]['lidic'];
        }else{
          this.form[index][0] = null;
        }
        this.$forceUpdate();
      },
      selectOptions(opt,index,qte,libelle,ev){
        console.log("selectOptions");
        console.log(opt);
        console.log(index);

        if(this.vmc.options[index].content['multiple']==true){
          console.log("multiple");
          console.log(opt.value);
          if(ev.currentTarget.classList.contains('active')){
            var indexValueDel = this.form[index].findIndex(element => {
              if(typeof element === 'object'){
                return parseInt(element.lidic)==opt.value;
              }else{
                return element == opt.value;
              }
            });
            if(indexValueDel!=-1){
              console.log(indexValueDel);
              var qte_tmp_del = opt.qte != undefined ? opt.qte : qte;
              console.log(qte_tmp_del);
              this.form[index][indexValueDel]['qte'] -= parseInt(qte_tmp_del);
              if(this.form[index][indexValueDel]['qte']==0){
                this.form[index].splice(indexValueDel,1);
                console.log(this.form[index]);
                console.log(this.form[index].length);
                if(this.form[index].length==0){
                  console.log("error");
                  this.form[index][0]=undefined;
                }
              }
            }
          }else{
            var indexValue = this.form[index].findIndex(element => {
              if(typeof element === 'object'){
                return parseInt(element.lidic)==opt.value;
              }else{
                return element == opt.value;
              }
            });
            console.log(this.form[index]);
            console.log(indexValue);
            var qte_tmp = opt.qte != undefined ? opt.qte : qte;
            if(indexValue!=-1){
              console.log(indexValue);
              this.form[index][indexValue]['qte'] += parseInt(qte_tmp);
            }else{
              console.log(this.form[index][0]);
              if(this.form[index][0]==undefined){
                this.form[index][0] = {lidic:opt.value,qte:parseInt(qte_tmp)};
              }else{
                this.form[index].push({lidic:opt.value,qte:parseInt(qte_tmp)});
              }
            }
          }
          ev.currentTarget.classList.toggle("active");
        }else{
          console.log(opt);
          console.log(libelle);
          var qte_tmp_2 = opt.qte != undefined ? opt.qte : qte;
          if(ev.currentTarget.classList.contains('active')){
            this.form[index][0] = undefined;
            let x = document.getElementsByClassName("active");
            for(var j = 0; j < x.length; j++) {if(x[j].classList.contains('_'+index)){ x[j].classList.remove("active");}}
          }else{
            let x = document.getElementsByClassName("active");
            for(var k = 0; k < x.length; k++) {if(x[k].classList.contains('_'+index)){ x[k].classList.remove("active");}}
            this.form[index][0] = {lidic:opt.value,qte:parseInt(qte_tmp_2),type:libelle};
            ev.currentTarget.classList.toggle("active");
          }
        }
        this.vmc.options.forEach((item, i) => {
          this.isShowPremium(i);
        });
        this.changeValidate();
      },
      changeValidate(){
        let res = true;
        console.log("try validate");
        console.log(this.form);
        if(this.$store.state.devis.devis['type']!="tertiaire"){
          for(var i = 0 ; i < this.form.length;i++){
            for(var j = 0 ; j < this.form[i].length;j++){
              if(this.form[i][j]==undefined){
                if((!this.vmc.options[i].content.type || !this.vmc.options[i].content.type=='checkbox') && (this.vmc.options[i].content['optionnal']==undefined || !this.vmc.options[i].content['optionnal'])){
                  res = false;
                  break;
                }
              }
            }
          }
        }
        this.validate = res;
      },
    },
    computed: {
      cssProps() {
          return {
            '--maincolor': this.$maincolor
          }
        },
      prev() {
        let res = '/'
        console.log("Back");
        switch(this.$store.state.devis.devis['type']){
          case "individuel":
            res = '/individuel';
          break;
          case 'collectif':
            res = '/collectif';
          break;
          case 'desenfumage':
            res = '/desenfumage';
          break;
          default:
          break;
        }
        return res;
      },
      form(){
        let state = [];
        if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['options']!=undefined){
          state = this.$store.state.devis.devis['options'];
          /*state.forEach((item,j) => {
            this.checkbox[j] = item[0];
          });*/
          if(state.length<this.$store.state.devis.devis['vmc']['options'].length){
            this.$store.state.devis.devis['vmc']['options'].forEach((item, i) => {
              if(!state[i]){
                console.log('undefined add option');
                state[i] = new Array(this.$store.state.devis.devis['vmc']['options'][i]['content']['number']);
                if(this.$store.state.devis.devis['vmc']['options'][i]['content']['type'] && this.$store.state.devis.devis['vmc']['options'][i]['content']['type']=='checkbox'){
                  state[i].fill(null);
                }
              }
            });
          }
        }else{
          if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['vmc']!=undefined){
            this.$store.state.devis.devis['vmc']['options'].forEach((item, i) => {
              state[i] = new Array(parseInt(this.$store.state.devis.devis['vmc']['options'][i]['content']['number']));
              if(this.$store.state.devis.devis['vmc']['options'][i]['content']['type'] && this.$store.state.devis.devis['vmc']['options'][i]['content']['type']=='checkbox'){
                state[i].fill(null);
              }
            });
          }
        }
        return state;
      },
      vmc(){
        let vmc = {};
        if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['vmc']!=undefined){
          vmc = this.$store.state.devis.devis['vmc'];
        }
        console.log(vmc);
        return vmc;
      },
    },mounted(){
      window.scrollTo(0, 0);
      console.log(this.$store.state.devis.devis['options']);
      console.log(this.$store.state.devis.devis['vmc']);
      switch(this.$store.state.devis.devis['type']){
          case 'desenfumage':
            this.list_label = 'Choix Volet';
            this.classic = false;
          break;
          default:
            this.list_label = 'Choix VMC';
            this.classic = true;
          break;
      }

      if(this.$store.state.devis.devis['options']!=undefined){
        console.log(this.$store.state.devis.devis['options']);
        this.$store.state.devis.devis['options'].forEach((item,j) => {
          if(typeof item[0] === 'object' && item[0] !== null){
            item.forEach((item2,k)=>{
              console.log(item2);
              console.log(k);
              let x = document.getElementsByClassName('_'+j+' c'+item2.lidic);
              console.log(x);
              if(item2.qte==x.length && item2.qte>1){
                console.log("ok");
                for(var y=0;y<item2.qte;y++){
                  x[y].classList.add("active");
                }
              }else{
                x[0].classList.add("active");
              }
            });
            //this.isShowPremium(j);
          }else if(item[0]!=undefined && item[0] !== null){
            this.checkbox[j] = item[0];
          }
        });

      }
      this.vmc.options.forEach((item,j) => {
        console.log("okPremium");
        console.log(this.showPremium);
        this.isShowPremium(j);
        this.changeValidate();
      });
    },
}
</script>

<style lang="scss">
.toHide{ display: none; }
#options{
  text-align: left;
  max-width: 1980px;
  margin: 2em auto;
  @media (max-width: 576px) {
      padding:0;
  }
  select{
    margin-bottom:2em;
    background-color: #dc3545;
    border-radius: 50px;
    color: white;
  }

  .image_options{
    &.selected_options{
      border: 2px solid var(--maincolor);

      .custom-switch  .custom-control-input:checked~.custom-control-label::before, .custom-control-input:disabled:checked~.custom-control-label::before{
        background: var(--maincolor) !important;
        box-shadow: none;
      }
    }
    cursor:pointer;
    background: linear-gradient(-122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
    box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 30%), 4px 4px 15px 0px #fff;
    padding: 2em 4em;
    border-radius: 1em;
    border:0px;
    img{ width: auto;max-height: 120px;max-width:100%; }
    h6{ font-weight:bold;font-size:1.5em;
      @media (max-width: 1100px) {
        font-size:1em;
      }
    }
    p{font-size:1.5em;
      @media (max-width: 1100px) {
        font-size:1em;
      }
    }
  }
  .options-item{
    margin-top:2em;
    .custom-control-label {
      color: #666;
      text-align: left;
      font-size: 1.5em;
    }

    .custom-switch  {
      //padding-left: 3.25rem;
      display:none;
      margin-left:0.5em;
      .custom-control-label::before {
          //left: -2.25rem;
          //width: 1.75rem;
          pointer-events: all;
          border-radius: 1.5rem;
          padding: 0.9rem 1.75rem;
          background: transparent !important;
          box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
          border: 0px;
          top: 0;
      }

      .custom-control-label::after {
          //top: calc(.25rem + 2px);
          //left: calc(-2.25rem + 2px);
          width: calc(1.9rem - 10px);
          height: calc(1.9rem - 10px);
          background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border-radius: 2.5rem;
          top: 0.25rem;
          left:calc(-2.25rem + 5px);
          transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }
      .custom-control-input:checked~.custom-control-label::before, .custom-control-input:disabled:checked~.custom-control-label::before{
        background: linear-gradient(-122deg, rgba(51,51,51,1) 0%, rgba(102,102,102,1) 100%) !important;
        box-shadow: none;
      }

      .custom-control-input:checked~.custom-control-label::after {
          background: white;
          transform: translateX(1.6rem);
      }
    }

    h5{
      text-transform: uppercase;
      color: #333;
      font-weight: bold;
      span { color: var(--maincolor); }
    }

    .options-content{
      background: linear-gradient(-122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
      padding: 2em 0.5em;
      text-align: center;
      @media (max-width: 576px) {
          margin: 2em 0px;
      }
      margin: 2em 30px;
      width: 100%;
      max-width: 400px;
      border-radius: 1em;
      cursor: pointer;

      &.hide_options {
        display:none;
      }
      &.active{
          border: 2px solid var(--maincolor);
      }
      h6{
        color:#333;
        font-weight: bold;
        font-size:1.5em;
      }

      img{
        width:70%;
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
        border-radius: 1em;
        padding: 0.5em 1em;
        background-color: white;
        margin: 1em 0;
      }
    }
  }
}
</style>
