export const ProductsModule = {
  namespaced: true,
  state: {
    products: null
  },
  mutations: {
    SET_PRODUCTS(state, products){
      state.products = products;
    }
  },
  actions: {
    setProducts({commit},products){
      commit('SET_PRODUCTS',products);
    }
  },
  getters: {
    getProducts(state){
      return state.products;
    }
  }
}
