import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Individuel from '../views/Individuel.vue'
import Collectif from '../views/Collectif.vue'
import List from '../views/List.vue'
import Options from '../views/Options.vue'
import Devis from '../views/Devis.vue'
import Informations from '../views/Informations.vue'
import Desenfumage from '../views/Desenfumage.vue'
import Reporting from '../views/Reporting.vue'
import Tertiaire from '../views/Tertiaire.vue'
import Administration from '../views/Admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/individuel',
    name: 'Individuel',
    component: Individuel
  },
  {
    path: '/collectif',
    name: 'Collectif',
    component: Collectif
  },
  {
    path: '/tertiaire',
    name: 'Tertiaire',
    component: Tertiaire
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/options',
    name: 'Options',
    component: Options
  },
  {
    path: '/devis',
    name: 'Devis',
    component: Devis
  },
  {
    path: '/informations',
    name: 'Informations',
    component: Informations
  },
  {
    path: '/reporting',
    name: 'Reporting',
    component: Reporting
  },
  {
    path: '/desenfumage',
    name: 'Desenfumage',
    component: Desenfumage
  },
  {
    path: '/admin',
    name: 'Administration',
    component: Administration
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
