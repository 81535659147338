<template>
  <div id="catalogue_admin">
    <b-row  v-if="editVMC">
      <b-col cols="12" md="12" lg="12">
        <div class="content-block shadow-box">
          <div class='header-form'>
            <p><span>Nouveau Produit</span><br></p>
          </div>
          <div class="content-form roundButton">
            <b-row>
              <b-col cols="2">
                <label for="lidic_product">LIDIC: </label>
              </b-col>
              <b-col>
                <b-form-input id="lidic_product" v-model="currentVMC.lidic"></b-form-input>
            </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="lm_relation">Code Marchand: </label>
              </b-col>
              <b-col>
                <b-form-input id="lm_relation" v-model="currentVMC.code_marchand"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="ean">EAN: </label>
              </b-col>
              <b-col>
                <b-form-input id="ean" v-model="currentVMC.ean"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="ref_product">Référence: </label>
              </b-col>
              <b-col>
                <b-form-input id="ref_product" v-model="currentVMC.nom"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="design_product">Désignation: </label>
              </b-col>
              <b-col>
                <b-form-input id="design_product" v-model="currentVMC.designation"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="pu_product">Prix Unitaire: </label>
              </b-col>
              <b-col>
                <b-form-input id="pu_product" v-model="currentVMC.prix_unitaire"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="debit_max_product">Débit Max: </label>
              </b-col>
              <b-col>
                <b-form-input id="debit_max_product" v-model="currentVMC.debit_max"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="sous_categ">Sous Catégorie (PDF): </label>
              </b-col>
              <b-col>
                <b-form-input id="sous_categ" v-model="currentVMC.sub_categorie"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="cancel('product')" class="mr-1">Annuler</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="saveItem('product')" class="mr-1">Enregistrer</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
  </b-row>
      <b-row  v-if="!editVMC">
        <b-col cols="12" md="12" lg="12">
          <div class="content-block shadow-box">
            <div class='header-form'>
              <p><span>Catalogue Produit</span><br></p>
            </div>
            <div class="content-form roundButton">
              <b-alert
                :show="dismissCountDown"
                dismissible
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
              >
                <p>VMC enregistré avec succès.</p>
              </b-alert>
      <b-row align-h="end">
        <b-col cols="2">
          <b-button size="sm" target="_blank" @click="editItem(-1,'product')" class="mr-1">Ajouter Produit</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="2">
          <b-form-group label="Filtrer" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Rechercher par LIDIC, Nom, Type,..."></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-pagination
        v-model="currentPage"
        :total-rows="total_rows_vmc"
        :per-page="perPage"
        aria-controls="my-tableImg"
      ></b-pagination>
      <b-table responsive striped hover :items="product" :fields="fields" :filter="filter" id="my-table" :per-page="perPage" :current-page="currentPage" :tbody-tr-class="rowClass">
      <template #cell(vignette)="row">
        <b-img :src="`/img/${row.item.lidic}.jpg`"/>
      </template>
        <template #cell(actions)="row">
          <b-button size="sm" target="_blank" @click="editItem(row.item,'product')" class="mr-1">Editer</b-button>
          <b-button size="sm" target="_blank" @click="deleteItem(row.item,'product')" class="mr-1">Supprimer</b-button>
        </template>
      </b-table>
      </div></div>
    </b-col>
    </b-row>
    <datalist id="my-list-pt">
        <option :value="prod.value" v-for="(prod,index3) in lidic_params" :key="index3">{{ prod.text }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'CataloguelAdmin',
  props: {
    product: Array,
    total_rows_vmc: Number,
  },
  data() {
      return {
        total_rows: this.product.length,
        perPage: 20,
        currentPage:1,
        dismissCountDown: 0,
        currentVMC:{},
        currentVMCLidic: -1,
        currentOptions:{},
        currentParams:{},
        currentConfig:{},
        newConfig: 'Nouveau Label',
        currentGaine:{},
        currentGaineLidic: null,
        tmpGaineLidic: null,
        currentConfigLabel: null,
        tmpConfigLabel: null,
        lidic_options:[],
        lidic_params:[],
        lidic_product:[],
        newConfigParam: '',
        editVMC:false,
        editOptions:false,
        editParams:false,
        editConfig:false,
        editGaines:false,
        filter: null,
        fields: [
          { key: 'vignette', label: '',tdClass: 'product-vignette' },
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          {
            key: 'nom',
            label: 'Reference',
            sortable: true
          },
          {
            key: 'designation',
            label: 'Designation',
            sortable: true
          },
          {
            key: 'code_marchand',
            label: 'Code Marchand',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
      }
  },
  methods: {
    countDownChanged(dismisscd){
      this.dismissCountDown = dismisscd;
    },
    copyItem(item,type){
      switch(type){
        case 'individuel':
          this.currentVMC = JSON.parse(JSON.stringify(item));
          this.currentVMC.lidic=null;
          this.currentVMC.status= "waiting",
          this.currentVMCLidic = -1;
          this.editVMC = true;
        break;
      }
    },
    editItem(item,type){
      switch(type){
        case 'product':

          if(item==-1){
            this.currentVMC = {
              lidic: null,
              nom:"",
              designation:"",
              prix_unitaire:0,
              debit_max:0,
              code_marchand:"",
              sub_categorie:""
            };
            this.currentVMCLidic = item;
          }else{
            this.currentVMC = item;
            this.currentVMCLidic = item.lidic;
          }
          this.editVMC = true;
        break;
      }
    },
    deleteItem(item,type){
      switch(type){
        case 'product':
          this.$http.delete(`${this.$api_url}product/${item.lidic}`).then((result) => {
            console.log(result);
            if(result){
              var index = this.product.findIndex((vmc) => { return item.lidic == vmc.lidic; });
              if(index>-1) this.product.splice(index,1);
            }else{
              console.log(result);
            }
          });
        break;
      }
    },
    cancel(type){
      switch(type){
        case 'product':
          this.editVMC = false;
          this.currentVMC = {};
        break;
      }
    },
    saveItem(type){
      switch(type){
        case 'product':
          console.log(this.currentVMC);
          var baseURI = this.$api_url+'product';
          if(this.currentVMCLidic==-1){
            this.$http.post(baseURI,{product: this.currentVMC})
            .then((result) => {
              console.log(result);
              if(result){
                this.$set(this.product,(this.product.length-1),this.currentVMC);
                this.currentVMC = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }else{
            console.log(this.currentVMC);
            this.$http.put(baseURI,{product: this.currentVMC,lidic: this.currentVMCLidic})
            .then((result) => {
              console.log(result);
              if(result){
                var index = this.product.findIndex((item) => { return item.lidic == this.currentVMCLidic; });
                this.$set(this.product,index,this.currentVMC);
                this.currentVMC = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }
        break;
      }
    },
    rowClass(){
      return null;
    },
  },
  computed: {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
