<template>
    <b-container class="home" fluid="xl">
      <b-row align-h="center" align-v="center">
        <b-col md="10" sm="12" style="position:relative;">
          <router-link to="/"><b-img :src='"/img/"+$path+"logo.png"' fluid alt="Logo Estimair" id="estimair-logo"></b-img></router-link>
        </b-col>
      </b-row>
      <b-row align-h="center" align-v="center">
        <p>Bienvenue, choisissez votre type de chantier</p>
      </b-row>
      <b-row style="margin-top:2em;" align-h="center">
        <b-col sm="12" md="3" class="entry">
            <router-link @click.native="setDevis('individuel')" to="/individuel" v-bind:class="{'disabled':!module_individuel}">
              <p class="upcoming" v-if="!module_individuel">
                Bientôt Disponible
              </p>
              <div class="contain shadow-box" v-bind:class="{'disabled':!module_individuel}">
                <b-img src="../assets/individuel.jpg" fluid alt="Devis Individuel" class="entry_point"></b-img>
                <div class="caption">
                  <p>Vmc habitat individuel <span class="shadow-box"><img :src='"/img/"+$path+"arrow_right.png"' /></span></p>
                </div>
              </div>
            </router-link>
        </b-col>
        <b-col sm="12" md="3" class="entry" v-if="!$islm">
          <router-link @click.native="setDevis('collectif')" to="/collectif" v-bind:class="{'disabled':!module_collectif}">
            <p class="upcoming" v-if="!module_collectif">
              Bientôt Disponible
            </p>
            <div class="contain shadow-box" v-bind:class="{'disabled':!module_collectif}">
              <b-img src="../assets/collectif.jpg" fluid alt="Devis Collectif" class="entry_point"></b-img>
              <div class="caption">
                <p>Vmc habitat collectif <span class="shadow-box"><img :src='"/img/"+$path+"arrow_right.png"'/></span></p>
              </div>
            </div>
          </router-link>
        </b-col>
        <b-col sm="12" md="3" class="entry" v-if="!$islm">
          <router-link @click.native="setDevis('tertiaire')" to="/tertiaire" v-bind:class="{'disabled':!module_tertiaire}">
            <p class="upcoming" v-if="!module_tertiaire">
              Bientôt Disponible
            </p>
            <div class="contain shadow-box" v-bind:class="{'disabled':!module_tertiaire}">
                <b-img src="../assets/tertiaire.jpg" fluid alt="Devis Tertiare" class="entry_point"></b-img>
              <div class="caption">
                <p>Ventilation tertiaire <span class="shadow-box"><img :src='"/img/"+$path+"arrow_right.png"'/></span></p>
              </div>
            </div>
          </router-link>
        </b-col>
        <b-col sm="12" md="3" class="entry" v-if="!$islm">
          <router-link @click.native="setDevis('desenfumage')" to="/desenfumage" v-bind:class="{'disabled':!module_desenfumage}">
            <p class="upcoming" v-if="!module_desenfumage">
              Bientôt Disponible
            </p>
            <div class="contain shadow-box" v-bind:class="{'disabled':!module_desenfumage}">
                <b-img src="../assets/grid-desenfumage.jpg" fluid alt="Devis Accessoires" class="entry_point"></b-img>
              <div class="caption" style="height:80px">
                <p>Désenfumage habitat collectif <span class="shadow-box"><img :src='"/img/"+$path+"arrow_right.png"'/></span></p>
              </div>
            </div>
          </router-link>
        </b-col>
      </b-row>
      <div class="footer inner-shadow-box">
        <p>Proposé par<br>
          <strong>S&amp;P France</strong><br>
          <em>www.solerpalau.fr</em>
        </p>
        <p>
        <span v-if="is_nSP">pour<br></span>
        <b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img>
        </p>
      </div>
    </b-container>
</template>

<script>

export default {
  name: 'Home',
  data() {
      return {
        module_individuel : true,
        module_tertiaire: true,
        module_collectif: true,
        module_desenfumage: true,
        is_nSP: false,
      }
  },
  methods:{
    setDevis(type){
      this.$store.dispatch('devis/setType',type);
    }
  },
  mounted(){
    window.scrollTo(0, 0);

    this.$store.dispatch('devis/setDevis',{});
    this.is_nSP = this.$path!='/base/';

    if(this.$path=='/yes/' && this.$route.query.env){
      this.$store.dispatch('devis/setEnv',this.$route.query.env);
    }
  }
}
</script>

<style lang="scss">
body{
  background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);

  .shadow-box {
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
  }

  .inner-shadow-box{
    box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
    display: inline-block;
  }
  .home{
    .footer{
      border-radius: 3em;
      color: #666666;
      padding: 1.5em 3em;
      line-height: 1.3em;
    }

    #estimair-logo{
      max-width: 80%;
    }

    #logo-business{
      max-width: 100px;
    }

    .entry {
      .upcoming{
        position: absolute;
        top: 19%;
        left: 1em;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        z-index: 8;
        background: rgba(255,0,0,0.5);
        padding: 1px 10px;
        font-size: 1.5em;
        transform: rotate(-15deg);
      }
      .contain{
        &.disabled{
          opacity:0.5;
        }

        position: relative;
        margin-bottom: 2em;
        border-radius: 1em;
        overflow: hidden;
        padding-bottom: 1em;
        .caption {
          position: absolute;
           bottom: -10px;
           width: 100%;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          opacity: 1;
          color: #666;
          border-radius: 0 0 1em 1em;
          height:60px;
           p {
             text-transform: uppercase;
             margin: 0 0 0 1.5em;
             padding:10px 0;
             text-align:left;
             span{
               background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
               border-radius: 50%;
               padding: 5px 9px;
               box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
               img{
                 width:auto;
                 transform: translate(2px, 0);
               }
            }
           }
        }
        img.entry_point {
          border-radius: 1em;
          transition: transform 1s ease;
          width:100%;
          &:hover {
            transform: scale(2);
          }
        }
      }
    }
  }
}
</style>
