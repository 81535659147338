<template>
  <div id="desenfumage_admin">
    <b-row  v-if="editVMC">
      <b-col cols="12" md="12" lg="12">
        <div class="content-block shadow-box">
          <div class='header-form'>
            <p><span>{{currentVMC.nom}}</span><br></p>
          </div>
          <div class="content-form roundButton">
            <b-row>
              <b-col cols="2">
                <label for="vmc_input">Volet: </label>
              </b-col>
              <b-col>
              <b-form-input id="vmc_input" list="my-list-id" v-model="currentVMC.lidic" @blur="updateVMC" @input="updateLidicProduct()"></b-form-input>
              <datalist id="my-list-id">
                  <option :value="prod.value" v-for="(prod,index) in lidic_product" :key="index">{{ prod.text }}</option>
              </datalist>
            </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="statut_input">Status: </label>
              </b-col>
              <b-col>
                <b-form-select v-model="currentVMC.status" :options="status" id="statut_input"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-tabs pills>
                  <b-tab title="Options" active>
                    <div class="content-block shadow-box" v-if="editOptions">
                      <div class='header-form'>
                        <p><span>Options</span><br></p>
                      </div>
                      <div class="content-form roundButton">
                        <b-row>
                        <b-col>
                        <b-row>
                          <b-col cols="2">
                              <label for="options_libelle">Libelle: </label>
                            </b-col>
                          <b-col cols="3">
                            <b-form-input id="options_libelle" v-model="currentOptions.libelle"></b-form-input>
                          </b-col>
                        </b-row>
                        <h4>Options</h4>
                        <b-row v-if="currentOptions.content.type=='checkbox'">
                          <b-col>
                            <b-row v-for="(opt,index) in currentOptions.content.items" :key="index">
                              <b-col cols="2">
                                  <label :for="'options_value_'+index">Produit: </label>
                              </b-col>
                              <b-col cols="3">
                                <b-form-input :id="'options_value_'+index" :list="'my-list-pt-desenfumage'" v-model="currentOptions.content.items[index].lidic" @input="updateLidicOptions(index)" @blur="clearLidicBouche"></b-form-input>
                              </b-col>
                              <b-col cols="2">
                                  <label :for="'options_label_'+index">Nombre: </label>
                              </b-col>
                              <b-col cols="3">
                                <b-form-input :id="'options_label_'+index" v-model="currentOptions.content.items[index].number"></b-form-input>
                              </b-col>
                              <b-col cols="2">
                                <b-button size="sm" target="_blank" @click="remove_item_options(index)" class="mr-1 squarebutton">-</b-button>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col offset="10" cols="2">
                                <b-button size="sm" target="_blank" @click="add_item_options()" class="mr-1 squarebutton">+</b-button>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <h4>Images</h4>
                        <b-row v-if="currentOptions.content.type=='checkbox'">
                          <b-col>
                            <b-row v-for="(opt,index) in currentOptions.content.images" :key="index">
                              <b-col cols="2">
                                  <label :for="'options_value_'+index">Image: </label>
                              </b-col>
                              <b-col cols="3">
                                <b-form-input :id="'options_value_'+index" :list="'my-list-pt-desenfumage'" v-model="currentOptions.content.images[index].name"></b-form-input>
                              </b-col>
                              <b-col cols="5">
                                  <b-row>
                                    <b-col cols="2">
                                      <label :for="'options_label_'+index">Titre: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'options_label_'+index" v-model="currentOptions.content.images[index].title"></b-form-input>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="2">
                                      <label :for="'options_label_'+index">Texte: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'options_label_'+index" v-model="currentOptions.content.images[index].text"></b-form-input>
                                    </b-col>
                                  </b-row>

                              </b-col>
                              <b-col cols="2">
                                <b-button size="sm" target="_blank" @click="remove_item_options_image(index)" class="mr-1 squarebutton">-</b-button>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col offset="10" cols="2">
                                <b-button size="sm" target="_blank" @click="add_item_options_image()" class="mr-1 squarebutton">+</b-button>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col cols="2">
                                  <label :for="'options_value_image_ligne'">Nb image par ligne: </label>
                              </b-col>
                              <b-col cols="3">
                                <b-form-input :id="'options_value_image_ligne'" v-model="currentOptions.content.images_line"></b-form-input>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-button size="sm" target="_blank" @click="close('options')" class="mr-1">Fermer</b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                      </b-row>
                    </div>
                  </div>
                  <b-row align-h="end">
                    <b-col cols="2">
                      <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_option')" class="mr-1">Ajouter Option</b-button>
                    </b-col>
                  </b-row>
                    <b-table responsive striped hover :items="currentVMC.options" :fields="fields_options">
                      <template #cell(actions)="row">
                        <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_option')" class="mr-1">Editer</b-button>
                        <b-button size="sm" target="_blank" @click="deleteItem(row.index,'individuel_option')" class="mr-1">Supprimer</b-button>
                      </template>
                    </b-table>
                  </b-tab>
                  <b-tab title="Paramètre">
                    <div class="content-block shadow-box" v-if="editParams">
                      <div class='header-form'>
                        <p><span>Paramètre</span><br></p>
                      </div>
                      <div class="content-form roundButton">
                        <b-row>
                        <b-col>
                          <b-row>
                            <b-col cols="2">
                              <label for="param_type">Type: </label>
                            </b-col>
                            <b-col>
                              <b-form-select v-model="currentParams.type" :options="param_type" id="param_type" @change="updateParams"></b-form-select>
                            </b-col>
                          </b-row>
                        <b-row v-if="!is_paramType('ventilationhaute_rdc')">
                          <b-col cols="2" v-if="!is_paramType('vmc')">
                              <label :for="'params_value'">Produit: </label>
                          </b-col>
                          <b-col cols="3" v-if="!is_paramType('vmc')">
                            <b-form-input :id="'params_value'" :list="'my-list-pt-desenfumage'" v-model="currentParams.lidic" @input="updateLidicParams" @blur="clearLidicBouche"></b-form-input>
                          </b-col>
                          <b-col cols="2">
                              <label :for="'params_label'">Nombre: </label>
                          </b-col>
                          <b-col cols="3">
                            <b-form-input :id="'params_label'" v-model="currentParams.number"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="is_paramType('ventilationhaute_rdc')">
                          <b-col>
                            <b-row v-for="(opt,index) in currentParams.items" :key="index">
                              <b-col cols="2">
                                <b-form-select v-model="currentParams.items[index].type" :options="param_item_type" id="param_item_type" @change="updateItemParams(index)"></b-form-select>
                              </b-col>
                              <b-col cols="1" v-if="isparamItemType(index,'cadre')">
                                  <label :for="'params_change_'+index">Remplacer: </label>
                              </b-col>
                              <b-col cols="2" v-if="isparamItemType(index,'cadre')">
                                <b-form-input :id="'params_change_'+index" :list="'my-list-pt-desenfumage'" v-model="currentParams.items[index].change_lidic" @input="updateLidicItemChangeParams(index)" @blur="clearLidicBouche"></b-form-input>
                              </b-col>
                              <b-col cols="1">
                                  <label :for="'params_value_'+index">Produit: </label>
                              </b-col>
                              <b-col cols="2">
                                <b-form-input :id="'params_value_'+index" :list="'my-list-pt-desenfumage'" v-model="currentParams.items[index].lidic" @input="updateLidicItemParams(index)" @blur="clearLidicBouche"></b-form-input>
                              </b-col>
                              <b-col cols="1">
                                  <label :for="'params_label_'+index">Nombre: </label>
                              </b-col>
                              <b-col cols="2">
                                <b-form-input :id="'params_label_'+index" v-model="currentParams.items[index].number"></b-form-input>
                              </b-col>
                              <b-col cols="1" v-if="isparamItemType(index,'cadre')">
                                <b-button size="sm" target="_blank" @click="remove_item_params(index)" class="mr-1 squarebutton">-</b-button>
                              </b-col>
                              <b-col offset="3" cols="1" v-if="!isparamItemType(index,'cadre')">
                                <b-button size="sm" target="_blank" @click="remove_item_params(index)" class="mr-1 squarebutton">-</b-button>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col offset="11" cols="1">
                                <b-button size="sm" target="_blank" @click="add_item_params()" class="mr-1 squarebutton">+</b-button>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-button size="sm" target="_blank" @click="close('params')" class="mr-1">Fermer</b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                      </b-row>
                    </div>
                  </div>
                  <b-row align-h="end">
                    <b-col cols="2">
                      <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_params')" class="mr-1">Ajouter Paramètre</b-button>
                    </b-col>
                  </b-row>
                    <b-table responsive striped hover :items="currentVMC.params.items" :fields="fields_params">
                      <template #cell(actions)="row">
                        <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_params')" class="mr-1">Editer</b-button>
                        <b-button size="sm" target="_blank" @click="deleteItem(row.index,'individuel_params')" class="mr-1">Supprimer</b-button>
                      </template>
                    </b-table>
                  </b-tab>
                  <b-tab title="Config">
                    <div class="content-block shadow-box" v-if="editConfig">
                      <div class='header-form'>
                        <p><span>Configuration: {{currentConfigLabel}}</span><br></p>
                      </div>
                      <div class="content-form roundButton">
                        <b-row>
                          <b-col>
                            <b-row>
                              <b-col cols="2">
                                <label :for="'config_label'">Label: </label>
                              </b-col>
                              <b-col cols="3">
                                <b-form-input :id="'config_label'" v-model="tmpConfigLabel"></b-form-input>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row v-for="(value, propertyName, index) in currentConfig" :key="propertyName">
                          <b-col cols="2">
                              <label :for="'config_value_'+index">{{propertyName}}: </label>
                          </b-col>
                          <b-col cols="3">
                            <b-form-input :id="'config_value_'+index" v-model.number="currentConfig[propertyName]" type="number"></b-form-input>
                          </b-col>
                          <b-col cols="2">
                            <b-button size="sm" target="_blank" @click="remove_item_config(propertyName)" class="mr-1 squarebutton">-</b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="2">
                              <label :for="'config_value_-1'">Nouvel Config: </label>
                          </b-col>
                          <b-col cols="3">
                            <b-form-input :id="'config_value_-1'" v-model="newConfigParam"></b-form-input>
                          </b-col>
                          <b-col cols="2">
                            <b-button size="sm" target="_blank" @click="add_item_config()" class="mr-1 squarebutton">+</b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-button size="sm" target="_blank" @click="close('config')" class="mr-1">Fermer</b-button>
                          </b-col>
                        </b-row>
                    </div>
                  </div>
                  <b-row align-h="end">
                    <b-col cols="2">
                      <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_config')" class="mr-1">Ajouter Config</b-button>
                    </b-col>
                  </b-row>
                    <b-table responsive striped hover :items="getCurrentConfig" :fields="fields_config">
                      <template #cell(actions)="row">
                        <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_config')" class="mr-1">Editer</b-button>
                        <b-button size="sm" target="_blank" @click="deleteItem(row.item,'individuel_config')" class="mr-1">Supprimer</b-button>
                      </template>
                    </b-table>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="cancel('desenfumage')" class="mr-1">Annuler</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="saveItem()" class="mr-1">Enregistrer</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
  </b-row>
      <b-row  v-if="!editVMC">
        <b-col cols="12" md="12" lg="12">
          <div class="content-block shadow-box">
            <div class='header-form'>
              <p><span>Volets</span><br></p>
            </div>
            <div class="content-form roundButton">
              <b-alert
                :show="dismissCountDown"
                dismissible
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
              >
                <p>{{alertMsg}}</p>
              </b-alert>
      <b-row align-h="end">
        <b-col cols="2">
          <b-button size="sm" target="_blank" @click="editItem(-1,'desenfumage')" class="mr-1">Ajouter Volet</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="2">
          <b-form-group label="Filtrer" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Rechercher par LIDIC, Nom, Type,..."></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table responsive striped hover :items="desenfumage" :fields="fields" :filter="filter" sort-by="order_by" id="my-table" :per-page="perPage" :current-page="currentPage" :tbody-tr-class="rowClass">
      <template #cell(vignette)="row">
        <b-img :src="`/img/${row.item.lidic}.jpg`"/>
      </template>
        <template #cell(actions)="row">
          <b-button size="sm" target="_blank" @click="editItem(row.item,'desenfumage')" class="mr-1">Editer</b-button>
          <b-button size="sm" target="_blank" @click="copyItem(row.item,'desenfumage')" class="mr-1">Copier</b-button>
          <b-button size="sm" target="_blank" @click="deleteItem(row.item,'desenfumage')" class="mr-1">Supprimer</b-button>
          <b-button size="sm" target="_blank" @click="moveUp(row.item,'desenfumage')" class="mr-1" v-if="row.item.order_by>0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
</svg></b-button>
          <b-button size="sm" target="_blank" @click="moveDown(row.item,'desenfumage')" class="mr-1" v-if="row.item.order_by<(desenfumage.length-1)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
</svg></b-button>
        </template>
      </b-table>
      </div></div>
    </b-col>
    </b-row>
    <datalist id="my-list-pt-desenfumage">
        <option :value="prod.value" v-for="(prod,index3) in lidic_params" :key="index3">{{ prod.text }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'DesenfumageAdmin',
  props: {
    desenfumage: Array,
    product: Array,
  },
  data() {
      return {
        dismissCountDown: 0,
        alertMsg: '',
        currentVMC:{},
        currentVMCLidic: -1,
        currentOptions:{},
        currentParams:{},
        currentConfig:{},
        newConfig: 'Nouveau Label',
        currentGaine:{},
        currentGaineLidic: null,
        tmpGaineLidic: null,
        currentConfigLabel: null,
        tmpConfigLabel: null,
        lidic_options:[],
        lidic_params:[],
        lidic_product:[],
        newConfigParam: '',
        editVMC:false,
        editOptions:false,
        editParams:false,
        editConfig:false,
        filter: null,
        perPage: 20,
        currentPage: 1,
        fields_config: [
          {
            key: 'label',
            label: 'label',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_params: [
          {
            key: 'type',
            label: 'Type',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_options: [
          {
            key: 'libelle',
            label: 'Libelle',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields: [
          { key: 'vignette', label: '',tdClass: 'product-vignette' },
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          {
            key: 'nom',
            label: 'Nom',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        param_type:[{value:'vmc',text:"VMC"},
                    {value:'precadre',text:"PréCadre"},
                    {value:'ventilationhaute_rdc','text':'Ventilation Haute RDC'},
                   {value:'antichute','text':"AntiChute"}],
        param_item_type:[{value:'',text:'Type'},
      {value:'volet',text:'Volet'},
      {value:'cadre',text:'Cadre'}],
        status:[{value:'valid',text:'Validé'},{value:'error',text:'Erreur Paramétrage'},{value:'waiting',text:'En attente de validation'}],
      }
  },
  methods: {
    countDownChanged(dismisscd){
      this.dismissCountDown = dismisscd;
    },
    moveUp(item){
      var baseURI = this.$api_url+'vmc';
      this.currentVMC = item;
      this.currentVMCLidic = item.lidic;
      var current_order = this.currentVMC.order_by;
      var old_vmc_index = this.desenfumage.findIndex((item2) => { return item2.order_by == (current_order-1); });
      this.currentVMC.order_by--;
      this.$http.put(baseURI,{vmc: this.currentVMC,lidic: this.currentVMCLidic, type:'desenfumage'})
      .then((result) => {
        console.log(result);
        if(result){
          var index = this.desenfumage.findIndex((item3) => { return item3.lidic == this.currentVMCLidic; });
          this.$set(this.desenfumage,index,this.currentVMC);

          this.currentVMC = this.desenfumage[old_vmc_index];
          this.currentVMCLidic = this.desenfumage[old_vmc_index].lidic;
          this.currentVMC.order_by++;
          this.$http.put(baseURI,{vmc: this.currentVMC,lidic:this.currentVMCLidic, type:'desenfumage'}).then((result) => {
            console.log(result);
            if(result){
              var index = this.desenfumage.findIndex((item3) => { return item3.lidic == this.currentVMCLidic; });
              this.$set(this.desenfumage,index,this.currentVMC);
            }});
      }});


    },
    moveDown(item){
      var baseURI = this.$api_url+'vmc';
      this.currentVMC = item;
      this.currentVMCLidic = item.lidic;
      var current_order = this.currentVMC.order_by;
      var old_vmc_index = this.desenfumage.findIndex((item2) => { return item2.order_by == (current_order+1); });
      this.currentVMC.order_by++;
      this.$http.put(baseURI,{vmc: this.currentVMC,lidic: this.currentVMCLidic, type:'desenfumage'})
      .then((result) => {
        console.log(result);
        if(result){
          var index = this.desenfumage.findIndex((item) => { return item.lidic == this.currentVMCLidic; });
          this.$set(this.desenfumage,index,this.currentVMC);

          this.currentVMC = this.desenfumage[old_vmc_index];
          this.currentVMCLidic = this.desenfumage[old_vmc_index].lidic;
          this.currentVMC.order_by--;
          this.$http.put(baseURI,{vmc: this.currentVMC,lidic: this.currentVMCLidic, type:'desenfumage'}).then((result) => {
            console.log(result);
            if(result){
              var index = this.desenfumage.findIndex((item3) => { return item3.lidic == this.currentVMCLidic; });
              this.$set(this.desenfumage,index,this.currentVMC);
            }});
      }});
    },
    updateParams(){
      switch(this.currentParams.type){
        case 'ventilationhaute_rdc':
          this.$set(this.currentParams,'items',[]);
        break;
        default:
          this.$delete(this.currentParams,'items');
        break;
      }
      console.log(this.currentParams);
    },
    updateItemParams(index){
        switch(this.currentParams.items[index].type){
          case 'volet':
            this.$delete(this.currentParams.items[index],'change_lidic');
          break;
          case 'cadre':
            this.$set(this.currentParams.items[index],'change_lidic','');
          break;
        }
    },
    add_item_options(){
      this.currentOptions.content.items.push({lidic:'',number:'1'});
    },
    add_item_options_image(){
      if(this.currentOptions.content.images==undefined) this.$set(this.currentOptions.content,'images',[]);
      this.currentOptions.content.images.push({name:'',title:'',text:''});
      this.$forceUpdate();
    },
    remove_item_options_image(index){
      this.currentOptions.content.images.splice(index,1);
      this.$forceUpdate();
    },
    remove_item_options(index){
      this.currentOptions.content.items.splice(index,1);
    },
    remove_item_params(index){
      this.currentParams.items.splice(index,1);
    },
    add_item_params(){
      this.currentParams.items.push({lidic:'',number:1,type:''});
    },
    remove_item_config(propertyName){
      delete this.currentConfig[propertyName];
      this.$forceUpdate();
    },
    add_item_config(){
      if(this.newConfigParam!=""){
        this.$set(this.currentConfig,this.newConfigParam,0);
        this.newConfigParam = '';
      }
    },
    is_paramType(type){
      return this.currentParams.type==type;
    },
    isparamItemType(index,type){
      return this.currentParams.items[index].type==type;
    },
    close(type){
      switch(type){
        case 'options':
          console.log(this.currentOptions);
          console.log(this.currentVMC);
          this.lidic_options = [];
          this.editOptions=false;
        break;
        case 'params':
          this.lidic_params = [];
          this.editParams=false;
          console.log(this.currentParams);
          console.log(this.currentVMC);
        break;
        case 'config':
          this.editConfig=false;
          if(this.currentConfigLabel!=this.tmpConfigLabel && this.currentConfigLabel!='Nouveau Label'){
            this.$delete(this.currentVMC.params.config, this.currentConfigLabel);
          }
          this.$set(this.currentVMC.params.config,this.tmpConfigLabel,this.currentConfig);
          console.log(this.currentConfig);
          console.log(this.currentVMC);
        break;
      }
    },
    copyItem(item,type){
      switch(type){
        case 'desenfumage':
          this.currentVMC = JSON.parse(JSON.stringify(item));
          this.currentVMC.lidic=null;
          this.currentVMC.status= "waiting",
          this.currentVMCLidic = -1;
          this.editVMC = true;
        break;
      }
    },
    editItem(item,type){
      switch(type){
        case 'desenfumage':
          if(item==-1){
            this.currentVMC = {
              lidic: null,
              options: [],
              params: {
                items:[],
                config:{}
              },
              status: "waiting",
            };
            this.currentVMCLidic = item;
          }else{
            this.currentVMC = item;
            this.currentVMCLidic = item.lidic;
          }
          this.editVMC = true;
        break;
        case 'individuel_option':

          if(item==-1){
            this.currentVMC.options.push({content:{number:1,items:[],images:[],type: "checkbox"},libelle:''});
            this.currentOptions = this.currentVMC.options[this.currentVMC.options.length - 1];
          }else{
            this.currentOptions = item;
          }
          this.editOptions = true;
        break;
        case 'individuel_params':
          if(item==-1){
            this.currentVMC.params.items.push({type:''});
            this.currentParams = this.currentVMC.params.items[this.currentVMC.params.items.length - 1];
          }else{
            this.currentParams = item;
          }
          this.editParams = true;
        break;
        case 'individuel_config':
          if(item==-1){
            this.currentConfig = {};
            this.currentConfigLabel = 'Nouveau label';
            this.tmpConfigLabel = 'Nouveau label';
          }else{
            this.currentConfig = this.currentVMC.params.config[item.label];
            this.currentConfigLabel = item.label;
            this.tmpConfigLabel = this.currentConfigLabel;
          }
          this.editConfig = true;
          console.log(this.currentConfig);
        break;
      }
    },
    deleteItem(item,type){
      switch(type){
        case 'desenfumage':
          this.$http.delete(`${this.$api_url}vmc/${type}/${item.lidic}`).then((result) => {
            console.log(result);
            if(result){
              var index = this.desenfumage.findIndex((vmc) => { return item.lidic == vmc.lidic; });
              if(index>-1) this.desenfumage.splice(index,1);
              this.alertMsg = 'Volet supprimé avec succès.';
              this.dismissCountDown = 5;
            }else{
              console.log(result);
            }
          });
        break;
        case 'individuel_option':
          this.currentVMC.options.splice(item,1);
        break;
        case 'individuel_params':
          this.currentVMC.params.items.splice(item,1);
        break;
        case 'individuel_config':
          this.$delete(this.currentVMC.params.config, item.label);
        break;
      }
    },
    cancel(type){
      switch(type){
        case 'desenfumage':
          this.editVMC = false;
          this.currentVMC = {};
        break;
      }
    },
    saveItem(){
      console.log(this.currentVMC);
      var baseURI = this.$api_url+'vmc';
      if(this.currentVMCLidic==-1){
        this.currentVMC.order_by = this.desenfumage.length;
        this.$http.post(baseURI,{vmc: this.currentVMC, type:'desenfumage'})
        .then((result) => {
          console.log(result);
          if(result){
            this.desenfumage.push(this.currentVMC);
            this.currentVMC = {};
            this.editVMC = false;
            this.alertMsg = 'Volet enregistré avec succès.';
            this.dismissCountDown = 5;
          }else{
            console.log(result);
          }
        });
      }else{
        this.$http.put(baseURI,{vmc: this.currentVMC,lidic: this.currentVMCLidic, type:'desenfumage'})
        .then((result) => {
          console.log(result);
          if(result){
            var index = this.desenfumage.findIndex((item) => { return item.lidic == this.currentVMCLidic; });
            this.$set(this.desenfumage,index,this.currentVMC);
            this.currentVMC = {};
            this.editVMC = false;
            this.alertMsg = 'Volet enregistré avec succès.';
            this.dismissCountDown = 5;
          }else{
            console.log(result);
          }
        });
      }
    },
    rowClass(item,type){
      if (item && type === 'row') {
        if(item.status=='valid'){
          return 'validation_success';
        }else if(item.status=='error'){
          return 'validation_error';
        }else if(item.status=="waiting"){
          return 'waiting_validation';
        }
      }else{
        return null;
      }
    },
    updateVMC(){
      if(this.currentVMC.lidic!=""){
        let index = this.product.findIndex((item)=> {
          return item.lidic == this.currentVMC.lidic});
        if(index!=1){
          this.currentVMC.nom = this.product[index].nom;
          this.currentVMC.designation = this.product[index].designation;
          this.currentVMC.prix_unitaire = this.product[index].prix_unitaire;
          this.currentVMC.debit_max = this.product[index].debit_max;
        }
        console.log(this.currentVMC);
      }
    },
    updateLidicOptions(index){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentOptions.content.items[index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentOptions.content.items[index].lidic) || item.nom.toString().includes(this.currentOptions.content.items[index].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    clearLidicBouche(){
      this.lidic_params = [];
    },
    updateLidicParams(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.lidic) || item.nom.toString().includes(this.currentParams.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicItemParams(index){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.items[index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.items[index].lidic) || item.nom.toString().includes(this.currentParams.items[index].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicItemChangeParams(index){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.items[index].change_lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.items[index].change_lidic) || item.nom.toString().includes(this.currentParams.items[index].change_lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicProduct(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentVMC.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentVMC.lidic) || item.nom.toString().includes(this.currentVMC.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_product = res;
      return res;
    }
  },
  computed: {
    getCurrentConfig(){
      let res = [];
      Object.keys(this.currentVMC.params.config).forEach((item)=>{
        console.log(item);
        res.push({label:item});
      });
      return res;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
