<template>
  <div id="individuel_admin">
    <b-row  v-if="editVMC">
      <b-col cols="12" md="12" lg="12">
        <div class="content-block shadow-box">
          <div class='header-form'>
            <p><span>{{currentVMC.nom}}</span><br></p>
          </div>
          <div class="content-form roundButton">
            <b-row>
              <b-col cols="2">
                <label for="vmc_input">VMC: </label>
              </b-col>
              <b-col>
              <b-form-input id="vmc_input" list="my-list-id" v-model="currentVMC.lidic" @blur="updateVMC" @input="updateLidicProduct()"></b-form-input>
              <datalist id="my-list-id">
                  <option :value="prod.value" v-for="(prod,index) in lidic_product" :key="index">{{ prod.text }}</option>
              </datalist>
            </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="typologie_input">Typologie Min.: </label>
              </b-col>
              <b-col>
                <b-form-select v-model="currentVMC.typologie_min" :options="typologie" id="typologie_input"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="typologie_input">Typologie Max: </label>
              </b-col>
              <b-col>
                <b-form-select v-model="currentVMC.typologie" :options="typologie" id="typologie_input"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="reglementation_input">Réglementation: </label>
              </b-col>
              <b-col>
                <b-form-select v-model="currentVMC.reglementation" :options="reglementation" id="reglementation_input"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="categorie_input">Type de Flux: </label>
              </b-col>
              <b-col>
                <b-form-select v-model="currentVMC.categorie" :options="flux" id="categorie_input"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="debit_input">Débit max(m3/h): </label>
              </b-col>
              <b-col>
                <b-form-input id="debit_input" v-model="currentVMC.debit_max"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="nb_piece_technique_input">Nb Pièce Technique: </label>
              </b-col>
              <b-col>
                <b-form-spinbutton inline id="nb_piece_technique_input" v-model="currentVMC.piece_technique" min="1" size="sm"></b-form-spinbutton>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="statut_input">Status: </label>
              </b-col>
              <b-col>
                <b-form-select v-model="currentVMC.status" :options="status" id="statut_input"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="featured">Mis en avant: </label>
              </b-col>
              <b-col>
                <b-form-checkbox
                  id="featured"
                  v-model="currentVMC.featured"
                  name="featured"
                >
                  Mettre en avant le produit
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="currentVMC.featured">
              <b-col cols="2">
                <label for="slug_featured">Libellé Mise en avant: </label>
              </b-col>
              <b-col>
                <b-form-input id="slug_featured" v-model="currentVMC.feature_slug"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-tabs pills>
                  <b-tab title="Options" active>
                    <div class="content-block shadow-box" v-if="editOptions">
                      <div class='header-form'>
                        <p><span>Options</span><br></p>
                      </div>
                      <div class="content-form roundButton">
                        <b-row>
                        <b-col>
                        <b-row>
                          <b-col cols="2">
                              <label for="options_libelle">Libelle: </label>
                            </b-col>
                            <b-col>
                            <b-form-input id="options_libelle" v-model="currentOptions.libelle"></b-form-input>
                          </b-col>
                        </b-row>
                        <h4>Options</h4>
                        <b-row>
                          <b-col cols="3">
                            <b-form-checkbox
                                id="options-require"
                                v-model="currentOptions.content.optionnal"
                                name="options-require"
                              >
                                En Option
                              </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row v-for="(opt,index) in currentOptions.content.options" :key="index">
                          <b-col cols="2">
                              <label :for="'options_label_'+index">Label: </label>
                            </b-col>
                            <b-col cols="3">
                            <b-form-input :id="'options_label_'+index" v-model="opt.text"></b-form-input>
                          </b-col>
                          <b-col cols="2">
                              <label :for="'options_value_'+index">Produit: </label>
                            </b-col>
                            <b-col cols="3">
                            <b-form-input :id="'options_value_'+index" :list="'my-list-pt'" v-model="currentOptions.content.options[index].value" @input="updateLidicOptions(index)" @blur="clearLidicBouche"></b-form-input>
                          </b-col>
                          <b-col cols="2">
                            <b-button size="sm" target="_blank" @click="remove_item_options(index)" class="mr-1 squarebutton">-</b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col offset="10" cols="2">
                            <b-button size="sm" target="_blank" @click="add_item_options()" class="mr-1 squarebutton">+</b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-button size="sm" target="_blank" @click="close('options')" class="mr-1">Fermer</b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                      </b-row>
                    </div>
                  </div>
                  <b-row align-h="end">
                    <b-col cols="2">
                      <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_option')" class="mr-1">Ajouter Option</b-button>
                    </b-col>
                  </b-row>
                    <b-table responsive striped hover :items="currentVMC.options" :fields="fields_options">
                      <template #cell(actions)="row">
                        <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_option')" class="mr-1">Editer</b-button>
                        <b-button size="sm" target="_blank" @click="deleteItem(row.index,'individuel_option')" class="mr-1">Supprimer</b-button>
                        <b-button size="sm" target="_blank" @click="moveUp(row.index,'individuel_option')" class="mr-1" v-if="row.index>0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
              </svg></b-button>
                        <b-button size="sm" target="_blank" @click="moveDown(row.index,'individuel_option')" class="mr-1" v-if="row.index<(currentVMC.options.length-1)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
              </svg></b-button>
                      </template>
                    </b-table>
                  </b-tab>
                  <b-tab title="Paramètre">
                    <div class="content-block shadow-box" v-if="editParams">
                      <div class='header-form'>
                        <p><span>Paramètre</span><br></p>
                      </div>
                      <div class="content-form roundButton">
                        <b-row>
                        <b-col>
                          <b-row>
                            <b-col cols="2">
                              <label for="param_type">Type: </label>
                            </b-col>
                            <b-col>
                              <b-form-select v-model="currentParams.type" :options="param_type" id="param_type" @change="updateParams"></b-form-select>
                            </b-col>
                          </b-row>
                        <b-row v-if="is_paramType('insufflation')">
                          <b-col>
                            <b-row v-for="(bouche,index) in typologie_range" :key="index">
                              <b-col>
                                <b-row><b-col><h6>T{{bouche}}</h6></b-col></b-row>
                                <b-row v-for="(bouche_type,index2) in currentParams[bouche]" :key="index2" >
                                  <b-col cols="2">
                                    <label :for="'insufflation_value_'+index+'_'+index2">Produit: </label>
                                  </b-col>
                                  <b-col cols="3">
                                    <b-form-input :id="'insufflation_value_'+index+'_'+index2" list="my-list-pt" v-model="currentParams[bouche][index2].lidic" @input="updateLidicBouche(bouche,index2)" @blur="clearLidicBouche"></b-form-input>
                                  </b-col>
                                  <b-col cols="2">
                                      <label :for="'options_label_'+index+'_'+index2">Nombre: </label>
                                  </b-col>
                                  <b-col cols="3">
                                    <b-form-spinbutton inline :id="'options_label_'+index+'_'+index2" v-model="currentParams[bouche][index2].number" min="0" size="sm"></b-form-spinbutton>
                                  </b-col>
                                  <b-col cols="2">
                                    <b-button size="sm" target="_blank" @click="remove_item_bouche(bouche,index2)" class="mr-1 squarebutton">-</b-button>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col offset="10" cols="2">
                                    <b-button size="sm" target="_blank" @click="add_item_bouche(bouche)" class="mr-1 squarebutton">+</b-button>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row v-if="is_paramType('piece_technique')">
                          <b-col>
                            <b-row v-for="(piece_technique,index) in piece_technique_range" :key="index">
                              <b-col>
                                <b-row><b-col><h6>{{piece_technique.text}}</h6></b-col></b-row>
                                <b-row v-for="i in 3" :key="i">
                                  <b-col>
                                    <b-row><b-col>Nombre: {{i}}</b-col></b-row>
                                    <b-row v-for="(pt_row,index2) in currentParams[piece_technique.value].items[i]" :key="index2" >
                                      <b-col cols="1">
                                        <label :for="'pt_value_'+piece_technique.value+'_'+i+'_'+index2">Produit: </label>
                                      </b-col>
                                      <b-col cols="2">
                                        <b-form-input :id="'pt_value_'+piece_technique.value+'_'+i+'_'+index2" list="my-list-pt" v-model="currentParams[piece_technique.value].items[i][index2].lidic" @input="updateLidicPT(piece_technique.value,i,index2)" @blur="clearLidicBouche"></b-form-input>

                                      </b-col>
                                      <b-col cols="1">
                                          <label :for="'options_label_'+piece_technique.value+'_'+i+'_'+index2">Nombre: </label>
                                      </b-col>
                                      <b-col cols="2">
                                        <b-form-spinbutton inline :id="'options_label_'+piece_technique.value+'_'+i+'_'+index2" v-model="currentParams[piece_technique.value].items[i][index2].number" min="0" size="sm"></b-form-spinbutton>
                                      </b-col>
                                      <b-col cols="1">
                                          <label :for="'options_label_offered_'+piece_technique.value+'_'+i+'_'+index2">Offert: </label>
                                      </b-col>
                                      <b-col cols="2">
                                        <b-form-spinbutton inline :id="'options_label_offered_'+piece_technique.value+'_'+i+'_'+index2" v-model="currentParams[piece_technique.value].items[i][index2].offered" min="0" size="sm"></b-form-spinbutton>
                                      </b-col>
                                      <b-col cols="3">
                                        <b-button size="sm" target="_blank" @click="remove_item_pt(piece_technique.value,i,index2)" class="mr-1 squarebutton">-</b-button>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col offset="9" cols="3">
                                        <b-button size="sm" target="_blank" @click="add_item_pt(piece_technique.value,i)" class="mr-1 squarebutton">+</b-button>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-col cols="2">
                                  <label :for="'options_offered_'">Offert (contenu package): </label>
                                </b-col>
                                <b-col cols="2">
                                  <b-form-spinbutton inline :id="'options_offered_'" v-model="currentParams.offered" min="0" size="sm"></b-form-spinbutton>
                                </b-col>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-col cols="2">
                                  <label :for="'options_piquage_'">Piquage (contenu package): </label>
                                </b-col>
                                <b-col cols="2">
                                  <b-form-spinbutton inline :id="'options_piquage_'" v-model="currentParams.piquage" min="0" size="sm"></b-form-spinbutton>
                                </b-col>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row v-if="is_paramType('gaines')">
                          <b-col>
                          <div class="content-block shadow-box" v-if="editGaines">
                            <div class='header-form'>
                              <p><span>Gaines: {{currentGaineLidic}}</span><br></p>
                            </div>
                            <div class="content-form roundButton">
                              <b-row>
                                <b-col>
                                  <b-row>
                                    <b-col cols="2">
                                      <label :for="'gaines_prod'">Produit: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'gaines_prod'" list="my-list-pt" v-model="tmpGaineLidic" @input="updateLidicGaine()" @blur="clearLidicBouche"></b-form-input>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col>
                                  <b-col cols="2">
                                    <label :for="'gaines_longueur'">Longueur Gaine: </label>
                                  </b-col>
                                  <b-col cols="3">
                                    <b-form-spinbutton inline :id="'gaines_longueur'" v-model="currentGaine.longueur" min="0" size="sm"></b-form-spinbutton>
                                  </b-col>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <b-tabs>
                                    <b-tab title="Nb Etage: 0">
                                      <b-row v-for="(prod,index) in currentGaine.items[0]" :key="index">
                                        <b-col cols="2">
                                          <label :for="'prod0_value_'+index">Produit: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'prod0_value_'+index" list="my-list-pt" v-model="currentGaine.items[0][index].lidic" @input="updateLidicGaineProd(0,index)" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                            <label :for="'prod0_label_'+index">Nombre: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input inline :id="'prod0_label_'+index" v-model="currentGaine.items[0][index].number" min="0" size="sm"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_gaine(0,index)" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_gaine(0,0)" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-tab>
                                    <b-tab title="Nb Etage: 1">
                                      <b-row v-for="(prod,index) in currentGaine.items[1]" :key="index">
                                        <b-col cols="2">
                                          <label :for="'prod1_value_'+index">Produit: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'prod1_value_'+index" list="my-list-pt" v-model="currentGaine.items[1][index].lidic" @input="updateLidicGaineProd(1,index)" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                            <label :for="'prod1_label_'+index">Nombre: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input inline :id="'prod1_label_'+index" v-model="currentGaine.items[1][index].number" min="0" size="sm"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_gaine(1,index)" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_gaine(1,1)" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-tab>
                                  </b-tabs>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <b-button size="sm" target="_blank" @click="close('gaines')" class="mr-1">Fermer</b-button>
                                </b-col>
                              </b-row>
                            </div>
                          </div>
                          <b-row align-h="end">
                            <b-col cols="2">
                              <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_gaines')" class="mr-1">Ajouter Gaines</b-button>
                            </b-col>
                          </b-row>
                          <b-table responsive striped hover :items="get_gaines_params" :fields="fields_gaines">
                            <template #cell(actions)="row">
                              <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_gaines')" class="mr-1">Editer</b-button>
                              <b-button size="sm" target="_blank" @click="deleteItem(row.item,'individuel_gaines')" class="mr-1">Supprimer</b-button>
                            </template>
                          </b-table>
                        </b-col>
                        </b-row>
                        <b-row v-if="is_paramType('entree')">
                          <b-col v-if="currentParams['entree']">
                            <b-tabs>
                            <b-tab title="Entrée d'air">
                              <b-row v-for="(bouche,index) in typologie_range" :key="index">
                                <b-col>
                                  <b-row><b-col><h6>T{{bouche}}</h6></b-col></b-row>
                                  <b-row v-for="(bouche_type,index2) in currentParams['entree'][bouche]" :key="index2" >
                                    <b-col cols="2">
                                      <label :for="'insufflation_value_'+index+'_'+index2">Produit: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'insufflation_value_'+index+'_'+index2" list="my-list-pt" v-model="currentParams['entree'][bouche][index2].lidic" @input="updateLidicEntree(false,bouche,index2)" @blur="clearLidicBouche"></b-form-input>
                                    </b-col>
                                    <b-col cols="2">
                                        <label :for="'options_label_'+index+'_'+index2">Nombre: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-spinbutton inline :id="'options_label_'+index+'_'+index2" v-model="currentParams['entree'][bouche][index2].number" min="0" size="sm"></b-form-spinbutton>
                                    </b-col>
                                    <b-col cols="2">
                                      <b-button size="sm" target="_blank" @click="remove_item_entree(false,bouche,index2)" class="mr-1 squarebutton">-</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col offset="10" cols="2">
                                      <b-button size="sm" target="_blank" @click="add_item_entree(false,bouche)" class="mr-1 squarebutton">+</b-button>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-tab>
                            <b-tab title="Entrée d'air avec Rallonge">
                              <b-row v-for="(bouche,index) in typologie_range" :key="index">
                                <b-col>
                                  <b-row><b-col><h6>T{{bouche}}</h6></b-col></b-row>
                                  <b-row v-for="(bouche_type,index2) in currentParams['rallonge'][bouche]" :key="index2" >
                                    <b-col cols="2">
                                      <label :for="'insufflation_value_'+index+'_'+index2">Produit: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'insufflation_value_'+index+'_'+index2" list="my-list-pt" v-model="currentParams['rallonge'][bouche][index2].lidic" @input="updateLidicEntree(true,bouche,index2)" @blur="clearLidicBouche"></b-form-input>
                                    </b-col>
                                    <b-col cols="2">
                                        <label :for="'options_label_'+index+'_'+index2">Nombre: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-spinbutton inline :id="'options_label_'+index+'_'+index2" v-model="currentParams['rallonge'][bouche][index2].number" min="0" size="sm"></b-form-spinbutton>
                                    </b-col>
                                    <b-col cols="2">
                                      <b-button size="sm" target="_blank" @click="remove_item_entree(true,bouche,index2)" class="mr-1 squarebutton">-</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col offset="10" cols="2">
                                      <b-button size="sm" target="_blank" @click="add_item_entree(true,bouche)" class="mr-1 squarebutton">+</b-button>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-tab>
                          </b-tabs>
                          </b-col>
                        </b-row>
                        <b-row v-if="is_paramType('silent')">
                          <b-col cols="1">
                              <label :for="'silent_condition_'">Condition: </label>
                          </b-col>
                          <b-col cols="2">
                            <b-form-spinbutton inline :id="'silent_condition_'" v-model="currentParams.condition" min="0" size="sm"></b-form-spinbutton>
                          </b-col>
                          <b-col cols="1">
                            <label :for="'silent_value_'">Produit: </label>
                          </b-col>
                          <b-col cols="2">
                            <b-form-input :id="'silent_value_'" list="my-list-pt" v-model="currentParams.lidic" @input="updateLidicParams()" @blur="clearLidicBouche"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-button size="sm" target="_blank" @click="close('params')" class="mr-1">Fermer</b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                      </b-row>
                    </div>
                  </div>
                  <b-row align-h="end">
                    <b-col cols="2">
                      <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_params')" class="mr-1">Ajouter Paramètre</b-button>
                    </b-col>
                  </b-row>
                    <b-table responsive striped hover :items="currentVMC.params.items" :fields="fields_params">
                      <template #cell(actions)="row">
                        <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_params')" class="mr-1">Editer</b-button>
                        <b-button size="sm" target="_blank" @click="deleteItem(row.index,'individuel_params')" class="mr-1">Supprimer</b-button>
                      </template>
                    </b-table>
                  </b-tab>
                  <b-tab title="Config">
                    <div class="content-block shadow-box" v-if="editConfig">
                      <div class='header-form'>
                        <p><span>Configuration: {{currentConfigLabel}}</span><br></p>
                      </div>
                      <div class="content-form roundButton">
                        <b-row>
                          <b-col>
                            <b-row>
                              <b-col cols="2">
                                <label :for="'config_label'">Label: </label>
                              </b-col>
                              <b-col cols="3">
                                <b-form-input :id="'config_label'" v-model="tmpConfigLabel"></b-form-input>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row v-for="(value, propertyName, index) in currentConfig" :key="propertyName">
                          <b-col cols="2">
                              <label :for="'config_value_'+index">{{propertyName}}: </label>
                          </b-col>
                          <b-col cols="3">
                            <b-form-input :id="'config_value_'+index" v-model.number="currentConfig[propertyName]" type="number"></b-form-input>
                          </b-col>
                          <b-col cols="2">
                            <b-button size="sm" target="_blank" @click="remove_item_config(propertyName)" class="mr-1 squarebutton">-</b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="2">
                              <label :for="'config_value_-1'">Nouvel Config: </label>
                          </b-col>
                          <b-col cols="3">
                            <b-form-input :id="'config_value_-1'" v-model="newConfigParam"></b-form-input>
                          </b-col>
                          <b-col cols="2">
                            <b-button size="sm" target="_blank" @click="add_item_config()" class="mr-1 squarebutton">+</b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-button size="sm" target="_blank" @click="close('config')" class="mr-1">Fermer</b-button>
                          </b-col>
                        </b-row>
                    </div>
                  </div>
                  <b-row align-h="end">
                    <b-col cols="2">
                      <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_config')" class="mr-1">Ajouter Config</b-button>
                    </b-col>
                  </b-row>
                    <b-table responsive striped hover :items="getCurrentConfig" :fields="fields_config">
                      <template #cell(actions)="row">
                        <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_config')" class="mr-1">Editer</b-button>
                        <b-button size="sm" target="_blank" @click="deleteItem(row.item,'individuel_config')" class="mr-1">Supprimer</b-button>
                      </template>
                    </b-table>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="cancel('individuel')" class="mr-1">Annuler</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="saveItem('individuel')" class="mr-1">Enregistrer</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
  </b-row>
      <b-row  v-if="!editVMC">
        <b-col cols="12" md="12" lg="12">
          <div class="content-block shadow-box">
            <div class='header-form'>
              <p><span>Habitat Individuel: VMC</span><br></p>
            </div>
            <div class="content-form roundButton">
              <b-alert
                :show="dismissCountDown"
                dismissible
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
              >
                <p>VMC enregistré avec succès.</p>
              </b-alert>
      <b-row align-h="end">
        <b-col cols="2">
          <b-button size="sm" target="_blank" @click="editItem(-1,'individuel')" class="mr-1">Ajouter VMC</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="2">
          <b-form-group label="Filtrer" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Rechercher par LIDIC, Nom, Type,..."></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table responsive striped hover :items="individuel" :fields="fields" :filter="filter" id="my-table" :per-page="perPage" :current-page="currentPage" :tbody-tr-class="rowClass">
      <template #cell(vignette)="row">
        <b-img :src="`/img/${row.item.lidic}.jpg`"/>
      </template>
        <template #cell(actions)="row">
          <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel')" class="mr-1">Editer</b-button>
          <b-button size="sm" target="_blank" @click="copyItem(row.item,'individuel')" class="mr-1">Copier</b-button>
          <b-button size="sm" target="_blank" @click="deleteItem(row.item,'individuel')" class="mr-1">Supprimer</b-button>
        </template>
      </b-table>
      </div></div>
    </b-col>
    </b-row>
    <datalist id="my-list-pt">
        <option :value="prod.value" v-for="(prod,index3) in lidic_params" :key="index3">{{ prod.text }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'IndividuelAdmin',
  props: {
    individuel: Array,
    product: Array,
  },
  data() {
      return {
        dismissCountDown: 0,
        currentVMC:{},
        currentVMCLidic: -1,
        currentOptions:{},
        currentParams:{},
        currentConfig:{},
        newConfig: 'Nouveau Label',
        currentGaine:{},
        currentGaineLidic: null,
        tmpGaineLidic: null,
        currentConfigLabel: null,
        tmpConfigLabel: null,
        lidic_options:[],
        lidic_params:[],
        lidic_product:[],
        newConfigParam: '',
        editVMC:false,
        editOptions:false,
        editParams:false,
        editConfig:false,
        editGaines:false,
        filter: null,
        perPage: 20,
        currentPage: 1,
        fields_config: [
          {
            key: 'label',
            label: 'label',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_params: [
          {
            key: 'type',
            label: 'Type',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_gaines: [
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_options: [
          {
            key: 'libelle',
            label: 'Libelle',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields: [
          { key: 'vignette', label: '',tdClass: 'product-vignette' },
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          {
            key: 'nom',
            label: 'Nom',
            sortable: true
          },
          {
            key: 'categorie',
            label: 'Type',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        typologie:[{
          value:1,
          text:'T1'
        },{
          value:2,
          text:'T2'
        },{
          value:3,
          text:'T3'
        },{
          value:4,
          text:'T4'
        },{
          value:5,
          text:'T5'
        },{
          value:6,
          text:'T6'
        },{
          value:7,
          text:'T7'
        }],
        reglementation:[{
          value:'E',
          text:'Existant'
        },
        {
          value:'N',
          text:'Construction Neuve'
        },{
          value:'E/N',
          text:'Existant ou Construction Neuve'
        }],
        param_type:[{value:'insufflation',text:"Bouche d'insufflation"},
                    {value:'gaines',text:"Gaines"},
                    {value:'piece_technique','text':'Pièces Techniques'},
                   {value:'entree','text':"Bouche d'entrée"},
                   {value:'silent','text':'Silent'}],
        flux:[{value:'Simple Flux',text:'Simple Flux'},{value:'Double Flux',text:'Double Flux'}],
        status:[{value:'valid',text:'Validé'},{value:'error',text:'Erreur Paramétrage'},{value:'waiting',text:'En attente de validation'}],
      }
  },
  methods: {
    countDownChanged(dismisscd){
      this.dismissCountDown = dismisscd;
    },
    updateParams(){
      switch(this.currentParams.type){
        case 'entree':
          this.$set(this.currentParams,'rallonge',{});
          this.$set(this.currentParams,'entree',{});
          this.$delete(this.currentParams,'sdb');
          this.$delete(this.currentParams,'sdb_wc');
          this.$delete(this.currentParams,'wc');
          this.$delete(this.currentParams,'eau');
          this.$delete(this.currentParams,'offered');
        break;
        case 'piece_technique':
          this.$delete(this.currentParams,'rallonge');
          this.$delete(this.currentParams,'entree');
          this.$set(this.currentParams,'sdb',{items:[],offered:0});
          this.$set(this.currentParams,'sdb_wc',{items:[],offered:0});
          this.$set(this.currentParams,'wc',{items:[],offered:0});
          this.$set(this.currentParams,'eau',{items:[],offered:0});
          this.$set(this.currentParams,'offered',0);
        break;
        default:
          this.$delete(this.currentParams,'sdb');
          this.$delete(this.currentParams,'sdb_wc');
          this.$delete(this.currentParams,'wc');
          this.$delete(this.currentParams,'eau');
          this.$delete(this.currentParams,'offered');
          this.$delete(this.currentParams,'rallonge');
          this.$delete(this.currentParams,'entree');
        break;
      }
      console.log(this.currentParams);
    },
    add_item_options(){
      this.currentOptions.content.options.push({text:'',value:''});
    },
    remove_item_options(index){
      this.currentOptions.content.options.splice(index,1);
    },
    remove_item_config(propertyName){
      delete this.currentConfig[propertyName];
      this.$forceUpdate();
    },
    add_item_config(){
      if(this.newConfigParam!=""){
        this.$set(this.currentConfig,this.newConfigParam,0);
        this.newConfigParam = '';
      }
    },
    add_item_entree(rallonge,bouche){
      if(rallonge){
        if(!this.currentParams['rallonge'][bouche]) this.$set(this.currentParams['rallonge'],bouche,[]);
        this.currentParams['rallonge'][bouche].push({lidic:'',number:1});
      }else{
        if(!this.currentParams['entree'][bouche]) this.$set(this.currentParams['entree'],bouche,[]);
        this.currentParams['entree'][bouche].push({lidic:'',number:1});
      }

    },
    remove_item_entree(rallonge,bouche,index2){
      if(rallonge){
        this.currentParams['rallonge'][bouche].splice(index2,1);
      }else{
        this.currentParams['entree'][bouche].splice(index2,1);
      }
    },
    remove_item_gaine(nb_etage,index){
      this.currentGaine.items[nb_etage].splice(index,1);
    },
    add_item_gaine(nb_etage){
      if(!this.currentGaine.items[nb_etage]) this.$set(this.currentGaine.items,nb_etage,[]);
      this.currentGaine.items[nb_etage].push({lidic:'',number:1});
    },
    add_item_bouche(bouche){
      if(!this.currentParams[bouche]) this.$set(this.currentParams,bouche,[]);
      this.currentParams[bouche].push({lidic:'',number:1});
    },
    remove_item_bouche(bouche,index2){
      this.currentParams[bouche].splice(index2,1);
    },
    add_item_pt(pt,index){
      if(!this.currentParams[pt].items[index]) this.$set(this.currentParams[pt].items,index,[]);
      this.currentParams[pt].items[index].push({lidic:'',number:1,offered:0});
    },
    remove_item_pt(pt,index,index2){
      this.currentParams[pt].items[index].splice(index2,1);
    },
    is_paramType(type){
      return this.currentParams.type==type;
    },
    close(type){
      switch(type){
        case 'options':
          console.log(this.currentOptions);
          console.log(this.currentVMC);
          this.lidic_options = [];
          this.editOptions=false;
        break;
        case 'params':
          this.lidic_params = [];
          this.editParams=false;
          console.log(this.currentParams);
          console.log(this.currentVMC);
        break;
        case 'gaines':
          this.lidic_params = [];
          this.editGaines = false;
          if(this.currentGaineLidic!=this.tmpGaineLidic && this.currentGaineLidic!=-1){
            this.$delete(this.currentParams, this.currentGaineLidic);
          }
          this.$set(this.currentParams,this.tmpGaineLidic,this.currentGaine);
          console.log(this.currentParams);
          console.log(this.currentVMC);
        break;
        case 'config':
          this.editConfig=false;
          if(this.currentConfigLabel!=this.tmpConfigLabel && this.currentConfigLabel!='Nouveau Label'){
            this.$delete(this.currentVMC.params.config, this.currentConfigLabel);
          }
          this.$set(this.currentVMC.params.config,this.tmpConfigLabel,this.currentConfig);
          console.log(this.currentConfig);
          console.log(this.currentVMC);
        break;
      }
    },
    copyItem(item,type){
      switch(type){
        case 'individuel':
          this.currentVMC = JSON.parse(JSON.stringify(item));
          this.currentVMC.lidic=null;
          this.currentVMC.status= "waiting",
          this.currentVMCLidic = -1;
          this.editVMC = true;
        break;
      }
    },
    moveUp(index,type){
      switch(type){
        case 'individuel_option':
          var element = this.currentVMC.options.splice(index,1)[0];
          this.currentVMC.options.splice((index-1),0,element);
        break;
      }
    },
    moveDown(index,type){
      switch(type){
        case 'individuel_option':
          var element = this.currentVMC.options.splice(index,1)[0];
          this.currentVMC.options.splice((index+1),0,element);
        break;
      }
    },
    editItem(item,type){
      switch(type){
        case 'individuel':

          if(item==-1){
            this.currentVMC = {
              categorie: "",
              lidic: null,
              options: [],
              params: {
                items:[],
                config:{}
              },
              featured:false,
              feature_slug:'',
              status: "waiting",
              typologie: 7,
              typologie_min: 1,
            };
            this.currentVMCLidic = item;
          }else{
            console.log(item);
            this.currentVMC = item;
            this.currentVMC.featured = this.currentVMC.featured==1;
            this.currentVMCLidic = item.lidic;
          }
          this.editVMC = true;
        break;
        case 'individuel_option':

          if(item==-1){
            this.currentVMC.options.push({content:{number:1,options:[]},libelle:''});
            this.currentOptions = this.currentVMC.options[this.currentVMC.options.length - 1];
          }else{
            this.currentOptions = item;
          }
          this.editOptions = true;
        break;
        case 'individuel_params':

          if(item==-1){
            this.currentVMC.params.items.push({type:''});
            this.currentParams = this.currentVMC.params.items[this.currentVMC.params.items.length - 1];
          }else{
            this.currentParams = item;
          }
          this.editParams = true;
        break;
        case 'individuel_config':
          if(item==-1){
            this.currentConfig = {};
            this.currentConfigLabel = 'Nouveau label';
            this.tmpConfigLabel = 'Nouveau label';
          }else{
            this.currentConfig = this.currentVMC.params.config[item.label];
            this.currentConfigLabel = item.label;
            this.tmpConfigLabel = this.currentConfigLabel;
          }
          this.editConfig = true;
          console.log(this.currentConfig);
        break;
        case 'individuel_gaines':

          if(item==-1){
            this.currentGaine = {
              items:{
                0:[],
                1:[]
              },
              longueur:0
            };
            this.currentGaineLidic = -1;
            this.tmpGaineLidic = null;
          }else{
            this.currentGaine = this.currentParams[item.lidic];
            this.currentGaineLidic = item.lidic;
            this.tmpGaineLidic = this.currentGaineLidic;
          }
          this.editGaines = true;
          console.log(item);
        break;
      }
    },
    deleteItem(item,type){
      switch(type){
        case 'individuel':
          this.$http.delete(`${this.$api_url}vmc/${type}/${item.lidic}`).then((result) => {
            console.log(result);
            if(result){
              var index = this.individuel.findIndex((vmc) => { return item.lidic == vmc.lidic; });
              if(index>-1) this.individuel.splice(index,1);
            }else{
              console.log(result);
            }
          });
        break;
        case 'individuel_option':
          this.currentVMC.options.splice(item,1);
        break;
        case 'individuel_params':
          this.currentVMC.params.items.splice(item,1);
        break;
        case 'individuel_config':
          this.$delete(this.currentVMC.params.config, item.label);
        break;
        case 'individuel_gaines':
          this.$delete(this.currentParams, item.lidic);
        break;
      }
    },
    cancel(type){
      switch(type){
        case 'individuel':
          this.editVMC = false;
          this.currentVMC = {};
        break;
      }
    },
    saveItem(type){
      switch(type){
        case 'individuel':
          console.log(this.currentVMC);
          var baseURI = this.$api_url+'vmc';
          if(this.currentVMCLidic==-1){
            this.$http.post(baseURI,{vmc: this.currentVMC, type:type})
            .then((result) => {
              console.log(result);
              if(result){
                this.$set(this.individuel,(this.individuel.length-1),this.currentVMC);
                this.currentVMC = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }else{
            console.log(this.currentVMC);
            this.$http.put(baseURI,{vmc: this.currentVMC,lidic: this.currentVMCLidic, type:type})
            .then((result) => {
              console.log(result);
              if(result){
                var index = this.individuel.findIndex((item) => { return item.lidic == this.currentVMCLidic; });
                this.$set(this.individuel,index,this.currentVMC);
                this.currentVMC = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }
        break;
      }
    },
    rowClass(item,type){
      if (item && type === 'row') {
        if(item.status=='valid'){
          return 'validation_success';
        }else if(item.status=='error'){
          return 'validation_error';
        }else if(item.status=="waiting"){
          return 'waiting_validation';
        }
      }else{
        return null;
      }
    },
    updateVMC(){
      if(this.currentVMC.lidic!=""){
        let index = this.product.findIndex((item)=> {
          return item.lidic == this.currentVMC.lidic});
        if(index!=1){
          this.currentVMC.nom = this.product[index].nom;
          this.currentVMC.designation = this.product[index].designation;
          this.currentVMC.prix_unitaire = this.product[index].prix_unitaire;
          this.currentVMC.debit_max = this.product[index].debit_max;
        }
        console.log(this.currentVMC);
      }
    },
    updateLidicGaineProd(nb_etage,index){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentGaine.items[nb_etage][index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentGaine.items[nb_etage][index].lidic) || item.nom.toString().includes(this.currentGaine.items[nb_etage][index].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicGaine(){
      let res = [];
      this.product.forEach((item) => {
        if(this.tmpGaineLidic.toString().length>3 && (item.lidic.toString().includes(this.tmpGaineLidic) || item.nom.toString().includes(this.tmpGaineLidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicOptions(index){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentOptions.content.options[index].value.toString().length>3 && (item.lidic.toString().includes(this.currentOptions.content.options[index].value) || item.nom.toString().includes(this.currentOptions.content.options[index].value))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    clearLidicBouche(){
      this.lidic_params = [];
    },
    updateLidicEntree(rallonge,index,index2){
      let res = [];
      if(rallonge){
        this.product.forEach((item) => {
          if(this.currentParams['rallonge'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['rallonge'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['rallonge'][index][index2].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }else{
        this.product.forEach((item) => {
          if(this.currentParams['entree'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['entree'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['entree'][index][index2].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }
      this.lidic_params = res;
      return res;
    },
    updateLidicBouche(index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[index][index2].lidic) || item.nom.toString().includes(this.currentParams[index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicParams(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.lidic) || item.nom.toString().includes(this.currentParams.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicPT(pt,index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams[pt].items[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[pt].items[index][index2].lidic) || item.nom.toString().includes(this.currentParams[pt].items[index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicProduct(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentVMC.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentVMC.lidic) || item.nom.toString().includes(this.currentVMC.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_product = res;
      return res;
    }
  },
  computed: {
    get_gaines_params(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="gaines"){
        console.log(this.currentParams);
        Object.keys(this.currentParams).filter(e => e !== 'type').forEach((item)=>{
          res.push({lidic: item});
        });
      }
      return res;
    },
    typologie_range(){
      let res = [];
      if(this.currentVMC!=null && this.currentVMC.typologie!=undefined && this.currentVMC.typologie_min!=undefined){
        let tmp = this.currentVMC.typologie_min;
        while(tmp<=this.currentVMC.typologie){
          res.push(tmp);
          tmp++;
        }
      }
      return res;
    },
    piece_technique_range(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="piece_technique" && this.currentParams['eau']!=null){
        res.push({value:'eau',text:"Salle d'eau"});
        res.push({value:'wc',text:"WC"});
        res.push({value:'sdb_wc',text:"Salle de bain avec WC"});
        res.push({value:'sdb',text:"Salle de bain"});
      }
      return res;
    },
    total_devis(){
      return this.items.length;
    },
    getCurrentConfig(){
      let res = [];
      Object.keys(this.currentVMC.params.config).forEach((item)=>{
        if(item!="leroy_merlin"){
          console.log(item);
          res.push({label:item});
        }
      });
      return res;
    },
    total_prix(){
      let res = 0;
      this.items.forEach((item) => {
        res += parseFloat(item.prix);
      });
      res = res.toFixed(2);
      return res;
    },
    items(){
      let res;
      if((this.start!=null && this.start!="") && (this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime() && new Date(elem.created_at).getTime() <= new Date(this.end).getTime());
      }else if((this.start!=null && this.start!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime());
      }else if((this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime()<= new Date(this.end).getTime());
      }else{
        res = this.devis;
      }
      return res;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
