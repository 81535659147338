<template>
  <div id="script_admin">
    <b-tabs pills>
      <b-tab title="VMC" active class="main_content">
        <b-tabs>
          <b-tab title="Maison Individuel" active class="main_content">
            <b-row>
              <b-col cols="2">
                <b-form-file v-model="fileCSV" class="mt-3" plain></b-form-file>
              </b-col>
              <b-col cols="10">
                <b-form-select v-model="type_import" :options="options_type" size="sm" class="mt-3"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col offset="10" cols="2">
                <b-button size="sm" target="_blank" @click="uploadImport('individuel')" class="mr-1">Importer</b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Habitat Collectif">
            <b-row>
              <b-col cols="2">
                <b-form-file v-model="fileCSV" class="mt-3" plain></b-form-file>
              </b-col>
              <b-col cols="10">
                <b-form-select v-model="type_import" :options="options_type" size="sm" class="mt-3"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col offset="10" cols="2">
                <b-button size="sm" target="_blank" @click="uploadImport('collectif')" class="mr-1">Importer</b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Tertiaire">
            <b-row>
              <b-col cols="2">
                <b-form-file v-model="fileCSV" class="mt-3" plain></b-form-file>
              </b-col>
              <b-col cols="10">
                <b-form-select v-model="type_import" :options="options_type" size="sm" class="mt-3"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col offset="10" cols="2">
                <b-button size="sm" target="_blank" @click="uploadImport('tertiaire')" class="mr-1">Importer</b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Désenfumage">
            <b-row>
              <b-col cols="2">
                <b-form-file v-model="fileCSV" class="mt-3" plain></b-form-file>
              </b-col>
              <b-col cols="10">
                <b-form-select v-model="type_import" :options="options_type" size="sm" class="mt-3"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col offset="10" cols="2">
                <b-button size="sm" target="_blank" @click="uploadImport('desenfumage')" class="mr-1">Importer</b-button>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-tab>
      <b-tab title="Mise à jour Catalogue" class="main_content">
        <b-row v-if="success">
          <b-col>La mise à jour du catalogue a été correctement effectué</b-col>
        </b-row>
        <b-row v-if="error">
          <b-col>La mise à jour du catalogue n'a pas pu être effectué correctement.</b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-form-file v-model="fileCSV" class="mt-3" plain></b-form-file>
          </b-col>
          <b-col cols="10">
            <b-form-select v-model="type_update" :options="update_type" size="sm" class="mt-3"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="10" cols="2">
            <b-button size="sm" target="_blank" @click="uploadCatalogue()" class="mr-1">Mise à jour Catalogue</b-button>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Mise à jour Prix" class="main_content">
        <b-row v-if="success_barre">
          <b-col>La mise à jour du prix des barres a été correctement effectué</b-col>
        </b-row>
        <b-row v-if="error_barre">
          <b-col>La mise à jour du prix des barres n'a pas pu être effectué correctement.</b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-form-file v-model="fileCSV" class="mt-3" plain></b-form-file>
          </b-col>
          <b-col cols="10">
            <b-form-select v-model="type_barre" :options="barre_type" size="sm" class="mt-3"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="10" cols="2">
            <b-button size="sm" target="_blank" @click="uploadBarre()" class="mr-1">Mise à jour Prix des barres</b-button>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Transfert Configuration" class="main_content">
        <b-row v-if="success_transfert">
          <b-col>Le transfert de(s) configuration(s) a été correctement effectué</b-col>
        </b-row>
        <b-row v-if="error_transfert">
          <b-col>Le transfert de(s) configuration(s) n'a pas pu être effectué correctement.</b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <h5>Destination</h5>
          </b-col>
          <b-col cols="10">
            <b-form-select v-model="app_dest_selected" :options="application_estimair_dest" size="sm" class="mt-3" multiple></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <h5>Module</h5>
          </b-col>
          <b-col cols="10">
            <b-form-select v-model="modules_selected" :options="modules_available" size="sm" class="mt-3" multiple></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="10" cols="2">
            <b-button size="sm" target="_blank" @click="transfertConfig()" class="mr-1">Transfert</b-button>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Backup" class="main_content">
        <b-tabs>
          <b-tab title="Catalogue" active class="main_content">
            <b-row>
              <b-col cols="12">
                <b-form-select v-model="catalogue_backup_selected" :options="catalogue_backup_select" size="sm" class="mt-3"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="backtoBackup('catalogue')" class="mr-1">Revenir à</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="deleteBackup('catalogue')" class="mr-1">Supprimer</b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Individuel">
            <b-row>
              <b-col cols="12">
                <b-form-select v-model="individuel_backup_selected" :options="individuel_backup_select" size="sm" class="mt-3"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="backtoBackup('individuel')" class="mr-1">Revenir à</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="deleteBackup('individuel')" class="mr-1">Supprimer</b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Collectif">
            <b-row>
              <b-col cols="12">
                <b-form-select v-model="collectif_backup_selected" :options="collectif_backup_select" size="sm" class="mt-3"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="backtoBackup('collectif')" class="mr-1">Revenir à</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="deleteBackup('collectif')" class="mr-1">Supprimer</b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Calcul Collectif">
            <b-row>
              <b-col cols="12">
                <b-form-select v-model="collectif_calcul_backup_selected" :options="collectif_calcul_backup_select" size="sm" class="mt-3"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="backtoBackup('collectif_calcul')" class="mr-1">Revenir à</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="deleteBackup('collectif_calcul')" class="mr-1">Supprimer</b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Tertiaire">
            <b-row>
              <b-col cols="12">
                <b-form-select v-model="tertiaire_backup_selected" :options="tertiaire_backup_select" size="sm" class="mt-3"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="backtoBackup('tertiaire')" class="mr-1">Revenir à</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="deleteBackup('tertiaire')" class="mr-1">Supprimer</b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Calcul Tertiaire">
            <b-row>
              <b-col cols="12">
                <b-form-select v-model="tertiaire_calcul_backup_selected" :options="tertiaire_calcul_backup_select" size="sm" class="mt-3"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="backtoBackup('tertiaire_calcul')" class="mr-1">Revenir à</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="deleteBackup('tertiaire_calcul')" class="mr-1">Supprimer</b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Desenfumage">
            <b-row>
              <b-col cols="12">
                <b-form-select v-model="desenfumage_backup_selected" :options="desenfumage_backup_select" size="sm" class="mt-3"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="backtoBackup('desenfumage')" class="mr-1">Revenir à</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="deleteBackup('desenfumage')" class="mr-1">Supprimer</b-button>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  name: 'ScriptAdmin',
  props: {
    backup: Object
  },
  watch: {
    backup: function () {
      console.log("backup");
      console.log(this.backup);
      if(this.backup['catalogue'].length>1){
        this.backup['catalogue'].forEach((item) => {
          if(item.name!=""){
            this.catalogue_backup_select.push({value:item.tablename,text:item.name});
          }
        });
      }

      if(this.backup['individuel'].length>1){
        this.backup['individuel'].forEach((item) => {
          if(item.name!=""){
            this.individuel_backup_select.push({value:item.tablename,text:item.name});
          }
        });
      }

      if(this.backup['collectif'].length>1){
        this.backup['collectif'].forEach((item) => {
          if(item.name!=""){
            this.collectif_backup_select.push({value:item.tablename,text:item.name});
          }
        });
      }

      if(this.backup['collectif_calcul'].length>1){
        this.backup['collectif_calcul'].forEach((item) => {
          if(item.name!=""){
            this.collectif_calcul_backup_select.push({value:item.tablename,text:item.name});
          }
        });
      }

      if(this.backup['tertiaire'].length>1){
        this.backup['tertiaire'].forEach((item) => {
          if(item.name!=""){
            this.tertiaire_backup_select.push({value:item.tablename,text:item.name});
          }
        });
      }

      if(this.backup['tertiaire_calcul'].length>1){
        this.backup['tertiaire_calcul'].forEach((item) => {
          if(item.name!=""){
            this.tertiaire_calcul_backup_select.push({value:item.tablename,text:item.name});
          }
        });
      }

      if(this.backup['desenfumage'].length>1){
        this.backup['desenfumage'].forEach((item) => {
          if(item.name!=""){
            this.desenfumage_backup_select.push({value:item.tablename,text:item.name});
          }
        });
      }
    },
  },
  data() {
    return {
      app_origin:null,
      app_dest_selected:null,
      catalogue_backup_selected:null,
      catalogue_backup_select:[
        {
          value:'null',text:"Sélectionner une backup du catalogue"
        }
      ],
      desenfumage_backup_selected:null,
      desenfumage_backup_select:[
        {
          value:'null',text:"Sélectionner une backup du désenfumage"
        }
      ],
      tertiaire_calcul_backup_selected:null,
      tertiaire_calcul_backup_select:[
        {
          value:'null',text:"Sélectionner une backup du calcul du tertiaire"
        }
      ],
      tertiaire_backup_selected:null,
      tertiaire_backup_select:[
        {
          value:'null',text:"Sélectionner une backup du tertiaire"
        }
      ],
      collectif_calcul_backup_selected:null,
      collectif_calcul_backup_select:[
        {
          value:'null',text:"Sélectionner une backup du calcul du collectif"
        }
      ],
      collectif_backup_selected:null,
      collectif_backup_select:[
        {
          value:'null',text:"Sélectionner une backup du collectif"
        }
      ],
      individuel_backup_selected:null,
      individuel_backup_select:[
        {
          value:'null',text:"Sélectionner une backup d'individuel"
        }
      ],
      application_estimair_dest:[{
        value:'null',text:"Sélectionner les application à mettre à jour"
      }],
      modules_selected:null,
      modules_available:[
        {value:null,text:"Sélectionner les modules à transferer"},
        {value:'individuel',text:"Module Individuel"},
        {value:'collectif',text:"Module Collectif"},
        {value:'collectif_calcul',text:"Module Collectif - Calcul"},
        {value:'tertiaire',text:"Module Tertiaire"},
        {value:'tertiaire_calcul',text:"Module Tertiaire - Calcul"},
        {value:'desenfumage',text:"Module Désenfumage"},
        {value:'catalogue',text:"Catalogue Produit"},
      ],
      fileCSV:'',
      type_import:'import',
      type_update:'add_update',
      type_barre: 'barre_horizontal',
      error:false,
      success:false,
      success_barre:false,
      error_barre:false,
      success_transfert:false,
      error_transfert:false,
      options_type:[
         { value: null, text: "Selectionner l'action à effectuer" },
         { value: 'import', text: 'Importer VMC' },
         { value: 'delete_config', text: 'Supprimer Produit Obsolète dans les options' },
         //{ value: 'delete', text: 'Supprimer VMC' }
      ],
      update_type:[
        { value: 'add_update', text: 'Ajout et Mise à jour existant' },
        { value: 'add_update_remove', text: 'Ajout, Mise à jour et Suppression' },
        { value: 'update_prix', text: 'Mise à jour des prix' },
        { value: 'update_marchand', text: 'Mise à jour des références et code marchand' }
      ],
      barre_type:[
        { value: 'barre_horizontal', text: 'Barre Horizontal' },
        { value: 'barre_vertical_collectif', text: 'Barre Verticale Collectif' },
        { value: 'barre_vertical_tertiaire', text: 'Barre Verticale Tertiaire' }
      ]
    }
  },
  methods: {
    backtoBackup(type){
      console.log("back to "+type);
      console.log(this.catalogue_backup_selected);
    },
    deleteBackup(type){
      console.log("delete "+type);
      console.log(this.catalogue_backup_selected);
    },
    uploadImport(type){
      console.log(this.fileCSV);
      console.log(this.type_import);
      const formData = new FormData();
      formData.append('import_file', this.fileCSV);
      switch(this.type_import){
        case 'import':
          this.$http.post(this.$api_url+'import/'+type, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }}).then((res) => {
            console.log(res);
          });
        break;
        case 'delete':
          this.$http.post(this.$api_url+'delete_vmc/'+type, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }}).then((res) => {
            console.log(res);
          });
        break;
        case 'delete_config':
          this.$http.post(this.$api_url+'delete_config/'+type, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }}).then((res) => {
            console.log(res);
          });
        break;
      }
    },
    uploadCatalogue(){
      this.sucess = this.error = false;
      const formData = new FormData();
      formData.append('import_file', this.fileCSV);
      this.$http.post(this.$api_url+'catalogue/'+this.type_update, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }}).then((res) => {
        console.log(res);
        if(res.data.data.error.length==0){
          this.success = true;
        }else{
          this.error=true;
        }
      });
    },
    uploadBarre(){
      const formData = new FormData();
      formData.append('import_file', this.fileCSV);
      this.error_barre = this.success_barre = false;
      this.$http.post(this.$api_url+'import_barre/'+this.type_barre, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }}).then((res) => {
        console.log(res);
        this.success_barre = true;
      }).catch((error) => {console.log(error); this.error_barre = true;});
    },
    transfertConfig(){
      console.log(this.app_origin);
      console.log(this.app_dest_selected);
      console.log(this.modules_selected);
      this.success_transfert = this.error_transfert = false;
      this.$http.post(this.$api_url+'transfert/',{destinations: this.app_dest_selected, modules:this.modules_selected}).then((result) => {
        console.log(result);
        this.success_transfert = true;
      }).catch((error) => {console.log(error); this.error_transfert = true;});
    },
    deleteItem(item,type){
      switch(type){
        case 'img':
          console.log(this.img[item.id]);
          this.$http.delete(`${this.$api_url}img/${this.img[item.id].name}`).then((result) => {
            if(result){
              let lidic_tmp = this.img[item.id].lidic;
              let indexLidic = this.lidic_img.findIndex((element) => element == lidic_tmp);
              if(indexLidic!=-1) this.lidic_img.splice(indexLidic,1);

              this.img.splice(item.id,1);
            }else{
              console.log(result);
            }
          });
        break;
      }
    },
    countDownChanged(dismisscd){
      this.dismissCountDown = dismisscd;
    },
    uploadFile(event){
      this.files = event.target.files;
    },
    handleSubmit() {
      const formData = new FormData();
      for (const i of Object.keys(this.files)) {
        formData.append('new_images', this.files[i]);
      }

      this.$http.post(this.$api_url+'img', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }}).then((res) => {
        res.data.filenames.forEach((item) => {
          let lidic_tmp = item.split(".");
          let index_lidic = this.product.findIndex((element) => element.lidic == lidic_tmp[0]);
          if(index_lidic==-1){
            this.img.push({id:this.img.length,name:item,product_name:'',lidic:''});
          }else{
            let index_img_lidic = this.img.findIndex((element) => element.lidic == lidic_tmp[0]);
            if(index_img_lidic==-1){
              this.img.push({id:this.img.length,name:item,product_name:this.product[index_lidic].name,lidic:this.product[index_lidic].lidic});
              this.lidic_img.push(this.product[index_lidic].lidic);
            }else{
              this.img[index_img_lidic].name=item;
            }
          }
        });
        console.log(this.img);
      })
    },
    updateLidicImg(lidic_item){
      let res = [];
      this.product.forEach((item) => {
        if(lidic_item.lidic.toString().length>3 && (item.lidic.toString().includes(lidic_item.lidic) || item.nom.toString().includes(lidic_item.lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    putLidicImg(lidic_item){
      var baseURI = this.$api_url+'img';
      let indexLidic = this.lidic_img.findIndex((element) => element == lidic_item.lidic);
      console.log(indexLidic);
      console.log(lidic_item.id);
      if(indexLidic==lidic_item.id || indexLidic==-1){
        this.$http.put(baseURI,{name: lidic_item.name,lidic: lidic_item.lidic})
        .then((result) => {
          console.log(result);
          if(result){
            this.img[lidic_item.id].name = result.data.name;
            let indexProduct = this.product.findIndex((element) => element.lidic == lidic_item.lidic);
            this.img[lidic_item.id].product_name = this.product[indexProduct].nom;
            if(indexLidic==-1){
              this.lidic_img.push(lidic_item.lidic);
            }
          }else{
            console.log(result);
          }
        });
      }else{
        this.dismissCountDown = 5;
        this.img[lidic_item.id].lidic = this.lidic_img[lidic_item.id];
      }
    }
  },
  mounted(){
    this.app_origin = this.$appName;
    var tmp_application = [
      {value:'dev',text:"Dev"},
      {value:'prod',text:"Prod"},
      {value:'leroy-merlin',text:"Leroy Merlin"},
      {value:'cged',text:"CGED"},
      {value:'sonepar',text:"Sonepar"},
      {value:'rexel',text:"Rexel"},
      {value:'yes',text:"Yes"}
    ]
    this.application_estimair_dest = [].concat(this.application_estimair_dest, tmp_application.filter(e => e.value !== this.app_origin));
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
