<template>
  <div id="tertiaire_admin">
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <div class="content-block shadow-box">
          <div class='header-form'>
            <p><span>Tertiaire</span><br></p>
          </div>
          <div class="content-form roundButton">
          <b-tabs>
            <b-tab title="Type Locaux" active class="main_content">
              <b-row v-if="editLocal">
                <b-col cols="12" md="12" lg="12">
                  <div class="content-block shadow-box">
                    <div class='header-form'>
                      <p><span>{{currentLocal.nom}}</span><br></p>
                    </div>
                    <div class="content-form roundButton">
                      <b-row>
                        <b-col cols="2">
                          <label for="name_local">Nom: </label>
                        </b-col>
                        <b-col>
                          <b-form-input v-model="currentLocal.nom" id="name_local"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="statut_local">Status: </label>
                        </b-col>
                        <b-col>
                          <b-form-select v-model="currentLocal.status" :options="status" id="statut_local"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <div class="content-block shadow-box" v-if="editLocalPiece">
                              <div class='header-form'>
                                <p><span>Pièce {{currentPiece.name}}</span><br></p>
                              </div>
                              <div class="content-form roundButton">
                                <b-row>
                                  <b-col cols="2">
                                    <label :for="'piece_nom'">Nom Pièce: </label>
                                  </b-col>
                                  <b-col cols="3">
                                    <b-form-input :id="'piece_nom'" v-model="currentPiece.name"></b-form-input>
                                  </b-col>
                                </b-row>
                                <b-row v-if="currentPiece.type=='entree' || currentPiece.type=='independant'">
                                  <b-col>
                                    <b-row>
                                      <b-col>
                                        <h5>Débit en m3/heure</h5>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col cols="3">
                                        <b-form-checkbox id="checkbox-personne_debit" v-model="personne_debit" name="checkbox-personne_debit" @change="changePersonne()">Débit par personne</b-form-checkbox>
                                      </b-col>
                                      <b-col>
                                        <b-form-input :id="'piece_nom'" v-model.number="currentPiece.personne" v-if="personne_debit" type="number"></b-form-input>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col cols="3">
                                        <b-form-checkbox id="checkbox-m_debit" v-model="m_debit" name="checkbox-m_debit" @change="changeSurface()">Débit par m²</b-form-checkbox>
                                      </b-col>
                                      <b-col>
                                        <b-form-input :id="'piece_nom'" v-model.number="currentPiece.surface" v-if="m_debit" type="number"></b-form-input>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row v-if="currentPiece.type=='sortie'">
                                  <b-col>
                                    <b-row>
                                      <b-col>
                                        <h5>Débit en m3/heure</h5>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col cols="3">
                                        <label for="debit_main_sortie">Débit principal</label>
                                      </b-col>
                                      <b-col>
                                        <b-form-input :id="'debit_main_sortie'" v-model.number="currentPiece.debit.main" type="number"></b-form-input>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col cols="3">
                                        <b-form-checkbox id="checkbox-suppdebit" v-model="supp_debit" name="checkbox-suppdebit" @change="changeSupp()">Débit Supplémentaire</b-form-checkbox>
                                      </b-col>
                                      <b-col>
                                        <b-form-input :id="'piece_nom'" v-model.number="currentPiece.debit.supp" v-if="supp_debit" type="number"></b-form-input>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row v-if="currentPiece.type=='independant'">
                                  <b-col></b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-button size="sm" target="_blank" @click="close('local_piece')" class="mr-1">Fermer</b-button>
                                  </b-col>
                                </b-row>
                            </div>
                          </div>
                        </b-col>
                      </b-row>

                      <b-tabs>
                        <b-tab title="Locaux d'entrée">
                          <b-row align-h="end">
                            <b-col cols="2">
                              <b-button size="sm" target="_blank" @click="editItem(-1,'local_entree')" class="mr-1">Ajouter Pièce</b-button>
                            </b-col>
                          </b-row>
                          <b-table responsive striped hover :items="piece_entree" :fields="fields_piece_params">
                            <template #cell(actions)="row">
                              <b-button size="sm" target="_blank" @click="editItem(row.item,'local_entree')" class="mr-1">Editer</b-button>
                              <b-button size="sm" target="_blank" @click="deleteItem(row.index,'local_entree')" class="mr-1">Supprimer</b-button>
                            </template>
                          </b-table>
                        </b-tab>
                        <b-tab title="Locaux de sortie">
                          <b-row align-h="end">
                            <b-col cols="2">
                              <b-button size="sm" target="_blank" @click="editItem(-1,'local_sortie')" class="mr-1">Ajouter Pièce</b-button>
                            </b-col>
                          </b-row>
                          <b-table responsive striped hover :items="piece_sortie" :fields="fields_piece_params">
                            <template #cell(actions)="row">
                              <b-button size="sm" target="_blank" @click="editItem(row.item,'local_sortie')" class="mr-1">Editer</b-button>
                              <b-button size="sm" target="_blank" @click="deleteItem(row.index,'local_sortie')" class="mr-1">Supprimer</b-button>
                            </template>
                          </b-table>
                        </b-tab>
                        <b-tab title="Locaux indépendant">
                          <b-row align-h="end">
                            <b-col cols="2">
                              <b-button size="sm" target="_blank" @click="editItem(-1,'local_independant')" class="mr-1">Ajouter Pièce</b-button>
                            </b-col>
                          </b-row>
                          <b-table responsive striped hover :items="piece_independant" :fields="fields_piece_params">
                            <template #cell(actions)="row">
                              <b-button size="sm" target="_blank" @click="editItem(row.item,'local_independant')" class="mr-1">Editer</b-button>
                              <b-button size="sm" target="_blank" @click="deleteItem(row.index,'local_independant')" class="mr-1">Supprimer</b-button>
                            </template>
                          </b-table>
                        </b-tab>
                      </b-tabs>
                      <b-row>
                        <b-col cols="2">
                          <b-button size="sm" target="_blank" @click="cancel('local')" class="mr-1">Annuler</b-button>
                        </b-col>
                        <b-col cols="8"></b-col>
                        <b-col cols="2">
                          <b-button size="sm" target="_blank" @click="saveItem('local')" class="mr-1">Enregistrer</b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!editLocal">
                <b-col>
                  <b-row align-h="end">
                    <b-col cols="2">
                      <b-button size="sm" target="_blank" @click="editItem(-1,'local')" class="mr-1">Ajouter Local</b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-table responsive striped hover :items="tertiaire.locaux" :fields="fields_locaux" id="tertiaire_locaux" :tbody-tr-class="rowClass">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'local')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.item,'local')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="VMC">
              <b-row  v-if="editVMC">
                <b-col cols="12" md="12" lg="12">
                  <div class="content-block shadow-box">
                    <div class='header-form'>
                      <p><span>{{currentVMC.nom}}</span><br></p>
                    </div>
                    <div class="content-form roundButton">
                      <b-row>
                        <b-col cols="2">
                          <label for="vmc_input">VMC: </label>
                        </b-col>
                        <b-col>
                        <b-form-input id="vmc_input" list="my-list-id" v-model="currentVMC.lidic" @blur="updateVMC" @input="updateLidicProduct()"></b-form-input>
                        <datalist id="my-list-id">
                            <option :value="prod.value" v-for="(prod,index) in lidic_product" :key="index">{{ prod.text }}</option>
                        </datalist>
                      </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="categorie_input">Catégorie: </label>
                        </b-col>
                        <b-col>
                          <b-form-select v-model="currentVMC.categorie" :options="categorie" id="categorie_input"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="type_input">Type: </label>
                        </b-col>
                        <b-col>
                          <b-form-select id="type_input" v-model="currentVMC.type" :options="type"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="type_flux_input">Type de flux: </label>
                        </b-col>
                        <b-col>
                          <b-form-select id="type_flux_input" v-model="currentVMC.flux" :options="type_flux"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="type_version_input">Type de Version: </label>
                        </b-col>
                        <b-col>
                          <b-form-select id="type_version_input" v-model="currentVMC.version" :options="type_version"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="type_input">Type d'aggrément': </label>
                        </b-col>
                        <b-col>
                          <b-form-select id="type_aggrement" v-model="currentVMC.aggrement" :options="type_aggrement"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="tension_input">Tension: </label>
                        </b-col>
                        <b-col>
                          <b-form-select id="tension_input" v-model="currentVMC.tension" :options="tension"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="aspiration_input">Type d'aspiration: </label>
                        </b-col>
                        <b-col>
                          <b-form-select id="aspiration_input" v-model="currentVMC.type_aspiration" :options="aspiration"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="debit_input">Débit: </label>
                        </b-col>
                        <b-col>
                          <b-form-select id="debit_input" v-model="currentVMC.debit_max" :options="debit" multiple></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="diam_aspi_input">Diamètre Aspiration: </label>
                        </b-col>
                        <b-col>
                          <b-form-input v-model.number="currentVMC.diametre_aspi"  id="diam_aspi_input"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="diam_rejet_input">Diamètre Rejet: </label>
                        </b-col>
                        <b-col>
                          <b-form-input v-model.number="currentVMC.diametre_rejet"  id="diam_rejet_input"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <label for="statut_input">Status: </label>
                        </b-col>
                        <b-col>
                          <b-form-select v-model="currentVMC.status" :options="status" id="statut_input"></b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-tabs pills>
                            <b-tab title="Options" active>
                              <div class="content-block shadow-box" v-if="editOptions">
                                <div class='header-form'>
                                  <p><span>Options</span><br></p>
                                </div>
                                <div class="content-form roundButton">
                                  <b-row>
                                  <b-col>
                                  <b-row>
                                    <b-col cols="2">
                                        <label for="options_libelle">Libelle: </label>
                                      </b-col>
                                      <b-col>
                                      <b-form-input id="options_libelle" v-model="currentOptions.libelle"></b-form-input>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="2">
                                      <label for="options_type">Type d'options: </label>
                                    </b-col>
                                    <b-col>
                                      <b-form-select v-model="currentOptions.content.type" :options="options_type" id="options_type" @change="updateConfig()"></b-form-select>
                                    </b-col>
                                  </b-row>
                                  <h4>Options</h4>
                                  <b-row>
                                    <b-col cols="3">
                                      <b-form-checkbox
                                          id="options-require"
                                          v-model="currentOptions.content.optionnal"
                                          name="options-require"
                                        >
                                          En Option
                                        </b-form-checkbox>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="3">
                                      <b-form-checkbox
                                          id="options-multiple"
                                          v-model="currentOptions.content.multiple"
                                          name="options-multiple"
                                        >
                                          Multiple
                                        </b-form-checkbox>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="is_option_checkbox()">
                                    <b-col>
                                      <b-row v-for="(opt,index) in currentOptions.content.items" :key="index">
                                        <b-col cols="2">
                                            <label :for="'options_value_'+index">Produit: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'options_value_'+index" :list="'my-list-pt-tertiaire'" v-model="currentOptions.content.items[index].lidic" @input="updateLidicOptions(index,true)" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                            <label :for="'options_label_'+index">Nombre: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'options_label_'+index" v-model="currentOptions.content.items[index].number"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_options(index)" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_options()" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="!is_option_checkbox()">
                                    <b-col>
                                      <b-row v-for="(opt,index) in currentOptions.content.options" :key="index">
                                        <b-col cols="2">
                                            <label :for="'options_label_'+index">Label: </label>
                                          </b-col>
                                          <b-col cols="3">
                                          <b-form-input :id="'options_label_'+index" v-model="opt.text"></b-form-input>
                                        </b-col>
                                        <b-col cols="7"></b-col>
                                        <b-col cols="2">
                                            <label :for="'options_value_'+index">Produit: </label>
                                          </b-col>
                                          <b-col cols="3">
                                          <b-form-input :id="'options_value_'+index" :list="'my-list-pt-tertiaire'" v-model="currentOptions.content.options[index].value" @input="updateLidicOptions(index,false)" @blur="clearLidicBouche"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <label :for="'options_value_qte'+index">Quantité: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'options_value_qte'+index" v-model="currentOptions.content.options[index].qte"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_options(index)" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col offset="10" cols="2">
                                          <b-button size="sm" target="_blank" @click="add_item_options()" class="mr-1 squarebutton">+</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>

                                  <b-row>
                                    <b-col>
                                      <b-button size="sm" target="_blank" @click="close('options')" class="mr-1">Fermer</b-button>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                </b-row>
                              </div>
                            </div>
                            <b-row align-h="end">
                              <b-col cols="2">
                                <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_option')" class="mr-1">Ajouter Option</b-button>
                              </b-col>
                            </b-row>
                              <b-table responsive striped hover :items="currentVMC.options" :fields="fields_options">
                                <template #cell(actions)="row">
                                  <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_option')" class="mr-1">Editer</b-button>
                                  <b-button size="sm" target="_blank" @click="deleteItem(row.index,'individuel_option')" class="mr-1">Supprimer</b-button>
                                </template>
                              </b-table>
                            </b-tab>
                            <b-tab title="Paramètre">
                              <div class="content-block shadow-box" v-if="editParams">
                                <div class='header-form'>
                                  <p><span>Paramètre</span><br></p>
                                </div>
                                <div class="content-form roundButton">
                                  <b-row>
                                  <b-col>
                                    <b-row>
                                      <b-col cols="2">
                                        <label for="param_vmc_type">Type: </label>
                                      </b-col>
                                      <b-col>
                                        <b-form-select v-model="currentParams.type" :options="param_vmc_type" id="param_vmc_type" @change="updateParams('vmc')"></b-form-select>
                                      </b-col>
                                    </b-row>
                                  <b-row v-if="is_paramType('piquetage',false)">
                                    <b-col cols="2">
                                      <label :for="'piquetage_prod'">Produit: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'piquetage_prod'" list="my-list-pt-tertiaire" v-model="currentParams.lidic" @input="updateLidicPiquetage()" @blur="clearLidicBouche"></b-form-input>
                                    </b-col>
                                    <b-col cols="2">
                                        <label :for="'piquetage_nb'">Nombre: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-spinbutton inline :id="'piquetage_nb'" v-model="currentParams.number" min="0" size="sm"></b-form-spinbutton>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="is_paramType('barre_horizontal',false)">
                                    <b-col cols="2">
                                      <label :for="'barre_horizontal_prod'">Produit: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'barre_horizontal_prod'" list="my-list-pt-tertiaire" v-model="currentParams.lidic" @input="updateLidicPiquetage()" @blur="clearLidicBouche"></b-form-input>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="is_paramType('barre_vertical',false)">
                                    <b-col cols="2">
                                      <label :for="'barre_vertical_prod'">Produit: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'barre_vertical_prod'" list="my-list-pt-tertiaire" v-model="currentParams.lidic" @input="updateLidicPiquetage()" @blur="clearLidicBouche"></b-form-input>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-button size="sm" target="_blank" @click="close('params')" class="mr-1">Fermer</b-button>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                </b-row>
                              </div>
                            </div>
                            <b-row align-h="end">
                              <b-col cols="2">
                                <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_params')" class="mr-1">Ajouter Paramètre</b-button>
                              </b-col>
                            </b-row>
                              <b-table responsive striped hover :items="currentVMC.params.items" :fields="fields_params">
                                <template #cell(actions)="row">
                                  <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_params')" class="mr-1">Editer</b-button>
                                  <b-button size="sm" target="_blank" @click="deleteItem(row.index,'individuel_params')" class="mr-1">Supprimer</b-button>
                                </template>
                              </b-table>
                            </b-tab>
                            <b-tab title="Config">
                              <div class="content-block shadow-box" v-if="editConfig">
                                <div class='header-form'>
                                  <p><span>Configuration: {{currentConfigLabel}}</span><br></p>
                                </div>
                                <div class="content-form roundButton">
                                  <b-row>
                                    <b-col>
                                      <b-row>
                                        <b-col cols="2">
                                          <label :for="'config_label'">Label: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'config_label'" v-model="tmpConfigLabel"></b-form-input>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="!isBarreVertical()">
                                    <b-col>
                                      <b-row>
                                        <b-col cols="2">
                                            <label>Diamètre moyen</label>
                                        </b-col>
                                        <b-col cols="3">
                                          <label>Prix unitaire</label>
                                        </b-col>
                                      </b-row>
                                      <b-row v-for="(value, propertyName, index) in currentConfig" :key="propertyName" >
                                        <b-col cols="2">
                                            <label :for="'config_value_'+index">{{propertyName}}: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'config_value_'+index" v-model.number="currentConfig[propertyName]" type="number"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_config(propertyName)" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="isBarreVertical()">
                                    <b-col>
                                      <b-row>
                                        <b-col cols="2">
                                            <label>Débit Max</label>
                                        </b-col>
                                        <b-col cols="3">
                                          <label>Prix 1er Niveau</label>
                                        </b-col>
                                        <b-col cols="3">
                                          <label>Prix Niveau Supplémentaire</label>
                                        </b-col>
                                      </b-row>
                                      <b-row v-for="(value, propertyName, index) in currentConfig" :key="propertyName" >
                                        <b-col cols="2">
                                            <label :for="'config_value_'+index">{{propertyName}}: </label>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'config_value_'+index+'nv1'" v-model.number="currentConfig[propertyName][0]" type="number"></b-form-input>
                                        </b-col>
                                        <b-col cols="3">
                                          <b-form-input :id="'config_value_'+index+'nv2'" v-model.number="currentConfig[propertyName][1]" type="number"></b-form-input>
                                        </b-col>
                                        <b-col cols="2">
                                          <b-button size="sm" target="_blank" @click="remove_item_config(propertyName)" class="mr-1 squarebutton">-</b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="2">
                                        <label :for="'config_value_-1'">Nouvel Config: </label>
                                    </b-col>
                                    <b-col cols="3">
                                      <b-form-input :id="'config_value_-1'" v-model="newConfigParam"></b-form-input>
                                    </b-col>
                                    <b-col cols="2">
                                      <b-button size="sm" target="_blank" @click="add_item_config()" class="mr-1 squarebutton">+</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-button size="sm" target="_blank" @click="close('config')" class="mr-1">Fermer</b-button>
                                    </b-col>
                                  </b-row>
                              </div>
                            </div>
                            <b-row align-h="end">
                              <b-col cols="2">
                                <b-button size="sm" target="_blank" @click="editItem(-1,'individuel_config')" class="mr-1">Ajouter Config</b-button>
                              </b-col>
                            </b-row>
                              <b-table responsive striped hover :items="getCurrentConfig" :fields="fields_config">
                                <template #cell(actions)="row">
                                  <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel_config')" class="mr-1">Editer</b-button>
                                  <b-button size="sm" target="_blank" @click="deleteItem(row.item,'individuel_config')" class="mr-1">Supprimer</b-button>
                                </template>
                              </b-table>
                            </b-tab>
                          </b-tabs>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <b-button size="sm" target="_blank" @click="cancel('vmc')" class="mr-1">Annuler</b-button>
                        </b-col>
                        <b-col cols="8"></b-col>
                        <b-col cols="2">
                          <b-button size="sm" target="_blank" @click="saveItem('vmc')" class="mr-1">Enregistrer</b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
            </b-row>
                <b-row  v-if="!editVMC">
                  <b-col cols="12" md="12" lg="12">
                    <div class="content-block shadow-box">
                      <div class='header-form'>
                        <p><span>VMC</span><br></p>
                      </div>
                      <div class="content-form roundButton">
                        <b-alert
                          :show="dismissCountDown"
                          dismissible
                          variant="success"
                          @dismissed="dismissCountDown=0"
                          @dismiss-count-down="countDownChanged"
                        >
                          <p>VMC enregistré avec succès.</p>
                        </b-alert>
                <b-row align-h="end">
                  <b-col cols="2">
                    <b-button size="sm" target="_blank" @click="editItem(-1,'individuel')" class="mr-1">Ajouter VMC</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6" lg="2">
                    <b-form-group label="Filtrer" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
                      <b-input-group size="sm">
                        <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Rechercher par LIDIC, Nom, Type,..."></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="total_rows_vmc"
                  :per-page="perPage"
                  aria-controls="my-table-tertiare-vmc"
                ></b-pagination>
                <b-table responsive striped hover :items="tertiaire.vmc" :fields="fields" :filter="filter" id="my-table-tertiare-vmc" :per-page="perPage" :current-page="currentPage" :tbody-tr-class="rowClass" @filtered="onFiltered">
                <template #cell(vignette)="row">
                  <b-img :src="`/img/${row.item.lidic}.jpg`"/>
                </template>
                  <template #cell(actions)="row">
                    <b-button size="sm" target="_blank" @click="editItem(row.item,'individuel')" class="mr-1">Editer</b-button>
                    <b-button size="sm" target="_blank" @click="copyItem(row.item,'individuel')" class="mr-1">Copier</b-button>
                    <b-button size="sm" target="_blank" @click="deleteItem(row.item,'individuel')" class="mr-1">Supprimer</b-button>
                  </template>
                </b-table>
                </div></div>
              </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
      </div>
    </div>
    </b-col>
    </b-row>
    <datalist id="my-list-pt-tertiaire">
        <option :value="prod.value" v-for="(prod,index3) in lidic_params" :key="index3">{{ prod.text }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'TertiaireAdmin',
  props: {
    tertiaire: Object,
    product: Array,
    total_rows_vmc: Number,
  },
  data() {
      return {
        dismissCountDown: 0,
        tmpPieceName:'',
        currentVMC:{},
        currentVMCLidic: -1,
        currentLocalId: -1,
        currentOptions:{},
        currentParams:{},
        currentConfig:{},
        currentLocal: {},
        personne_debit: false,
        m_debit: false,
        supp_debit: false,
        newConfig: 'Nouveau Label',
        currentGaine:{},
        currentGaineLidic: null,
        tmpGaineLidic: null,
        currentConfigLabel: null,
        tmpConfigLabel: null,
        lidic_options:[],
        lidic_params:[],
        lidic_product:[],
        newConfigParam: '',
        editVMC:false,
        editLocal:false,
        editLocalPiece:false,
        editOptions:false,
        editParams:false,
        editConfig:false,
        editGaines:false,
        filter: null,
        perPage: 20,
        currentPage: 1,
        fields_locaux:[
          {
            key: 'nom',
            label: 'Nom Local',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_config: [
          {
            key: 'label',
            label: 'label',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_piece_params: [
          {
            key:'name',
            label:'Nom Pièce',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_params: [
          {
            key: 'type',
            label: 'Type',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_gaines: [
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_options: [
          {
            key: 'libelle',
            label: 'Libelle',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields: [
          { key: 'vignette', label: '',tdClass: 'product-vignette' },
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          {
            key: 'nom',
            label: 'Nom',
            sortable: true
          },
          {
            key: 'categorie',
            label: 'Type',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        typologie:[{
          value:1,
          text:'T1'
        },{
          value:2,
          text:'T2'
        },{
          value:3,
          text:'T3'
        },{
          value:4,
          text:'T4'
        },{
          value:5,
          text:'T5'
        },{
          value:6,
          text:'T6'
        },{
          value:7,
          text:'T7'
        }],
        reglementation:[{
          value:'E',
          text:'Existant'
        },
        {
          value:'N',
          text:'Construction Neuve'
        },{
          value:'E/N',
          text:'Existant ou Construction Neuve'
        }],
        options_type:[{value:'',text:'Classique'},{value:'checkbox',text:'Oui/Non'}],
        param_type:[{value:'bouche',text:'Bouche Commande'},
                    {value:'piece_technique','text':'Pièces Techniques'},
                   {value:'entree','text':"Bouche d'entrée"}],
        param_vmc_type:[{value:'piquetage',text:'Piquetage'},{value:'barre_horizontal',text:'Barre Horizontal'},{value:'barre_vertical',text:'Barre Vertical'}],
        flux:[{value:'Simple Flux',text:'Simple Flux'},{value:'Double Flux',text:'Double Flux'}],
        status:[{value:'valid',text:'Validé'},{value:'error',text:'Erreur Paramétrage'},{value:'waiting',text:'En attente de validation'}],
        categorie:[{value:'Caisson Très Basse Conso.',text:'Caisson Très Basse Consommation'},{value:'Caisson Basse Conso.',text:'Caisson Basse Consommation'},{value:'Caisson Basse Conso. Régulé',text:'Caisson Basse Consommation Régulé'},{value:'Caisson Standard',text:'Caisson Standard'}],
        type_aggrement:[{value:'N',text:'Non Agrée'},{value:'400',text:'400°C 1/2H'}],
        type_flux:[{value:'S',text:'Simple Flux'},{value:'D',text:'Double Flux'}],
        type_version:[{value:'S',text:'Standard'},{value:'Elec',text:'Batterie Electrique'},{value:'Eau',text:'Batterie Eau'}],
        type:[{value:'JETLINE',text:'JETLINE'},{value:'CACB ECM ECO',text:'CACB ECM ECO'},{value:'CACB ECM REJET VERTICAL',text:'CACB ECM REJET VERTICAL'},{value:'CACB MV REJET HORIZ/VERTICAL',text:'CACB MV REJET HORIZ/VERTICAL'},
      {value:'CRCB ECOWATT REJET HORIZONTAL',text:'CRCB ECOWATT REJET HORIZONTAL'},{value:'CRCB ECOWATT ISOLE  REJET HORIZONTAL',text:'CRCB ECOWATT ISOLE  REJET HORIZONTAL'},{value:'CRCB ECOWATT ISOLE  REJET VERTICAL',text:'CRCB ECOWATT ISOLE  REJET VERTICAL'},
    {value:'CRCB ECOWATT REJET VERTICAL',text:'CRCB ECOWATT REJET VERTICAL'},{value:'CRCB ECOWATT PM  REJET HORIZONTAL',text:'CRCB ECOWATT PM  REJET HORIZONTAL'},{value:'CRCB ECOWATT PM  REJET VERTICAL',text:'CRCB ECOWATT PM  REJET VERTICAL'},
  {value:'CRCB ECOWATT PM ISOLE  REJET HORIZONTAL',text:'CRCB ECOWATT PM ISOLE  REJET HORIZONTAL'},{value:'CRCB ECOWATT PM ISOLE  REJET VERTICAL',text:'CRCB ECOWATT PM ISOLE  REJET VERTICAL'},{value:'CRCB ECOWATT TRI  ISOLE  REJET VERTICAL',text:'CRCB ECOWATT TRI  ISOLE  REJET VERTICAL'},
{value:'CRCB ECOWATT TRI ISOLE  REJET VERTICAL',text:'CRCB ECOWATT TRI ISOLE  REJET VERTICAL'},{value:'CRCB ECOWATT TRI REJET VERTICAL',text:'CRCB ECOWATT TRI REJET VERTICAL'},{value:'CACT N ECO TRI',text:'CACT N ECO TRI'}],
      tension:[{value:'M',text:'MonoPhase'},{value:'T',text:'Triphase'}],
      aspiration:[{value:'9',text:'90°'},{value:'L',text:'Ligne'},{value:'D',text:'Double'}],
      debit:["300", "400", "500", "600", "700", "800", "900", "1000", "1100", "1200", "1300", "1400", "1500", "1600", "1700", "1800", "1900",'2000','2700','2900','3700','5000','6900','8000','9000'],
      }
  },
  methods: {
    onFiltered(filteredItems){
      console.log("filter");
      console.log(this.tertiaire.vmc.length);
      console.log(filteredItems.length);
      this.total_rows_vmc = filteredItems.length;
      this.currentPage = 1;
    },
    updateLidicPiquetage(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.lidic) || item.nom.toString().includes(this.currentParams.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateConfig(){
      switch(this.currentOptions.content.type){
        case 'checkbox':
          this.$delete(this.currentOptions.content,'options');
        break;
        default:
          this.$delete(this.currentOptions.content,'items');
          this.$delete(this.currentOptions,'type');
        break;
      }
    },
    changePersonne(){
      if(!this.personne_debit){
        if(!Object.prototype.hasOwnProperty.call(this.currentPiece,'personne')){
          this.$set(this.currentPiece,'personne',0);
        }
      }else{
        if(Object.prototype.hasOwnProperty.call(this.currentPiece,'personne')){
          this.$delete(this.currentPiece,'personne');
        }
      }
    },
    changeSurface(){
      if(!this.m_debit){
        if(!Object.prototype.hasOwnProperty.call(this.currentPiece,'surface')){
          this.$set(this.currentPiece,'surface',0);
        }
      }else{
        if(Object.prototype.hasOwnProperty.call(this.currentPiece,'surface')){
          this.$delete(this.currentPiece,'surface');
        }
      }
    },
    changeSupp(){
      if(!this.supp_debit){
        if(!Object.prototype.hasOwnProperty.call(this.currentPiece.debit,'supp')){
          this.$set(this.currentPiece.debit,'supp',0);
        }
      }else{
        if(Object.prototype.hasOwnProperty.call(this.currentPiece.debit,'supp')){
          this.$delete(this.currentPiece.debit,'supp');
        }
      }
    },
    countDownChanged(dismisscd){
      this.dismissCountDown = dismisscd;
    },
    updateParams(){
      switch(this.currentParams.type){
        case 'entree':
          this.$set(this.currentParams,'rallonge',{});
          this.$set(this.currentParams,'entree',{});
          this.$delete(this.currentParams,'sdb');
          this.$delete(this.currentParams,'sdb_wc');
          this.$delete(this.currentParams,'wc');
          this.$delete(this.currentParams,'eau');
          this.$delete(this.currentParams,'offered');
        break;
        case 'piece_technique':
          this.$delete(this.currentParams,'rallonge');
          this.$delete(this.currentParams,'entree');
          this.$set(this.currentParams,'sdb',{items:[],offered:0});
          this.$set(this.currentParams,'sdb_wc',{items:[],offered:0});
          this.$set(this.currentParams,'wc',{items:[],offered:0});
          this.$set(this.currentParams,'eau',{items:[],offered:0});
          this.$set(this.currentParams,'offered',0);
        break;
        default:
          this.$delete(this.currentParams,'sdb');
          this.$delete(this.currentParams,'sdb_wc');
          this.$delete(this.currentParams,'wc');
          this.$delete(this.currentParams,'eau');
          this.$delete(this.currentParams,'offered');
          this.$delete(this.currentParams,'rallonge');
          this.$delete(this.currentParams,'entree');
        break;
      }
      console.log(this.currentParams);
    },
    add_item_options(){
      if(this.currentOptions.content.type == 'checkbox'){
        if(!this.currentOptions.content.items) this.$set(this.currentOptions.content,'items',[]);
        this.currentOptions.content.items.push({lidic:'',number:'1'});
      }else{
        if(!this.currentOptions.content.options) this.$set(this.currentOptions.content,'options',[]);
        this.currentOptions.content.options.push({text:'',value:''});
      }
    },
    remove_item_options(index){
      if(this.currentOptions.content.type == 'checkbox'){
        this.currentOptions.content.items.splice(index,1);
      }else{
        this.currentOptions.content.options.splice(index,1);
      }
    },
    remove_item_config(propertyName){
      delete this.currentConfig[propertyName];
      this.$forceUpdate();
    },
    add_item_config(){
      if(this.newConfigParam!=""){
        this.$set(this.currentConfig,this.newConfigParam,0);
        this.newConfigParam = '';
      }
    },
    add_item_entree(rallonge,bouche){
      if(rallonge){
        if(!this.currentParams['rallonge'][bouche]) this.$set(this.currentParams['rallonge'],bouche,[]);
        this.currentParams['rallonge'][bouche].push({lidic:'',number:1});
      }else{
        if(!this.currentParams['entree'][bouche]) this.$set(this.currentParams['entree'],bouche,[]);
        this.currentParams['entree'][bouche].push({lidic:'',number:1});
      }

    },
    remove_item_entree(rallonge,bouche,index2){
      if(rallonge){
        this.currentParams['rallonge'][bouche].splice(index2,1);
      }else{
        this.currentParams['entree'][bouche].splice(index2,1);
      }
    },
    remove_item_gaine(nb_etage,index){
      this.currentGaine.items[nb_etage].splice(index,1);
    },
    add_item_gaine(nb_etage){
      if(!this.currentGaine.items[nb_etage]) this.$set(this.currentGaine.items,nb_etage,[]);
      this.currentGaine.items[nb_etage].push({lidic:'',number:1});
    },
    add_item_bouche(bouche){
      if(!this.currentParams[bouche]) this.$set(this.currentParams,bouche,[]);
      this.currentParams[bouche].push({lidic:'',number:1});
    },
    remove_item_bouche(bouche,index2){
      this.currentParams[bouche].splice(index2,1);
    },
    add_item_pt(pt,index){
      if(!this.currentParams[pt].items[index]) this.$set(this.currentParams[pt].items,index,[]);
      this.currentParams[pt].items[index].push({lidic:'',number:1,offered:0});
    },
    remove_item_pt(pt,index,index2){
      this.currentParams[pt].items[index].splice(index2,1);
    },
    is_paramType(type){
      return this.currentParams.type==type;
    },
    is_option_checkbox(){
      return (this.currentOptions && this.currentOptions.content && this.currentOptions.content.type=="checkbox");
    },
    isBarreVertical(){
      return this.currentConfigLabel=="barre_vertical";
    },
    close(type){
      switch(type){
        case 'local_piece':
          this.editLocalPiece = false;
          this.currentPiece = {};
          this.personne_debit = false;
          this.m_debit = false;
          this.supp_debit = false;
          console.log(this.currentLocal);
        break;
        case 'options':
          console.log(this.currentOptions);
          console.log(this.currentVMC);
          this.lidic_options = [];
          this.editOptions=false;
        break;
        case 'params':
          this.lidic_params = [];
          this.editParams=false;
          console.log(this.currentParams);
          console.log(this.currentVMC);
        break;
        case 'gaines':
          this.lidic_params = [];
          this.editGaines = false;
          if(this.currentGaineLidic!=this.tmpGaineLidic && this.currentGaineLidic!=-1){
            this.$delete(this.currentParams, this.currentGaineLidic);
          }
          this.$set(this.currentParams,this.tmpGaineLidic,this.currentGaine);
          console.log(this.currentParams);
          console.log(this.currentVMC);
        break;
        case 'config':
          this.editConfig=false;
          if(this.currentConfigLabel!=this.tmpConfigLabel && this.currentConfigLabel!='Nouveau Label'){
            this.$delete(this.currentVMC.params.config, this.currentConfigLabel);
          }
          this.$set(this.currentVMC.params.config,this.tmpConfigLabel,this.currentConfig);
          console.log(this.currentConfig);
          console.log(this.currentVMC);
        break;
      }
    },
    copyItem(item,type){
      switch(type){
        case 'individuel':
          this.currentVMC = JSON.parse(JSON.stringify(item));
          this.currentVMC.lidic=null;
          this.currentVMC.status= "waiting",
          this.currentVMCLidic = -1;
          this.editVMC = true;
        break;
      }
    },
    editItem(item,type){
      switch(type){
        case 'local_entree':
        case 'local_sortie':
        case 'local_independant':
          this.editLocalPiece = true;
          if(item==-1){
            let type_local=type.split('_')[1];
            this.currentPiece = {
              name: 'Nouveau Local',
              type: type_local
            };

            let local_name="";


            let index = this.currentLocal.params.items.findIndex((item) => { return item.type==type_local});
            if(index==-1){
              this.currentLocal.params.items.push({name:local_name, piece:[],type:type_local});
              index = this.currentLocal.params.items.length-1;
            }
            if(type_local=="sortie"){
              local_name = "Locaux de sortie";
              this.currentPiece['debit'] = {};
              this.currentPiece['id'] = "S"+(this.currentLocal.params.items[index].piece.length+1);
            }else if(type_local=="entree"){
              local_name = "Locaux d'entrée";
              this.currentPiece['id'] = "E"+(this.currentLocal.params.items[index].piece.length+1);
            }else if(type_local=="independant"){
              local_name = "Locaux indépendant";
              this.currentPiece['id'] = "I"+(this.currentLocal.params.items[index].piece.length+1);
            }
            this.currentLocal.params.items[index].piece.push(this.currentPiece);
          }else{
            this.currentPiece = item;
            this.tmpPieceName = item.name;
            if(this.currentPiece.type=="entree" || this.currentPiece.type=="independant"){
              this.personne_debit = Object.prototype.hasOwnProperty.call(this.currentPiece,'personne')
              this.m_debit = Object.prototype.hasOwnProperty.call(this.currentPiece,'surface');
            }else if(this.currentPiece.type=="sortie"){
              this.supp_debit = Object.prototype.hasOwnProperty.call(this.currentPiece.debit,'supp');
            }
          }
        break;
        case 'local':
          this.editLocal = true;
          if(item==-1){
            this.currentLocalId = item;
            this.currentLocal = {
              params:{items:[]},
              status: "waiting",
              nom:'Nouveau Local',
              id: this.tertiaire.locaux.length+1
            };
            console.log(this.currentLocal);
          }else{
            this.currentLocal = item;
            this.currentLocalId = item.id;
          }
        break;
        case 'individuel':
          if(item==-1){
            this.currentVMC = {
              categorie: "",
              lidic: null,
              options: [
                {
                  "content": {
                    "number": 1,
                    "type": "checkbox",
                    "items": [
                      {
                        "lidic": "990001",
                        "number": "1"
                      }
                    ]
                  },
                  "libelle": "Plots Antivibratoire"
                }
              ],
              params: {
                items:[
                {
                  "type": "barre_horizontal",
                  "lidic": 869999
                },
                {
                  "type": "barre_vertical",
                  "lidic": 869998
                }
                ],
                  config: {
                    barre_horizontal: {
                      "125": 32.67,
                      "160": 43.32,
                      "200": 57.94,
                      "250": 73.65,
                      "315": 93.39,
                      "355": 106.65,
                      "400": 148.46,
                      "450": 165.67,
                      "500": 185.24,
                      "560": 206.04,
                      "630": 233.02,
                      "710": 330.08,
                      "800": 500.64
                    },
                    barre_vertical: {
                      "180": [
                        177,
                        81
                      ],
                      "300": [
                        211,
                        92
                      ],
                      "450": [
                        266,
                        109
                      ],
                      "700": [
                        329,
                        126
                      ],
                      "1100": [
                        433,
                        169
                      ],
                      "1400": [
                        480,
                        189
                      ],
                      "1800": [
                        646,
                        246
                      ],
                      "2300": [
                        768,
                        269
                      ],
                      "2900": [
                        947,
                        269
                      ],
                      "3500": [
                        1057,
                        354
                      ],
                      "4500": [
                        1270,
                        415
                      ]
                    }
                  }
              },
              status: "waiting",
              debit_max: [],
              diametre_aspi: 0,
              diametre_rejet: 0,
              type_aspiration:'',
              tension:'',
              type:'',
            };
            this.currentVMCLidic = item;
          }else{
            this.currentVMC = item;
            this.currentVMCLidic = item.lidic;
          }
          this.editVMC = true;
        break;
        case 'individuel_option':

          if(item==-1){
            this.currentVMC.options.push({content:{number:1,options:[]},libelle:''});
            this.currentOptions = this.currentVMC.options[this.currentVMC.options.length - 1];
          }else{
            this.currentOptions = item;
          }
          this.editOptions = true;
        break;
        case 'individuel_params':

          if(item==-1){
            this.currentVMC.params.items.push({type:''});
            this.currentParams = this.currentVMC.params.items[this.currentVMC.params.items.length - 1];
          }else{
            this.currentParams = item;
          }
          this.editParams = true;
        break;
        case 'individuel_config':
          if(item==-1){
            this.currentConfig = {};
            this.currentConfigLabel = 'Nouveau label';
            this.tmpConfigLabel = 'Nouveau label';
          }else{
            this.currentConfig = this.currentVMC.params.config[item.label];
            this.currentConfigLabel = item.label;
            this.tmpConfigLabel = this.currentConfigLabel;
          }
          this.editConfig = true;
          console.log(this.currentConfig);
        break;
        case 'individuel_gaines':

          if(item==-1){
            this.currentGaine = {
              items:{
                0:[],
                1:[]
              },
              longueur:0
            };
            this.currentGaineLidic = -1;
            this.tmpGaineLidic = null;
          }else{
            this.currentGaine = this.currentParams[item.lidic];
            this.currentGaineLidic = item.lidic;
            this.tmpGaineLidic = this.currentGaineLidic;
          }
          this.editGaines = true;
          console.log(item);
        break;
      }
    },
    deleteItem(item,type){
      switch(type){
        case 'local_entree':
        case 'local_sortie':
        case 'local_independant':
          var type_local=type.split('_')[1];
          var index = this.currentLocal.params.items.findIndex((item1) => { return item1.type==type_local});
          var indexPiece = this.currentLocal.params.items[index].piece.findIndex((item2) => { return item2.id == item.id});
          this.currentLocal.params.items[index].piece.splice(indexPiece,1);
        break;
        case 'local':
        this.$http.delete(`${this.$api_url}vmc/tertiaire_calcul/${item.id}`).then((result) => {
          console.log(result);
          if(result){
            var index = this.tertiaire.locaux.findIndex((local) => { return item.id == local.id; });
            if(index>-1) this.tertiaire.locaux.splice(index,1);
          }else{
            console.log(result);
          }
        });
        break;
        case 'individuel':
          this.$http.delete(`${this.$api_url}vmc/tertiaire/${item.lidic}`).then((result) => {
            console.log(result);
            if(result){
              var index = this.tertiaire.vmc.findIndex((vmc) => { return item.lidic == vmc.lidic; });
              if(index>-1) this.tertiaire.vmc.splice(index,1);
            }else{
              console.log(result);
            }
          });
        break;
        case 'individuel_option':
          this.currentVMC.options.splice(item,1);
        break;
        case 'individuel_params':
          this.currentVMC.params.items.splice(item,1);
        break;
        case 'individuel_config':
          this.$delete(this.currentVMC.params.config, item.label);
        break;
        case 'individuel_gaines':
          this.$delete(this.currentParams, item.lidic);
        break;
      }
    },
    cancel(type){
      switch(type){
        case 'local':
          this.currentLocal = {};
          this.editLocal = false;
          this.currentPiece = {};
          this.editLocalPiece = false;
        break;
        case 'vmc':
          this.editVMC = false;
          this.currentVMC = {};
        break;
      }
    },
    saveItem(type){
      var baseURI = this.$api_url+'vmc';
      var type_content = '';
      switch(type){
        case 'local':
          if(this.currentLocalId==-1){
            this.$http.post(baseURI,{vmc: this.currentLocal, type:'tertiaire_calcul'})
            .then((result) => {
              console.log(result);
              if(result){
                var indexLocaux=0;
                if(this.tertiaire.locaux.lengh>0){
                  indexLocaux=this.tertiaire.locaux.length-1;
                }

                this.$set(this.tertiaire.locaux,indexLocaux,this.currentLocal);
                this.currentLocal = {};
                this.editLocal = false;
                this.currentPiece = {};
                this.editLocalPiece = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }else{
            this.$http.put(baseURI,{vmc: this.currentLocal,lidic: this.currentLocal.id, type:'tertiaire_calcul'})
            .then((result) => {
              console.log(result);
              if(result){
                var index = this.tertiaire.locaux.findIndex((item) => { return item.id == this.currentLocal.id; });
                this.$set(this.tertiaire.locaux,index,this.currentLocal);
                this.currentLocal = {};
                this.editLocal = false;
                this.currentPiece = {};
                this.editLocalPiece = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }
        break;
        case 'vmc':
          console.log(this.currentVMC);
          type_content = 'tertiaire';
          if(this.currentVMCLidic==-1){
            this.$http.post(baseURI,{vmc: this.currentVMC, type:type_content})
            .then((result) => {
              console.log(result);
              if(result){
                var indexVMC=0;
                if(this.tertiaire.vmc.lengh>0){
                  indexVMC=this.tertiaire.vmc.length-1;
                }

                this.$set(this.tertiaire.vmc,indexVMC,this.currentVMC);
                this.currentVMC = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
                console.log(this.tertiaire);
              }else{
                console.log(result);
              }
            });
          }else{
            this.$http.put(baseURI,{vmc: this.currentVMC,lidic: this.currentVMCLidic, type:type_content})
            .then((result) => {
              console.log(result);
              if(result){
                var index = this.tertiaire.vmc.findIndex((item) => { return item.lidic == this.currentVMCLidic; });
                this.$set(this.tertiaire.vmc,index,this.currentVMC);
                this.currentVMC = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }
        break;
        case 'individuel':
          console.log(this.currentVMC);
          if(this.currentVMCLidic==-1){
            this.$http.post(baseURI,{vmc: this.currentVMC, type:type})
            .then((result) => {
              console.log(result);
              if(result){
                this.$set(this.individuel,(this.individuel.length-1),this.currentVMC);
                this.currentVMC = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }else{
            this.$http.put(baseURI,{vmc: this.currentVMC,lidic: this.currentVMCLidic, type:type})
            .then((result) => {
              console.log(result);
              if(result){
                var index = this.individuel.findIndex((item) => { return item.lidic == this.currentVMCLidic; });
                this.$set(this.individuel,index,this.currentVMC);
                this.currentVMC = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }
        break;
      }
    },
    rowClass(item,type){
      if (item && type === 'row') {
        if(item.status=='valid'){
          return 'validation_success';
        }else if(item.status=='error'){
          return 'validation_error';
        }else if(item.status=="waiting"){
          return 'waiting_validation';
        }
      }else{
        return null;
      }
    },
    updateVMC(){
      if(this.currentVMC.lidic!=""){
        let index = this.product.findIndex((item)=> {
          return item.lidic == this.currentVMC.lidic});
        if(index!=1){
          this.currentVMC.nom = this.product[index].nom;
          this.currentVMC.designation = this.product[index].designation;
          this.currentVMC.prix_unitaire = this.product[index].prix_unitaire;
          this.currentVMC.debit_max_reel = this.product[index].debit_max;
        }
        console.log(this.currentVMC);
      }
    },
    updateLidicGaineProd(nb_etage,index){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentGaine.items[nb_etage][index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentGaine.items[nb_etage][index].lidic) || item.nom.toString().includes(this.currentGaine.items[nb_etage][index].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicGaine(){
      let res = [];
      this.product.forEach((item) => {
        if(this.tmpGaineLidic.toString().length>3 && (item.lidic.toString().includes(this.tmpGaineLidic) || item.nom.toString().includes(this.tmpGaineLidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicOptions(index,isCheckbox){
      let res = [];
      if(isCheckbox){
        this.product.forEach((item) => {
          if(this.currentOptions.content.items[index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentOptions.content.items[index].lidic) || item.nom.toString().includes(this.currentOptions.content.items[index].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }else{
        this.product.forEach((item) => {
          if(this.currentOptions.content.options[index].value.toString().length>3 && (item.lidic.toString().includes(this.currentOptions.content.options[index].value) || item.nom.toString().includes(this.currentOptions.content.options[index].value))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }
      this.lidic_params = res;
      return res;
    },
    clearLidicBouche(){
      this.lidic_params = [];
    },
    updateLidicEntree(rallonge,index,index2){
      let res = [];
      if(rallonge){
        this.product.forEach((item) => {
          if(this.currentParams['rallonge'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['rallonge'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['rallonge'][index][index2].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }else{
        this.product.forEach((item) => {
          if(this.currentParams['entree'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['entree'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['entree'][index][index2].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }
      this.lidic_params = res;
      return res;
    },
    updateLidicBouche(index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[index][index2].lidic) || item.nom.toString().includes(this.currentParams[index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicParams(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.lidic) || item.nom.toString().includes(this.currentParams.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicPT(pt,index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams[pt].items[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[pt].items[index][index2].lidic) || item.nom.toString().includes(this.currentParams[pt].items[index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicProduct(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentVMC.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentVMC.lidic) || item.nom.toString().includes(this.currentVMC.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_product = res;
      return res;
    }
  },
  computed: {
    piece_entree(){
      let res = [];
      if(this.currentLocal && this.currentLocal.params && this.currentLocal.params.items){
        let indexEntree = this.currentLocal.params.items.findIndex((item) => { return item.type=="entree"});
        if(indexEntree!=-1){
          res = this.currentLocal.params.items[indexEntree].piece
        }
      }
      return res;
    },
    piece_sortie(){
      let res = [];
      if(this.currentLocal && this.currentLocal.params && this.currentLocal.params.items){
        let indexEntree = this.currentLocal.params.items.findIndex((item) => { return item.type=="sortie"});
        if(indexEntree!=-1){
          res = this.currentLocal.params.items[indexEntree].piece
        }
      }
      return res;
    },
    piece_independant(){
      let res = [];
      if(this.currentLocal && this.currentLocal.params && this.currentLocal.params.items){
        let indexEntree = this.currentLocal.params.items.findIndex((item) => { return item.type=="independant"});
        if(indexEntree!=-1){
          res = this.currentLocal.params.items[indexEntree].piece
        }
      }
      return res;
    },
    get_gaines_params(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="gaines"){
        console.log(this.currentParams);
        Object.keys(this.currentParams).filter(e => e !== 'type').forEach((item)=>{
          res.push({lidic: item});
        });
      }
      return res;
    },
    typologie_range(){
      let res = [];
      if(this.currentVMC!=null && this.currentVMC.typologie!=undefined && this.currentVMC.typologie_min!=undefined){
        let tmp = this.currentVMC.typologie_min;
        while(tmp<=this.currentVMC.typologie){
          res.push(tmp);
          tmp++;
        }
      }
      return res;
    },
    piece_technique_range(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="piece_technique" && this.currentParams['eau']!=null){
        res.push({value:'eau',text:"Salle d'eau"});
        res.push({value:'wc',text:"WC"});
        res.push({value:'sdb_wc',text:"Salle de bain avec WC"});
        res.push({value:'sdb',text:"Salle de bain"});
      }
      return res;
    },
    total_devis(){
      return this.items.length;
    },
    getCurrentConfig(){
      let res = [];
      Object.keys(this.currentVMC.params.config).forEach((item)=>{
        console.log(item);
        res.push({label:item});
      });
      return res;
    },
    total_prix(){
      let res = 0;
      this.items.forEach((item) => {
        res += parseFloat(item.prix);
      });
      res = res.toFixed(2);
      return res;
    },
    items(){
      let res;
      if((this.start!=null && this.start!="") && (this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime() && new Date(elem.created_at).getTime() <= new Date(this.end).getTime());
      }else if((this.start!=null && this.start!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime());
      }else if((this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime()<= new Date(this.end).getTime());
      }else{
        res = this.devis;
      }
      return res;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
