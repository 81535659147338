export const DevisModule = {
  namespaced: true,
  state: {
    devis: null
  },
  mutations: {
    SET_DEVIS(state, devis){
      state.devis = devis;
    },
    SET_TYPE(state, type){
      if(state.devis == null) state.devis = {};
      state.devis['type'] = type;
    },
    SET_ENV(state, env){
      if(state.devis == null) state.devis = {};
      state.devis['env'] = env;
    },
    SET_REQUEST(state, request){
      if(state.devis == null) state.devis = {};
      state.devis['request'] = request;
    },
    SET_VMC(state, vmc){
      if(state.devis == null) state.devis = {};
      state.devis['vmc'] = vmc;
    },
    SET_OPTIONS(state, options){
      if(state.devis == null) state.devis = {};
      state.devis['options'] = options;
    },
    SET_INFORMATIONS(state, informations){
      if(state.devis == null) state.devis = {};
      state.devis['informations'] = informations;
    },
    SET_PDF(state, pdf){
      if(state.devis == null) state.devis = {};
      state.devis['pdf'] = pdf;
    },
    SAVE_DEVIS(state,devis_content){
      if(state.devis == null) state.devis = {};
      state.devis['content'] = devis_content;
    }
  },
  actions: {
    setDevis({commit},devis){
      commit('SET_DEVIS',devis);
    },
    setType({commit},type){
      commit('SET_TYPE',type);
    },
    setEnv({commit},env){
      commit('SET_ENV',env);
    },
    setRequest({commit},request){
      commit('SET_REQUEST',request);
    },
    setVMC({commit},vmc){
      commit('SET_VMC',vmc);
    },
    setOptions({commit},options){
      commit('SET_OPTIONS',options);
    },
    setInformations({commit},informations){
      commit('SET_INFORMATIONS',informations);
    },
    setPDF({commit},pdf){
      commit('SET_PDF',pdf);
    },
    getDevis({commit}){
      let devis_content = {
        id: 'devis_1',
        products: [
          {id:854096,quantite:2,name:'ECA 45',designation:"Bouche d'inspiration",prix_unite:1800},
          {id:857657,quantite:2,name:'ECA RA 45',designation:'Bouche Salle de Bain WC',prix_unite:150},
          {id:604141,quantite:1,name:'DECO² K',designation:'Caisson VMC Aspiration EcoWatts',prix_unite:2650},
          {id:813881,quantite:5,name:'GPI 80/25',designation:'Gaine PVC Dure 25MM ',prix_unite:10.58},
          {id:810415,quantite:1,name:'ACR 50/80',designation:'Rallonge Acoustique',prix_unite:150},
        ]
      };
      commit('SAVE_DEVIS',devis_content);
    }
  },
  getters: {
    getDevis(state){
      return state.devis;
    }
  }
}
