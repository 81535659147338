<template>
  <b-container id="administration">
    <div class="header inner-shadow-box">
      <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Estimair" id="estimair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
    </div>
    <h3>Administration</h3>
    <b-tabs>
      <b-tab title="Individuel" active class="main_content">
        <IndividuelAdmin :individuel="this.individuel" :product="this.product"/>
      </b-tab>
      <b-tab title="Collectif" v-if="!$islm" class="main_content">
        <CollectifAdmin :collectif="this.collectif" :product="this.product" :total_rows_vmc="this.total_collectif_vmc_rows"/>
      </b-tab>
      <b-tab title="Tertiaire" v-if="!$islm" class="main_content">
        <TertiaireAdmin :tertiaire="this.tertiaire" :product="this.product" :total_rows_vmc="this.total_tertiaire_vmc_rows"/>
      </b-tab>
      <b-tab title="Désenfumage" v-if="!$islm" class="main_content">
        <DesenfumageAdmin :desenfumage="this.desenfumage" :product="this.product"/>
      </b-tab>
      <b-tab title="Catalogue" v-if="$isCatalogue" class="main_content">
        <CatalogueAdmin :product="this.product" :total_rows_vmc="this.total_catalogue_rows"/>
      </b-tab>
      <b-tab title="Image" class="main_content">
        <ImageAdmin :img="this.img" :product="this.product" :lidic_img="this.lidic_img" :total_rows="this.total_img_rows"/>
      </b-tab>
      <b-tab title="Script" class="main_content">
        <ScriptAdmin :backup="this.backup" />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
export default {
  name: 'Administration',
  data() {
      return {
        product: [],
        individuel: [],
        collectif: {},
        tertiaire: {},
        desenfumage: [],
        backup: {},
        img: [],
        lidic_img: [],
        total_img_rows: 1,
        total_tertiaire_vmc_rows: 1,
        total_collectif_vmc_rows: 1,
        total_catalogue_rows:1,
      }
    },
    methods: {
    },
    computed: {
    },mounted(){
      window.scrollTo(0, 0);
      const baseURI = this.$api_url+'vmc';
      this.$http.get(baseURI)
      .then((result) => {
        console.log(result);
        this.product = result.data.product;
        this.individuel = result.data.individuel;
        this.collectif = {vmc: result.data.collectif, calcul: result.data.collectif_calcul };
        this.tertiaire = {vmc: result.data.tertiaire, locaux: result.data.tertiaire_calcul };
        this.desenfumage = result.data.desenfumage;
        this.img = result.data.img;
        this.total_img_rows = this.img.length;
        this.total_tertiaire_vmc_rows = this.tertiaire.vmc.length;
        this.total_collectif_vmc_rows = this.collectif.vmc.length;
        this.total_catalogue_rows = this.product.length;
        console.log(result.data.backup);
        this.backup = result.data.backup;
        console.log(this.backup);
        console.log(this.total_tertiaire_vmc_rows);
        this.img.forEach((item) => {
          this.lidic_img.push(item.lidic);
        });
      });
    }
}
</script>

<style lang="scss">
#administration{
  text-align: left;
  max-width: 1980px;
  margin: 2em auto;
  ul li a {
    box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
    padding:1em 2em;
    border-radius: 2em;
    color: #ccc;
    margin-left:1em;
    &.active{
      background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
      box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
      color:#333;
    }
  }

  .row{
    margin-bottom:1em;
  }

  button{
    &.squarebutton{
      border-radius: 0;
      padding: 10px 10px;
      line-height: 0;
    }
    background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
    color:#333;
    padding:1em 2em;
    border-radius: 2em;
    border:0px;
    font-size:1em;
  }

  span.page-link{
    background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
    color:#333;
    padding:1em 2em;
    border-radius: 2em;
    border:0px;
    font-size:1em !important;
    opacity:0.5
  }

  .card-header{
    text-align: center;
    font-size: 1.5em;
  }

  .card-body{
    padding:1.25rem 0;
  }

  .tab-content{
    margin-top:2em;
  }

  h3{
    margin-bottom:2em;
  }

  .product-vignette{
    max-width: 70px;
    img{
      width:100%;
      border-radius:1em;

    }
  }

  .img-vignette{
    max-width: 60px;
    img{
      width:100%;
    }
  }
  table.table {
    tr.b-table-details td{
      padding-top:0;
    }
    th, td{
      font-size: 1em;
    }

    tr.validation_success{
      background-color: rgba(56,173,42,0.93);
    }

    tr.validation_error{
      background-color: rgba(218,23,23,0.93);
    }
  }


}
</style>
